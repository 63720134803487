import { Alert, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { FadeTransition } from "../../components/Transitions";

const NoPhotoOptions: React.FC = () => {
  const { t } = useTranslation();
  return (
    <FadeTransition>
      <Alert title={t("noPhotoOptionsText1")} color="orange">
        {t("noPhotoOptionsText2")}
        <Text variant="link" component="a" href="mailto:jules@bidscape.com">
          &nbsp;jules@bidscape.com
        </Text>
        &nbsp;{t("noPhotoOptionsText3")}
      </Alert>
    </FadeTransition>
  );
};

export default NoPhotoOptions;
