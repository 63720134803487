import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { Mutation, MutationNegotiatePriceArgs } from "../../typing/gql.schema";

export const MUTATION_NEGOTIATE_PRICE = gql`
  mutation negotiatePrice($id: ID!, $price: Float!) {
    negotiatePrice(id: $id, price: $price)
  }
`;

const useNegotiatePrice = (cartItemId: string) => {
  const { t } = useTranslation();
  const [mutate, { loading, data, error }] = useMutation<{ negotiatePrice: Mutation["negotiatePrice"] }, MutationNegotiatePriceArgs>(MUTATION_NEGOTIATE_PRICE);

  const negotiate = async (price: number) => {
    try {
      const res = await mutate({ variables: { price, id: cartItemId } });
      if (!res.data?.negotiatePrice) throw new Error("Error negotiating price");
      showNotification({ color: "teal", title: t("useNegotiateText1"), message: t("useNegotiateText2") });
    } catch (error: any) {
      showNotification({ color: "red", title: t("useNegotiateText3"), message: t("useNegotiateText4") });
      Sentry.captureException(error, { extra: { price, id: cartItemId, where: "useNegotiatePrice" } });
    }
  };

  return { negotiate, loading, error, data };
};

export default useNegotiatePrice;
