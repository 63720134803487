const order = {
  orderText0: "Please note: Any changes made to your quote (deleting items or splitting shipments) may result in changes to item cost and freight.",
  orderText1: "Delivery Date:",
  orderText2: "TBD (phased delivery)",
  orderText3: `For billing or payment questions, please email `,
  orderText4: `Items by truck`,
  orderText5: "Truck #",
  orderText6: " for ",
  orderText7: " each and ",
  orderText8: " total",
  orderText9: "Subtotal: ",
  orderText10: "Delivery Charge",
  orderText11: "Tax",
  orderText12: "Total: ",
  orderText13: `- Tax was applied if you did not have a reseller license on file with us. Have a license?`,
  orderText14: `Contact us`,
  orderText15: "Back",
  orderText16: "Share",
  orderText17: "- Your delivery total is based on a single delivery for all listed materials. Split deliveries will result in additional costs.",
  orderText18: "Items by truck",
  orderText19: "Order items are grouped by truck. Check on a truck to see the items that will be delivered on that truck.",
  orderText20: "This is a copy of your original order. For details related to changes, returns, or refunds, please refer to your Xero invoice or email",

  // order terms and conditions
  termsText1: "Terms & Conditions",
  termsText2: `Quotes are good for 7 days and are subject to change based on availability.`,
  termsText3: `Any changes made to quotes (adding or deleting items) will result in price fluctuations in the overall quote.`,
  termsText4: `A minimum 20% restocking fee applies to ALL cancelled orders.`,
  termsText5: `A licensed representative or representatives of your organization must be present at the delivery site on the day of delivery along with the equipment and manpower required to offload the delivery truck.`,
  termsText6: `Items to be returned or exchanged shall be left on the delivery vehicle to be returned to the supplying vendor.`,
  termsText7: `No returns or exchanges will be accepted after delivery.`,

  // share modal
  shareModal1: "Share Order",
  shareModal2: "Share this order with:",
  shareModal3: "A valid email is required",
  shareModal4: "Share",
  shareModal5: "Cancel",

  orderQueryErrorText1: `An error occurred while loading your order, please make sure you have an internet connection, or else contact Jules at`,
  orderQueryErrorText2: `An error occurred while loading your order, contact Jules at`,
  orderQueryErrorText3: `for assistance.`,
  orderQueryErrorText4: `Back`,
  orderQueryErrorText5: `Try again`,

  useShareOrderText1: "Success",
  useShareOrderText2: "Order: #{{orderId}} shared with {{email}}",
  useShareOrderText3: "Error",
  useShareOrderText4: "Couldn't share order. Email william@bidscape.com for help.",
};

export default order;
