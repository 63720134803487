const browseTranslations = {
  plantDetailsText1: "Commonly purchased with",

  plantDetailsAddToProjectText1: "Add to",
  plantDetailsAddToProjectText2: "This plant and size is already in your project, clicking the button above will only update the quantity. ",
  plantDetailsAddToProjectText3: "View quote",
  plantDetailsAddToProjectText4: "Add to quote",

  plantDetailsLocationInformationText1: "Location",

  plantDetailsQueryError1: "An error occurred while loading plant, please make sure you have an internet connection, or else contact Jules at",
  plantDetailsQueryError2: "An error occurred while loading plant, contact Jules at",
  plantDetailsQueryError3: "for assistance.",
  plantDetailsQueryError4: "Try again",

  plantDetailsPlantSizePhotosText1: "No photos available for currently selected size and plant combination.",

  categoriesListingText1: "Browse categories",

  plantsListingText1: "View all categories",
};

export default browseTranslations;
