import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { trackAction } from "../../services/firebaseAnalytics";
import { Mutation, MutationBuildForcedVariationArgs } from "../../typing/gql.schema";

export const buildForcedVariationGQL = gql`
  mutation buildForcedVariation($quoteId: ID!, $quoteItemId: ID!) {
    buildForcedVariation(quoteId: $quoteId, quoteItemId: $quoteItemId) {
      __typename
      adjustedUnitTotal
      priceDifference
      missing {
        id
        quantity
        notes
        plant {
          id
          latinName
          commonName
        }
        size {
          id
          name
        }
      }
      additional {
        id
        quantity
        notes
        plant {
          id
          latinName
          commonName
        }
        size {
          id
          name
        }
      }
      subs {
        id
        cartItem {
          id
          quantity
          plant {
            id
            latinName
            commonName
          }
          size {
            id
            name
          }
        }
        yardProduct {
          id
          plant {
            id
            latinName
            commonName
          }
          size {
            id
            name
          }
        }
      }
      unsubs {
        id
        cartItem {
          id
          quantity
          plant {
            id
            latinName
            commonName
          }
          size {
            id
            name
          }
        }
        yardProduct {
          id
          plant {
            id
            latinName
            commonName
          }
          size {
            id
            name
          }
        }
      }
    }
  }
`;

const useGenerateForcedVariation = () => {
  const [mutate, { loading, data, error }] =
    useMutation<{ buildForcedVariation: Mutation["buildForcedVariation"] }, MutationBuildForcedVariationArgs>(buildForcedVariationGQL);

  const generate = async (data: MutationBuildForcedVariationArgs) => {
    try {
      const result = await mutate({ variables: data });
      trackAction("build_forced_variation", { item_id: data.quoteItemId, quote_id: data.quoteId });
      return result;
    } catch (error: any) {
      Sentry.captureException(error, { extra: data });
    }
  };
  return { generate, loading, error, data };
};

export default useGenerateForcedVariation;
