import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { trackAction } from "../../services/firebaseAnalytics";
import { Mutation, MutationShareRfqArgs } from "../../typing/gql.schema";

export const MUTATION_SHARE_RFQ = gql`
  mutation shareRfq($id: ID!, $email: String!) {
    shareRfq(id: $id, email: $email)
  }
`;

const useShareRfq = (rqfId: string, contentType: string) => {
  const { t } = useTranslation();
  const [mutate, { loading, data, error }] = useMutation<{ shareRfq: Mutation["shareRfq"] }, MutationShareRfqArgs>(MUTATION_SHARE_RFQ);

  const share = async (email: string) => {
    let variables = { id: rqfId, email };
    try {
      const res = await mutate({ variables });

      if (!res.data?.shareRfq) throw new Error("Error sharing RFQ");
      showNotification({ color: "teal", title: t("useShareRfqText1"), message: t("useShareRfqText2") });
      trackAction("share", { rqfId: rqfId, email, method: "email", content_type: contentType });
    } catch (error: any) {
      showNotification({ color: "red", title: t("useShareRfqText3"), message: t("useShareRfqText4") });
      Sentry.captureException(error, { extra: { ...variables, where: "useShareRfq" } });
    }
  };

  return { share, loading, error, data };
};

export default useShareRfq;
