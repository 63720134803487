import { Estimate } from "../../typing/gql.schema";

const daysAwayToDelivery = (estimate?: Estimate) => {
  if (!estimate) return 0;
  const estimateDeliveryDate = new Date(estimate.rfq.deliveryDate);
  const today = new Date();
  const diff = estimateDeliveryDate.getTime() - today.getTime();
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
  return days;
};

const useIsEstimateDeliveryDate45DaysOrLessAway = (estimate?: Estimate) => {
  if (!estimate) return false;
  const days = daysAwayToDelivery(estimate);
  return days <= 45;
};

export default useIsEstimateDeliveryDate45DaysOrLessAway;
