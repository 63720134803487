import { Card, Group, Image, Space, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { FadeTransition } from "../../components/Transitions";
import { ItemDetail } from "../../typing/declarations";
import { Yard as IYard } from "../../typing/gql.schema";
import { formatCurrency } from "../../utils/utils";

type Props = {
  yard: IYard;
  children?: React.ReactNode;
  openMediaModal: (media: string) => void;
  yardLookup: Map<string, ItemDetail[]>;
};

const OrderTruck: React.FC<Props> = ({ yard, openMediaModal, yardLookup }) => {
  const { t } = useTranslation();
  const itemsDetail = yardLookup.get(yard.id);
  return (
    <FadeTransition>
      <Card shadow="sm" p="lg" radius="xs" withBorder>
        <Card.Section withBorder inheritPadding py="xs">
          <Title order={3}>
            {t("orderText5")}
            {yard.id}
          </Title>
        </Card.Section>
        <Space h={15} />
        {itemsDetail?.map((item, index) => (
          <div key={index}>
            <Text>
              <strong>{item.estimateItem.cartItem.quantity} </strong>
              {item.quoteItem.yardProduct.size.name}
              <em> {item.quoteItem.yardProduct.plant.latinName} </em>
              {item.estimateItem.cartItem.plant.id === item.quoteItem.yardProduct.plant.id && <>{item.estimateItem.cartItem.notes}</>}(
              {item.quoteItem.yardProduct.plant.commonName}){t("orderText6")}
              <strong>{formatCurrency(item.estimateItem.unitTotal)}</strong>
              {t("orderText7")}
              <strong>{formatCurrency(item.estimateItem.total)}</strong>
              {t("orderText8")}
            </Text>
            <Group>
              {item.quoteItem.yardProduct.approvedPhotos.map((photo, index) => (
                <Image
                  key={index}
                  width={100}
                  height={100}
                  radius={"sm"}
                  data-testid="open-media-button"
                  onClick={() => openMediaModal(photo?.filename!)}
                  src={`//img.bidscape.com/yard-product-photos/${photo?.filename}`}
                />
              ))}
            </Group>
            <Conditional renderIf={index !== itemsDetail?.length! - 1}>
              <Space h={15} />
            </Conditional>
          </div>
        ))}
      </Card>
      <Space h={25} />
    </FadeTransition>
  );
};

export default OrderTruck;
