import { Anchor, Button, Card, Flex, Group, Image, Space, Text } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "usehooks-ts";
import Conditional from "../../components/Conditional";
import { RfqType } from "../../enums";
import useElementHover from "../../hooks/useElementHover";
import { Estimate, EstimateItem as TEstimateItem } from "../../typing/gql.schema";
import { formatCurrency } from "../../utils/utils";
import useShowQuoteItemBundlePricing from "../hooks/useShowQuoteItemBundlePricing";
import EstimateItemAvailability from "./EstimateItemAvailability";

type Props = {
  estimate?: Estimate;
  item: TEstimateItem;
  estimateItemAvailability?: Estimate;
  loadingEstimateItemAvailability: boolean;
  openRemoveItemModal: (estimateItem: TEstimateItem) => void;
};

const EstimateItem: React.FC<Props> = ({ item, estimate, openRemoveItemModal, loadingEstimateItemAvailability, estimateItemAvailability }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const removeButtonHover = useElementHover();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const showBundlePricing = useShowQuoteItemBundlePricing(estimate!, item);

  const showIndividualUnitPrice = (estimate?.rfq.type === RfqType.ONLINE_TYPE || estimate?.rfq.type === RfqType.DEFAULT);
  const showUnitTotal = estimate?.rfq.type === RfqType.NURSERY_NET;

  return (
    <Container data-testid="estimate-item">
      <Card shadow="sm" p="md" radius="xs" withBorder>
        <Flex justify="space-between" wrap={isMobile ? "wrap" : "nowrap"}>
          <Text mt="5px" mr="md">
            <Text component="strong">{item?.cartItem?.quantity}</Text>&nbsp;{item?.cartItem?.size?.name}&nbsp;
            <Text component="em">{item?.cartItem?.plant?.latinName}</Text>
            {item?.cartItem?.notes} ({item?.cartItem?.plant?.commonName}) {t("estimateText14")}
            &nbsp;
            <Conditional renderIf={estimate?.guaranteed}>
              <Text component="span" c="green">
                {t("estimateText15")}&nbsp;
              </Text>
            </Conditional>
            <Conditional renderIf={showIndividualUnitPrice}>
              <Conditional renderIf={!showBundlePricing}>
                <Text component="strong">{formatCurrency(item.individualUnitPrice)}</Text>
              </Conditional>
              <Conditional renderIf={showBundlePricing}>
                <Text fw={700} component="strong" td="line-through">
                  {formatCurrency(item.individualUnitPrice)}
                </Text>
                <Text fw={700} component="span" c="green">
                  &nbsp;{formatCurrency(item.unitTotal)} {t("estimateText42")}
                </Text>
              </Conditional>
            </Conditional>
            <Conditional renderIf={showUnitTotal}>
              <Text component="strong">{formatCurrency(item.unitTotal)}</Text>&nbsp;
              <Conditional renderIf={item.cartItem.quantity > 1}>
                {t("estimateText16")}
                &nbsp;<Text component="strong">{formatCurrency(item?.total)}</Text> {t("estimateText16_1")}
              </Conditional>
            </Conditional>
          </Text>
          <Group gap="sm" justify={isMobile ? "center" : "right"} wrap={isMobile ? "wrap" : "nowrap"}>
            <EstimateItemAvailability estimateItemId={item.id} loading={loadingEstimateItemAvailability} estimate={estimateItemAvailability} />
            <Conditional renderIf={estimate?.editable && !isMobile}>
              <Button
                color="red"
                leftSection={<IconTrash />}
                ref={removeButtonHover.hoverRef}
                className="center-on-mobile "
                variant={removeButtonHover.isHover ? "outline" : "default"}
                onClick={() => openRemoveItemModal(item!)}
                data-testid="remove-estimate-item-btn"
              >
                {t("estimateText17")}
              </Button>
            </Conditional>
          </Group>
        </Flex>
        <Space h={"md"} />
        <Conditional renderIf={item?.cartItem?.defaultPhoto && item?.cartItem?.defaultPhoto?.filename}>
          <Group>
            <Anchor
              data-testid="open-media-button"
              href={`/photos/default/${item.id}`}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/photos/default/${item.id}`);
              }}
            >
              <Image height={100} width={100} radius="sm" src={`//img.bidscape.com/yard-product-photos/${item?.cartItem.defaultPhoto?.filename}`} />
            </Anchor>
          </Group>
          <Space h={"md"} />
        </Conditional>
        <Conditional renderIf={estimate?.editable && isMobile}>
          <Button
            color="red"
            leftSection={<IconTrash />}
            ref={removeButtonHover.hoverRef}
            className="center-on-mobile "
            variant={removeButtonHover.isHover ? "outline" : "default"}
            onClick={() => openRemoveItemModal(item!)}
            data-testid="remove-estimate-item-btn"
          >
            {t("estimateText17")}
          </Button>
        </Conditional>
      </Card>
      <Space h={"md"} />
    </Container>
  );
};

const Container = styled.div`
  .flex {
    flex-wrap: wrap;
    @media (min-width: 768px) {
      flex-wrap: nowrap;
    }
  }

  .center-on-mobile {
    @media (max-width: 768px) {
      text-align: center;
      width: 100%;
    }
  }
`;

export default EstimateItem;
