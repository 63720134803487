import { Button, Container, Group, Space, Title } from "@mantine/core";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PlantListingBase from "../PlantsListingBase/PlantsListingBase";
import PlantsListingSearchBar from "../PlantsListingBase/components/PlantsListingSearchBar";
import useUrlSearchKey from "../PlantsListingBase/hooks/useUrlSearchKey";

const PlantsListing = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const search = useUrlSearchKey();

  const categoryName = params.categoryName?.replace(/-/g, " ");
  const title = `Browse ${categoryName || "plants"} online | Bidscape`;
  const heading = `${categoryName || "plants"} (by popularity)`;

  const viewAllCategoriesURL = `/categories`;

  return (
    <Container>
      {/**  @ts-ignore */}
      <Helmet title={title} />
      <Group justify="center">
        <Title ta="center" order={1} title={heading}>
          {heading}
        </Title>
        <Button
          radius="lg"
          variant="light"
          component="a"
          href={viewAllCategoriesURL}
          onClick={(e) => {
            e.preventDefault();
            navigate(viewAllCategoriesURL);
          }}
        >
          {t("plantsListingText1")}
        </Button>
      </Group>
      <Space h={"xs"} />
      <PlantsListingSearchBar defaultSearchKey={search.searchKey} onSearchKey={search.onSearchKey} />
      <PlantListingBase searchKey={search.searchKey} categoryId={params.categoryId} />
    </Container>
  );
};

export default PlantsListing;
