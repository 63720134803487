import { ApolloError } from "@apollo/client";
import { Alert, Button, Container, Group, Space, Text } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { FadeTransition } from "../../components/Transitions";

type Props = {
  refetch?: () => void;
  error: ApolloError | undefined;
};

const QuoteQueryError: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <FadeTransition>
      <Container>
        <Space h={50} />
        <Alert color="red" title="Error">
          <Group gap={0}>
            <Conditional renderIf={props.error?.networkError}>
              <Text>{t("quoteQueryErrorText1")}</Text>
            </Conditional>
            <Conditional renderIf={!props.error?.networkError}>
              <Text>{t("quoteQueryErrorText2")}</Text>
            </Conditional>
            &nbsp;
            <Text variant="link" component="a" href="mailto:jules@bidscape.com">
              jules@bidscape.com
            </Text>
            <Text>&nbsp;{t("quoteQueryErrorText3")}</Text>
          </Group>
          <Space h="lg" />
          <Group justify="center">
            <Button component="a" href="/" leftSection={<IconArrowLeft />} variant="default">
              {t("quoteQueryErrorText4")}
            </Button>
            <Button variant="default" onClick={() => props.refetch?.()}>
              {t("quoteQueryErrorText5")}
            </Button>
          </Group>
        </Alert>
      </Container>
    </FadeTransition>
  );
};

export default QuoteQueryError;
