import {
  ActionIcon,
  Anchor,
  Burger,
  Button,
  Center,
  Divider,
  Drawer,
  Group,
  Image,
  Menu,
  NavLink,
  ScrollArea,
  Space,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { IconChevronDown, IconLogout, IconMoon, IconSun, IconSwitchHorizontal } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import logo from "../../../assets/images/bidscape.png";
import Conditional from "../../../components/Conditional";
import CustomModal from "../../../components/CustomModal";
import useAppSettings from "../../../hooks/useAppSettings";
import useAuth from "../../../hooks/useAuth";
import useModal from "../../../hooks/useModal";
import useAppHeader from "../hooks/useAppHeader";
import classes from "../styles/index.module.css";
import SwitchUser from "./SwitchUser";

const AppHeader: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const switchUserModal = useModal();
  const appSettings = useAppSettings();
  const { drawerOpened, toggleDrawer } = useAppHeader();
  const { activeUser, isLoggedIn, loggedInUser, logout } = useAuth();
  const isMobile = useMediaQuery("(max-width: 576px)");

  return (
    <>
      <header className={classes.header}>
        <Group px={8} justify="space-between" w="100%" h={56}>
          <Conditional renderIf={!isLoggedIn}>
            <Anchor
              href="/"
              onClick={(e) => {
                e.preventDefault();
                navigate("/");
              }}
            >
              <Image className={classes.logo} src={logo} alt="Bidscape" />
            </Anchor>
          </Conditional>
          <Conditional renderIf={isLoggedIn}>
            <Anchor
              href="/dashboard"
              onClick={(e) => {
                e.preventDefault();
                navigate("/dashboard");
              }}
            >
              <Image className={classes.logo} src={logo} alt="Bidscape" />
            </Anchor>
          </Conditional>
          <Conditional renderIf={!isMobile}>
            <Group visibleFrom="sm">
              <Conditional renderIf={!isLoggedIn}>
                <Button
                  component={"a"}
                  href="/login"
                  data-testid="login-btn"
                  variant="outline"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/login");
                  }}
                >
                  {t("headerText1")}
                </Button>
              </Conditional>
              <Conditional renderIf={isLoggedIn && loggedInUser?.isAdmin}>
                <Menu data-testid="user-menu" trigger="click">
                  <Menu.Target>
                    <UnstyledButton className={classes.userName}>
                      <Center>
                        <Text data-testid="user-menu">{activeUser?.email}</Text>
                        <IconChevronDown size={12} strokeWidth={1.5} />
                      </Center>
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item onClick={switchUserModal.open} leftSection={<IconSwitchHorizontal />}>
                      {t("headerText2")}
                    </Menu.Item>
                    <Menu.Item onClick={logout} c="red" leftSection={<IconLogout />}>
                      {t("headerText3")}
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Conditional>
              <Conditional renderIf={isLoggedIn && !loggedInUser?.isAdmin}>
                <Menu data-testid="user-menu" trigger="click">
                  <Menu.Target>
                    <UnstyledButton className="user">
                      <Center>
                        <Text data-testid="user-menu">{activeUser?.email}</Text>
                        <IconChevronDown size={12} strokeWidth={1.5} />
                      </Center>
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item onClick={logout} c="red" leftSection={<IconLogout />}>
                      {t("headerText3")}
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Conditional>
              <Conditional renderIf={!appSettings.darkMode}>
                <ActionIcon variant="default" size={34} onClick={appSettings.toggleTheme}>
                  <IconMoon strokeWidth={1} />
                </ActionIcon>
              </Conditional>
              <Conditional renderIf={appSettings.darkMode}>
                <ActionIcon variant="default" size={34} onClick={appSettings.toggleTheme}>
                  <IconSun strokeWidth={1} />
                </ActionIcon>
              </Conditional>
            </Group>
          </Conditional>
          <Group hiddenFrom="sm">
            <Burger opened={drawerOpened} onClick={toggleDrawer} />
          </Group>
        </Group>
        <Divider />
        <Drawer size="xl" padding="xl" position="right" opened={drawerOpened} onClose={toggleDrawer} title={activeUser?.email || t("headerText4")}>
          <ScrollArea>
            <Conditional renderIf={!isLoggedIn}>
              <NavLink 
                label={t("headerText1")} 
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/login");
                }}
                leftSection={<IconSwitchHorizontal size={16} strokeWidth={1.5} />} />
              <NavLink
                label={t("headerText5")}
                onClick={appSettings.toggleTheme}
                leftSection={appSettings.darkMode ? <IconSun size={16} strokeWidth={1.5} /> : <IconMoon size={16} strokeWidth={1.5} />}
              />
            </Conditional>
            <Conditional renderIf={isLoggedIn && !loggedInUser?.isAdmin}>
              <NavLink
                label={t("headerText5")}
                onClick={appSettings.toggleTheme}
                leftSection={appSettings.darkMode ? <IconSun size={16} strokeWidth={1.5} /> : <IconMoon size={16} strokeWidth={1.5} />}
              />
              <NavLink c="red" onClick={logout} label={t("headerText3")} leftSection={<IconLogout color="red" size={16} strokeWidth={1.5} />} />
            </Conditional>
            <Conditional renderIf={isLoggedIn && loggedInUser?.isAdmin}>
              <NavLink
                label={t("headerText2")}
                onClick={switchUserModal.open}
                leftSection={<IconSwitchHorizontal color="green" size={16} strokeWidth={1.5} />}
              />
              <NavLink
                label={t("headerText5")}
                onClick={appSettings.toggleTheme}
                leftSection={appSettings.darkMode ? <IconSun size={16} strokeWidth={1.5} /> : <IconMoon size={16} strokeWidth={1.5} />}
              />
              <NavLink c="red" onClick={logout} label={t("headerText3")} leftSection={<IconLogout color="red" size={16} strokeWidth={1.5} />} />
            </Conditional>
          </ScrollArea>
        </Drawer>
      </header>
      <CustomModal size="lg" centered title={t("headerText6")} onClose={switchUserModal.close} opened={switchUserModal.isActive}>
        <Divider />
        <Space h={15} />
        <SwitchUser close={switchUserModal.close} />
        <Divider />
        <Space h={20} />
        <Button onClick={switchUserModal.close} variant="default">
          {t("headerText7")}
        </Button>
      </CustomModal>
    </>
  );
};

export default AppHeader;
