import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { Mutation, MutationSaveFirmMetadataArgs } from "../../typing/gql.public.schema";

export const saveFirmMetadataGql = gql`
  mutation saveFirmMetadata($hmac: String!, $firmMetadata: firmMetadataInput!) {
    saveFirmMetadata(hmac: $hmac, firmMetadata: $firmMetadata) {
      website
      type
      residential
      projectMin
      projectMax
      phone
      name
      maintenance
      id
      email
      commercial
      address
    }
  }
`;

const useSaveFirmMetadata = () => {
  const { t } = useTranslation();
  const [mutate, result] = useMutation<{ saveFirmMetadata: Mutation["SaveFirmMetadata"] }, MutationSaveFirmMetadataArgs>(saveFirmMetadataGql, {
    context: { public: true },
  });

  const saveFirmMetadata = async (hmac: string, firmMetadata: MutationSaveFirmMetadataArgs["firmMetadata"]) => {
    try {
      await mutate({ variables: { hmac, firmMetadata } });
      showNotification({
        color: "teal",
        title: t("useSaveFirmMetadataText1"),
        message: t("useSaveFirmMetadataText2"),
      });
    } catch (error) {
      Sentry.captureException(error);
      showNotification({
        color: "red",
        title: t("useSaveFirmMetadataText3"),
        message: t("useSaveFirmMetadataText4"),
      });
    }
  };

  return { saveFirmMetadata, ...result };
};

export default useSaveFirmMetadata;
