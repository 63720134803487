import { useFormik } from "formik";
import * as Yup from "yup";

const initialValues = {
  email: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
});

type IOnSubmit = (email: string) => Promise<void>;

const useShareForm = (onSubmit: IOnSubmit, onClose?: () => void) => {
  const handleSubmit = async (values: typeof initialValues) => {
    try {
      await onSubmit(values.email);
    } finally {
      onClose?.();
    }
  };

  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return form;
};

export default useShareForm;
