const contractorTranslation = {
  useSaveFirmMetadataText1: "Firm information updated",
  useSaveFirmMetadataText2: "Your firm information has been updated successfully",
  useSaveFirmMetadataText3: "Error updating firm information",
  useSaveFirmMetadataText4: "An error occurred while updating your firm information",

  contractorListingText1: "Manage my listing",

  contractorListingText2: "Company Details",
  contractorListingText3: "Please review and correct your company details",

  contractorListingText4: "Company name",
  contractorListingText5: "Please enter your company name",

  contractorListingText6: "Email",
  contractorListingText7: "A valid email is required",

  contractorListingText8: "Phone number",
  contractorListingText9: "A valid phone is required",

  contractorListingText10: "Website",

  contractorListingText11: "Address",
  contractorListingText12: "A valid address is required",

  contractorListingText13: "Services provided",
  contractorListingText14: "Please select the services you provide",

  contractorListingText15: "Residential Landscaping",
  contractorListingText16: "Commercial Landscaping",
  contractorListingText17: "Maintenance",
  contractorListingText18: "Please select at least one service provided",

  contractorListingText19: "Project volume",
  contractorListingText20: "Please enter your project volume",

  contractorListingText21: "Project size minimum",
  contractorListingText22: "Please enter your project size minimum",

  contractorListingText23: "Project size maximum",
  contractorListingText24: "Please enter your project size maximum",

  contractorListingText25: "Submit",
};

export default contractorTranslation;
