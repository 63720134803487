import { ApolloError } from "@apollo/client";
import { Alert, Button, Group, Space, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { FadeTransition } from "../../components/Transitions";

type Props = {
  refetch?: () => void;
  error: ApolloError | undefined;
};

const PhotoOptionsQueryError: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <FadeTransition>
      <Space h={20} />
      <Alert color="red" title="Error">
        <Group gap={0}>
          <Conditional renderIf={props.error?.networkError}>
            <Text>{t("photoOptionsQueryErrorText1")}</Text>
          </Conditional>
          <Conditional renderIf={!props.error?.networkError}>
            <Text>{t("photoOptionsQueryErrorText2")}</Text>
          </Conditional>
          &nbsp;
          <Text variant="link" component="a" href="mailto:jules@bidscape.com">
            jules@bidscape.com
          </Text>
          <Text>&nbsp;{t("photoOptionsQueryErrorText3")}</Text>
        </Group>
        <Space h="lg" />
        <Group justify="center">
          <Button variant="default" onClick={() => props.refetch?.()}>
            {t("photoOptionsQueryErrorText5")}
          </Button>
        </Group>
      </Alert>
    </FadeTransition>
  );
};

export default PhotoOptionsQueryError;
