import { Button, Divider, Group, Image, Space, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import CustomModal from "../../components/CustomModal";
import { getImageUrl } from "../../utils/utils";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  filename: string;
};

const QuoteDefaultPhotoModal: React.FC<Props> = ({ isOpen, onClose, filename }) => {
  const { t } = useTranslation();
  return (
    <CustomModal centered opened={isOpen} onClose={onClose} title={t("quotesText76")}>
      <Image radius={3} src={getImageUrl(filename)} />
      <Space h={15} />
      <Group>
        <Button onClick={onClose} variant="default">
          {t("quotesText78")}
        </Button>
        <Text>{t("quotesText77")}</Text>
      </Group>
      <Space h={15} />
      <Divider />
    </CustomModal>
  );
};

export default QuoteDefaultPhotoModal;
