import { Space, TextInput } from "@mantine/core";
import { IconSearch, IconX } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";

type Props = {
  defaultSearchKey?: string;
  onSearchKey: (searchKey: string) => void;
};

const PlantsListingSearchBar: React.FC<Props> = ({ onSearchKey, defaultSearchKey }) => {
  const { t } = useTranslation();
  const ref = React.useRef<HTMLInputElement>(null);

  return (
    <>
      <TextInput
        ref={ref}
        size="md"
        radius={10}
        defaultValue={defaultSearchKey}
        placeholder={t("PlantsListingSearchBarText1")}
        onChange={(event) => onSearchKey(event.currentTarget.value)}
        leftSection={<IconSearch size={20} />}
        rightSection={
          <Conditional renderIf={defaultSearchKey}>
            <IconX
              size={20}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (ref.current) {
                  ref.current.value = "";
                  onSearchKey("");
                }
              }}
            />
          </Conditional>
        }
      />
      <Space h="xl" />
    </>
  );
};

export default PlantsListingSearchBar;
