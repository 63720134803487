import { Card, Container, Divider, Space } from "@mantine/core";
import * as Sentry from "@sentry/react";
import { useParams } from "react-router-dom";
import LightBoxModal from "../components/LightBoxModal";
import Loader from "../components/Loader";
import { FadeTransition } from "../components/Transitions";
import useMediaModal from "../hooks/useMediaModal";
import QuoteQueryError from "./components/QouteQueryError";
import QuoteDefaultPhotoModal from "./components/QuoteDefaultPhotoModal";
import QuoteFinancingModal from "./components/QuoteFinancingModal";
import QuoteFooter from "./components/QuoteFooter";
import QuoteHeader from "./components/QuoteHeader";
import QuoteItem from "./components/QuoteItem";
import QuoteItemEditModal from "./components/QuoteItemEditModal";
import QuoteNegotiateModal from "./components/QuoteItemNegotiateModal";
import QuoteItemRemoveModal from "./components/QuoteItemRemoveModal";
import QuoteItemRequestPhotoModal from "./components/QuoteItemRequestPhotoModal";
import QuoteItemShareModal from "./components/QuoteItemShareModal";
import QuoteMissingItem from "./components/QuoteMissingItem";
import QuoteMissingItemSubModal from "./components/QuoteMissingItemSubModal";
import QuoteTotals from "./components/QuoteTotals";
import Terms from "./components/Terms";
import useExtractEstimateItems from "./hooks/useExtractEstimateItems";
import useMissingItems from "./hooks/useMissingItems";
import useModals from "./hooks/useModals";
import useQuotesQuery from "./hooks/useQuotesQuery";
import useStaticQuotePage from "./hooks/useStaticQuotePage";
import useViewedQuote from "./hooks/useViewedQuote";

const Quote: React.FC = () => {
  const { id } = useParams();
  const modals = useModals();
  const query = useQuotesQuery(id);
  const defaultPhotoModal = useMediaModal();
  const approvedPhotoModal = useMediaModal();
  const missingItems = useMissingItems(query.estimate);
  const estimateItems = useExtractEstimateItems(query.estimate);
  useViewedQuote(query.estimate?.rfq.bestQuote?.id);
  const pageRef = useStaticQuotePage(query.estimate?.rfq?.deliveryDate);

  if (query.loading) return <Loader fullPage={true} />;
  if (query.error) return <QuoteQueryError error={query.error} refetch={query.refetch} />;

  return (
    <FadeTransition>
      <Container ref={pageRef}>
        <QuoteHeader estimate={query.estimate} openShareModal={modals.openShare} />
        <Space h={25} />
        {estimateItems.map((item, index) => (
          <QuoteItem
            key={index}
            item={item}
            modals={modals}
            estimate={query.estimate}
            openDefaultPhotoModal={defaultPhotoModal.open}
            openApprovedPhotoModal={approvedPhotoModal.open}
          />
        ))}
        {missingItems.map((item, index) => (
          <QuoteMissingItem key={index} item={item} refreshQuote={query.refetch} estimate={query.estimate} openSubModal={modals.openSub} />
        ))}
        <Space h={25} />
        <Card p="md" radius="xs">
          <QuoteTotals estimate={query.estimate} modals={modals} />
          <Divider />
          <QuoteFooter estimate={query.estimate} openShareModal={modals.openShare} />
        </Card>
        <Space h={35} />
        <Terms />
        <Space h={35} />
        <QuoteItemRemoveModal
          estimate={query.estimate}
          isOpen={modals.remove}
          onClose={modals.closeRemove}
          activeItem={modals.modalItem!}
          refreshQuote={query.refetch}
        />
        <QuoteNegotiateModal estimate={query.estimate} isOpen={modals.negotiate} onClose={modals.closeNegotiate} activeItem={modals.modalItem!} />
        <QuoteItemRequestPhotoModal
          estimate={query.estimate}
          refreshQuote={query.refetch}
          isOpen={modals.requestPhoto}
          activeItem={modals.modalItem!}
          onClose={modals.closeRequestPhoto}
        />
        <QuoteItemShareModal isOpen={modals.share} estimate={query.estimate} onClose={modals.closeShare} />
        <QuoteMissingItemSubModal isOpen={modals.sub} onClose={modals.closeSub} activeItem={modals.modalItem!} />
        <QuoteFinancingModal isOpen={modals.financing} onClose={modals.closeFinancing} />
        <QuoteItemEditModal
          isOpen={modals.edit}
          estimate={query.estimate}
          onClose={modals.closeEdit}
          activeItem={modals.modalItem!}
          openRemoveModal={modals.openRemove}
        />
        <QuoteDefaultPhotoModal isOpen={defaultPhotoModal.isActive} onClose={defaultPhotoModal.close} filename={defaultPhotoModal.filename} />
        <LightBoxModal filename={approvedPhotoModal.filename} opened={approvedPhotoModal.isActive} onClose={approvedPhotoModal.close} />
      </Container>
    </FadeTransition>
  );
};

export default Sentry.withProfiler(Quote, { name: "Quote" });
