import { Button, Flex, Space, Stack, Text } from "@mantine/core";
import { IconCreditCard, IconFileText } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Conditional from "../../components/Conditional";
import { RfqType } from "../../enums";
import { Estimate } from "../../typing/gql.schema";
import { formatCurrency } from "../../utils/utils";
import useExtractEstimateItems from "../hooks/useExtractEstimateItems";
import useIndividualSubTotal from "../hooks/useIndividualSubTotal";
import useModals from "../hooks/useModals";
import useShowTotalsBundlePricing from "../hooks/useShowTotalsBundlePricing";

type Props = {
  estimate?: Estimate;
  modals: ReturnType<typeof useModals>;
};

const QuoteTotals: React.FC<Props> = ({ modals, estimate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const estimateItems = useExtractEstimateItems(estimate);
  const individualSubTotal = useIndividualSubTotal(estimateItems);
  const showBundlePricing = useShowTotalsBundlePricing(estimate!, individualSubTotal);

  const showIndividualSubTotal = (estimate?.rfq.type === RfqType.ONLINE_TYPE || estimate?.rfq.type === RfqType.DEFAULT);
  const showSubTotal = estimate?.rfq.type === RfqType.NURSERY_NET;

  const checkoutPage = `/payment?rfqId=${estimate?.rfq?.id}`

  return (
    <Container>
      <Flex gap="md" justify="space-between" align="flex-start" direction="row" className="flex">
        <Stack gap={0}>
          <Conditional renderIf={showIndividualSubTotal}>
            <Conditional renderIf={!showBundlePricing}>
              <Text fz="lg">
                {t("quotesText71")} {formatCurrency(individualSubTotal)}
              </Text>
            </Conditional>
            <Conditional renderIf={showBundlePricing}>
              <Text fz="lg" td="line-through">
                {t("quotesText71")}
                {formatCurrency(individualSubTotal)}
              </Text>
              <Text fz="lg" c="green" fw="bold">
                {t("quotesText72")}
                {formatCurrency(estimate?.subtotal)} ({t("quotesText73")} {formatCurrency(individualSubTotal - estimate?.subtotal!)})
              </Text>
            </Conditional>
          </Conditional>
          <Conditional renderIf={showSubTotal}>
            <Text fz="lg">
              {t("quotesText18")} {formatCurrency(estimate?.subtotal)}
            </Text>
          </Conditional>
          <Conditional renderIf={estimate?.serviceFee}>
            <Text fz="lg">
              {t("quotesText82")}: {formatCurrency(estimate?.serviceFee)}
            </Text>
          </Conditional>
          <Text fz="lg">
            {t("quotesText19")}
            <sup>1</sup>: {formatCurrency(estimate?.deliveryFee)}
          </Text>
          <Text fz="lg">
            {t("quotesText20")}
            <sup>2</sup>: {formatCurrency(estimate?.tax)}
            <Conditional renderIf={formatCurrency(estimate?.tax) !== "$0.00"}>
              &nbsp;<Conditional renderIf={estimate?.rfq?.taxRate && estimate?.rfq?.taxRate > -1}>({estimate?.rfq?.taxRate!}%)</Conditional>
              <Conditional renderIf={estimate?.rfq?.taxRate && estimate?.rfq?.taxRate <= -1}>(9%)</Conditional>
            </Conditional>
          </Text>
          <Text fz="lg" fw="bold">
            {t("quotesText21")} {formatCurrency(estimate?.total)}
          </Text>
        </Stack>
        <Conditional renderIf={estimate?.editable}>
          <Stack>
            <Stack>
              <Button
                component="a"
                size="lg"
                leftSection={<IconCreditCard />}
                href={checkoutPage}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(checkoutPage);
                }}
              >
                {t("quotesText25")}
              </Button>
              <Conditional renderIf={estimate?.rfq.type === RfqType.NURSERY_NET}>
                <Text size="sm">{t("quotesText83")}</Text>
              </Conditional>
            </Stack>
            <Button
              fullWidth={false}
              size="sm"
              variant="default"
              leftSection={<IconFileText />}
              onClick={modals.openFinancing}
              data-testid="apply-financing-btn"
            >
              {t("quotesText26")}
            </Button>
          </Stack>
        </Conditional>
      </Flex>
      <Space h="md" />
      <Text fz="xs" c="red">
        <sup>1</sup> {t("quotesText68")}
      </Text>
      <Text fz="xs">
        <sup>2</sup> {t("quotesText23")}{" "}
        <Text variant="link" component="a" href="mailto:jules@bidscape.com">
          {t("quotesText24")}
        </Text>
      </Text>
      <Space h="md" />
    </Container>
  );
};

const Container = styled.div`
  .leaf-icon {
    margin-bottom: -5px;
  }

  .flex {
    flex-wrap: wrap;
    @media (min-width: 768px) {
      flex-wrap: nowrap;
    }
  }
  .buttons {
    @media (min-width: 768px) {
      width: 100%;
      justify-content: center;
    }
  }
`;

export default QuoteTotals;
