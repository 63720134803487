import { Button, Divider, Group, List, Space, TextInput, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import CustomModal from "../../components/CustomModal";
import { Order } from "../../typing/gql.schema";
import useShareOrder from "../hooks/useShareOrder";
import Conditional from "../../components/Conditional";

interface Props {
  order?: Order;
  close: () => void;
  opened: boolean;
}

const ShareModal: React.FC<Props> = ({ order, close, opened }) => {
  const { t } = useTranslation();
  const { form, loading } = useShareOrder(order?.id);
  const sharedEmailsList = order?.quote?.rfq?.sharedWithEmails?.map((email) => <List.Item key={email}>{email}</List.Item>);

  return (
    <CustomModal size="lg" centered opened={opened} title={t("shareModal1")} onClose={close} data-testid="share-modal">
      <Divider />
      <Space h={15} />
      <TextInput
        type="email"
        name="email"
        aria-label="email"
        onBlur={form.handleBlur}
        value={form.values.email}
        onChange={form.handleChange}
        placeholder="Email address"
        label={t("shareModal2")}
        error={form.touched.email && form.errors.email ? t("shareModal3") : ""}
      />
      <Space h={15} />
      <Conditional renderIf={sharedEmailsList?.length && sharedEmailsList?.length > 0}>
        <Text fs="italic">Already shared with:</Text>
        <List withPadding fs="italic">{sharedEmailsList}</List>
        <Space h={5} />
      </Conditional>
      <Divider />
      <Space h={15} />
      <Group>
        <Button loading={loading} disabled={!form.isValid} onClick={() => form.handleSubmit()}>
          {t("shareModal4")}
        </Button>
        <Button variant="default" onClick={close} data-testid="cancel-share-button">
          {t("shareModal5")}
        </Button>
      </Group>
    </CustomModal>
  );
};

export default ShareModal;
