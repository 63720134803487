import { useState } from "react";
import { CartItem, EstimateItem } from "../../typing/gql.schema";

const useModals = () => {
  const [sub, setSub] = useState(false);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [share, setShare] = useState(false);
  const [remove, setRemove] = useState(false);
  const [negotiate, setNegotiate] = useState(false);
  const [financing, setFinancing] = useState(false);
  const [requestPhoto, setRequestPhoto] = useState(false);
  const [contractAndHold, setContractAndHold] = useState(false);
  const [readyToByDisabled, setReadyToByDisabled] = useState(false);

  const [modalItem, setModalItem] = useState(undefined as Partial<EstimateItem> | undefined);

  const openEdit = (item: Partial<EstimateItem>) => {
    setEdit(true);
    setModalItem(item);
  };

  const closeEdit = () => {
    setEdit(false);
  };

  const openPhoto = (item: Partial<EstimateItem>) => {
    setRequestPhoto(true);
    setModalItem(item);
  };

  const openRemove = (item?: Partial<EstimateItem>) => {
    setEdit(false);
    setRemove(true);
    if (item) {
      setModalItem(item);
    }
  };

  const closeRemove = () => {
    setRemove(false);
  };

  const openNegotiate = (item: Partial<EstimateItem>) => {
    setModalItem(item);
    setNegotiate(true);
  };

  const closeNegotiate = () => {
    setNegotiate(false);
  };

  const closeRequestPhoto = () => {
    setRequestPhoto(false);
  };

  const openShare = () => {
    setShare(true);
  };

  const closeShare = () => {
    setShare(false);
  };

  const openFinancing = () => {
    setFinancing(true);
  };

  const closeFinancing = () => {
    setFinancing(false);
  };

  const openSub = (item: CartItem) => {
    setSub(true);
    setModalItem({ cartItem: item });
  };

  const closeSub = () => {
    setSub(false);
  };

  const openAdd = (item: CartItem) => {
    setAdd(true);
    setModalItem({ cartItem: item });
  };

  const closeAdd = () => {
    setAdd(false);
  };

  const openContractAndHold = () => {
    setContractAndHold(true);
  };

  const closetContractAndHold = () => {
    setContractAndHold(false);
  };

  const openReadyToByDisabled = () => {
    setReadyToByDisabled(true);
  };

  const closeReadyToByDisabled = () => {
    setReadyToByDisabled(false);
  };

  return {
    add,
    sub,
    edit,
    share,
    requestPhoto,
    remove,
    modalItem,
    negotiate,
    financing,
    contractAndHold,
    readyToByDisabled,
    openAdd,
    openSub,
    closeAdd,
    closeSub,
    openEdit,
    closeShare,
    openRemove,
    openPhoto,
    openShare,
    closeEdit,
    closeRequestPhoto,
    closeRemove,
    setModalItem,
    openNegotiate,
    openFinancing,
    closeNegotiate,
    closeFinancing,
    openContractAndHold,
    closetContractAndHold,
    openReadyToByDisabled,
    closeReadyToByDisabled,
  };
};

export default useModals;
