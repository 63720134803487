import { Card, Center, Divider, List, Loader, Space, Text, TextInput } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Conditional from "../../../components/Conditional";
import useSearchKey from "../../../hooks/useSearchKey";
import useSwitchUser from "../hooks/useSwitchUser";
import useUsersQuery from "../hooks/useUsersQuery";

interface Props {
  close: () => void;
}

const SwitchUser: React.FC<Props> = (props) => {
  const { close } = props;
  const { t } = useTranslation();
  const { searchKey, onSearchKey } = useSearchKey();
  const { users, loading } = useUsersQuery(searchKey);
  const { entries, getMatches, onUserSelect } = useSwitchUser({ users, close });

  return (
    <Container>
      <TextInput type="text" data-testid="search-input" placeholder={t("switchYardText1") || ""} onChange={(e) => onSearchKey(e.target.value)} />
      <Conditional renderIf={entries.length && !loading}>
        <Card p={0} shadow="sm" radius="sm" withBorder>
          <List className="list">
            {entries.map((entry, index) => (
              <React.Fragment key={index}>
                <List.Item className="th-suggestion" data-testid="user-suggestion" onClick={() => onUserSelect(entry.id)}>
                  <Text className="item-text" key={index}>
                    {getMatches(entry.text, searchKey).map((match, index) => (
                      <React.Fragment key={index}>
                        {match.preText} {match.text.length > 0 && <strong>{match.text}</strong>}
                      </React.Fragment>
                    ))}
                  </Text>
                </List.Item>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Card>
      </Conditional>
      <Conditional renderIf={loading}>
        <Center>
          <Space h={100} />
          <Loader variant="dots" />
        </Center>
      </Conditional>
    </Container>
  );
};

const Container = styled.div`
  min-height: 270px;

  .list {
    // box-shadow: 5px 10px;
  }
  .item-text {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .th-suggestion {
    padding: 8px;
    display: block;
    border-radius: 4px;
  }

  .th-suggestion:hover,
  .th-suggestion:focus {
    text-decoration: none;
    outline: 0;
    background-color: ${({ theme }) => (theme.darkMode ? theme.colors?.dark?.[5] : theme.colors?.gray?.[1])};
    cursor: pointer;
  }
`;
export default SwitchUser;
