import { Button, Center, Container, Divider, Group, Loader, Space, Text } from "@mantine/core";
import * as Sentry from "@sentry/react";
import { IconArrowLeft, IconPhoto } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Conditional from "../components/Conditional";
import CustomModal from "../components/CustomModal";
import LightBoxModal from "../components/LightBoxModal";
import CurrentlySelected from "./components/CurrentlySelected";
import NoPhotoOptions from "./components/NoPhotoOptions";
import OptionItem from "./components/OptionsItem";
import PhotoHeader from "./components/PhotoHeader";
import PhotoOptionsQueryError from "./components/PhotoOptionsQueryError";
import QuoteItemQueryError from "./components/QuoteItemQueryError";
import useLoadOptions from "./hooks/useLoadOptions";
import useModals from "./hooks/useModals";
import usePhotosQuery from "./hooks/usePhotosQuery";

const Photos: React.FC = () => {
  const modals = useModals();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { quoteItemId } = useParams();
  const query = usePhotosQuery(quoteItemId);
  const loadOptions = useLoadOptions(query.quoteItem);

  if (query.loading) {
    return (
      <Container>
        <Space h={50} />
        <Center>
          <Loader variant="bars" />
        </Center>
      </Container>
    );
  }

  if (query.error) {
    return <QuoteItemQueryError error={query.error} refetch={query.refetch} />;
  }

  return (
    <Container>
      <PhotoHeader quoteItem={query.quoteItem!} />
      <CurrentlySelected quoteItem={query.quoteItem!} openMediaModal={modals.openMedia} />
      <Conditional renderIf={query.quoteItem?.editable}>
        <Space h={40} />
        <Text ta="center" fw={700}>
          {t("photosText7")}
        </Text>
        <Space h={20} />
      </Conditional>
      <Conditional renderIf={query.quoteItem?.editable && !loadOptions.showOptions}>
        <Center>
          <Button size="lg" variant="default" onClick={() => loadOptions.load()} leftSection={<IconPhoto />} data-testid={"load-options-btn"}>
            {t("photosText8")}
          </Button>
        </Center>
      </Conditional>
      <Conditional renderIf={loadOptions.showOptionsLoader}>
        <Center>
          <Loader variant="dots" />
        </Center>
      </Conditional>
      <Conditional renderIf={loadOptions.showOptionsError}>
        <PhotoOptionsQueryError error={loadOptions.error} refetch={loadOptions.load} />
      </Conditional>
      <Conditional renderIf={loadOptions.showOptionsNoOptions}>
        <NoPhotoOptions />
      </Conditional>
      <Conditional renderIf={loadOptions.showOptions && !loadOptions.loading && loadOptions.options?.length > 0 && loadOptions.variations.size > 0}>
        {loadOptions.options.map((option, index) => (
          <OptionItem
            key={index}
            index={index}
            option={option}
            quoteItem={query.quoteItem}
            openInfoModal={modals.openInfo}
            openMediaModal={modals.openMedia}
            variations={loadOptions.variations}
            refetchQuoteItem={query.refetch}
            reLoadOptions={loadOptions.load}
          />
        ))}
      </Conditional>
      <Space h={30} />
      <Group justify="center">
        <Button size="lg" onClick={() => navigate(-1)} leftSection={<IconArrowLeft />} variant="default">
          {t("photosText23")}
        </Button>
      </Group>
      <Space h={30} />
      <LightBoxModal opened={modals.media} onClose={modals.closeMedia} filename={modals.mediaFilename} />
      <CustomModal size="lg" centered opened={modals.info} onClose={modals.closeInfo} title={t("photosText24")}>
        <Divider />
        <Space h={20} />
        <Text size="md" c="dimmed">
          {t("photosText25")}
        </Text>
        <Space h={20} />
        <Divider />
        <Space h={20} />
        <Button onClick={modals.closeInfo} variant="default">
          {t("photosText26")}
        </Button>
      </CustomModal>
    </Container>
  );
};

export default Sentry.withProfiler(Photos, { name: "Photos" });
