import loadScript from "load-script";
import each from "lodash/each";
import { useCallback, useEffect, useState } from "react";

let callbacks: (() => void)[];

const useGoogleMapsApi = () => {
  const [loading, setLoading] = useState(false);
  const [googleMapsApi, setApi] = useState<typeof google.maps>();

  const callback = useCallback(() => {
    setApi(window.google.maps);
  }, []);

  useEffect(() => {
    if (loading) {
      callbacks.push(callback);
      return;
    }

    if (googleMapsApi) return;

    setLoading(true);

    loadScript(`https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}&libraries=places`, { async: true }, () => {
      setLoading(false);
      setApi(window.google.maps);
      each(callbacks, (init) => init());
      callbacks = [];
    });
  }, []);

  return googleMapsApi;
};

export default useGoogleMapsApi;
