import { Button, Card, Flex, Group, Text } from "@mantine/core";
import { IconPlus, IconPuzzle } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Conditional from "../../components/Conditional";
import useElementHover from "../../hooks/useElementHover";
import { CartItem, Estimate } from "../../typing/gql.schema";
import useAddCartItem from "../hooks/useAddCartItem";

type Props = {
  item: CartItem;
  estimate?: Estimate;
  refreshQuote: () => void;
  openSubModal: (item: CartItem) => void;
};

const QuoteMissingItem: React.FC<Props> = ({ estimate, item, openSubModal, refreshQuote }) => {
  const { t } = useTranslation();
  const requestSubButtonHover = useElementHover();
  const { addCartItem, loading } = useAddCartItem();

  return (
    <Container shadow="sm" p="md" radius="xs" withBorder data-testid="missing-quote-item">
      <Flex gap="md" justify="space-between" align="flex-start" direction="row" className="flex">
        <Text>
          <strong>{item?.quantity}</strong> {item?.size?.name} <em>{item?.plant?.latinName}</em> {item?.notes} ({item?.plant?.commonName})&nbsp;
          {item?.ignore && <span>{t("quotesText14")}</span>} {!item?.ignore && <span>{t("quotesText15")}</span>}
        </Text>
        <Group className="buttons-group">
          <Conditional renderIf={estimate?.editable && item?.ignore}>
            <Button
              loading={loading}
              leftSection={<IconPlus />}
              onClick={async () => {
                await addCartItem(item);
                refreshQuote();
              }}
              ref={requestSubButtonHover.hoverRef}
              data-testid="missing-quote-item-request-sub-btn"
              variant={requestSubButtonHover.isHover ? "outline" : "default"}
            >
              {t("quotesText16")}
            </Button>
          </Conditional>
          <Conditional renderIf={estimate?.editable && !item?.ignore}>
            <Button
              className="request-sub-btn"
              leftSection={<IconPuzzle />}
              onClick={() => openSubModal(item)}
              ref={requestSubButtonHover.hoverRef}
              data-testid="missing-quote-item-request-sub-btn"
              variant={requestSubButtonHover.isHover ? "outline" : "default"}
            >
              {t("quotesText17")}
            </Button>
          </Conditional>
        </Group>
      </Flex>
    </Container>
  );
};

const Container = styled(Card)`
  margin-bottom: 30px;
  background-color: ${({ theme }) => {
    return theme.darkMode ? theme.colors?.dark?.[5] : theme.colors?.gray?.[0];
  }};

  .flex {
    flex-wrap: nowrap;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  .buttons-group {
    @media (max-width: 768px) {
      width: 100%;
      padding-top: 10px;
      justify-content: center;
    }
  }

  .request-sub-btn {
    background-color: transparent !important;
  }
` as typeof Card;

export default QuoteMissingItem;
