import { Container, Stepper } from "@mantine/core";
import * as Sentry from "@sentry/react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useMediaQuery } from "usehooks-ts";
import { FadeTransition } from "../components/Transitions";
import useUpdateUrlSearchParams from "../hooks/useUpdateUrlSearchParams";
import PlantEntry from "./components/PlantEntry";
import ProjectInfo from "./components/ProjectInfo";
import RequestSuccess from "./components/RequestSuccess";
import StepDescription from "./components/StepDescription";
import useRequests from "./hooks/useRequests";
import useSubmitRfq from "./hooks/useSubmitRfq";

const Requests: React.FC = () => {
  const { t } = useTranslation();
  const { submitRfq, loading } = useSubmitRfq();
  const request = useRequests({ submitRfq });
  useUpdateUrlSearchParams({ source: "get-started" });
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <FadeTransition>
      <Styled size="lg">
        <Stepper active={request.currentStep} orientation={isMobile ? "vertical" : "horizontal"}>
          <Stepper.Step label={t("requestsText1")} description={<StepDescription>{t("requestsText2")}</StepDescription>}>
            <PlantEntry transitionDirection={request.transitionDirection} onNext={request.nextStep} />
          </Stepper.Step>
          <Stepper.Step label={t("requestsText3")} description={<StepDescription>{t("requestsText4")}</StepDescription>}>
            <ProjectInfo
              loading={loading}
              onPrevious={request.prevStep}
              onSubmit={request.onFinishProjectInfo}
              transitionDirection={request.transitionDirection}
            />
          </Stepper.Step>
          <Stepper.Step label={t("requestsText5")} description={<StepDescription>{t("requestsText6")}</StepDescription>}>
            <RequestSuccess transitionDirection={request.transitionDirection} onBuyAgain={request.onBuyAgain} />
          </Stepper.Step>
        </Stepper>
      </Styled>
    </FadeTransition>
  );
};

const Styled = styled(Container)`
  min-height: 68vh;
  margin-top: 50px;
  padding-bottom: 50px;

  .mantine-Stepper-verticalSeparator {
    min-height: 1000px !important;
  }
`;

export default Sentry.withProfiler(Requests, { name: "Requests" });
