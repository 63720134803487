import { Button, Divider, Group, Space, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import CustomModal from "../../components/CustomModal";
import { Estimate, EstimateItem } from "../../typing/gql.schema";
import useQuoteItemByCartItemID from "../hooks/useQuoteItemByCartItemID";
import useRemoveItem from "../hooks/useRemoveItem";

type Props = {
  isOpen: boolean;
  estimate?: Estimate;
  onClose: () => void;
  refreshQuote: () => void;
  activeItem?: Partial<EstimateItem>;
};

const QuoteItemRemoveModal: React.FC<Props> = ({ isOpen, onClose, estimate, activeItem, refreshQuote }) => {
  const { t } = useTranslation();
  const { remove, loading } = useRemoveItem();
  const quoteItemByCartItemID = useQuoteItemByCartItemID(estimate, activeItem?.cartItem?.id!);

  return (
    <CustomModal size="xl" centered opened={isOpen} onClose={onClose} title={t("quotesText36")}>
      <Divider />
      <Space h={15} />
      <Text>
        {t("quotesText37")} <strong>{activeItem?.cartItem?.quantity}</strong>&nbsp;
        {quoteItemByCartItemID?.yardProduct?.size?.name}
        <em>{quoteItemByCartItemID?.yardProduct?.plant?.latinName}</em>
        {t("quotesText38")}
      </Text>
      <Conditional renderIf={estimate?.rebate! > 0}>
        <Space h={15} />
        {t("quotesText39")}&nbsp;
        <span className="icon is-small saver">
          <i className="fa fa-leaf"></i>
        </span>
        &nbsp;<span className="saver">{t("quotesText40")}</span>.
      </Conditional>
      <Space h={15} />
      <Text fs="italic">{t("quotesText41")}</Text>
      <Space h={15} />
      <Divider />
      <Space h={15} />
      <Group>
        <Button
          color="red"
          loading={loading}
          data-testid="confirm-remove-item-btn"
          onClick={async () => {
            await remove({ id: activeItem?.cartItem?.id! });
            onClose();
            refreshQuote();
          }}
        >
          {t("quotesText43")}
        </Button>
        <Button variant="default" onClick={onClose}>
          {t("quotesText78")}
        </Button>
      </Group>
    </CustomModal>
  );
};

export default QuoteItemRemoveModal;
