import { Card, Space, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { FadeTransition } from "../../components/Transitions";
import { Order } from "../../typing/gql.schema";
import { formatCurrency } from "../../utils/utils";

type Props = {
  order: Order;
};

const OrderTotals: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation();
  return (
    <FadeTransition>
      <Card shadow="sm" p="md" radius="xs" withBorder>
        <Text fz={20}>
          {t("orderText9")}
          {formatCurrency(order?.quote?.estimate?.subtotal)}
        </Text>
        <Text fz={20}>
          {t("orderText10")}
          <sup>1</sup>:&nbsp;
          {formatCurrency(order?.quote?.estimate?.deliveryFee)}
        </Text>
        <Text fz={20}>
          {t("orderText11")}
          <sup>2</sup>:&nbsp;{formatCurrency(order?.quote?.estimate?.tax)} ({(order?.quote?.rfq?.taxRate! / 100) * 100}%)
        </Text>
        <Text fw={700} fz={20}>
          {t("orderText12")}
          {formatCurrency(order?.quote?.estimate?.total)}
        </Text>
        <Space h={20} />
        <Text c="red" fz={12}>
          <sup>1</sup> {t("orderText17")}
        </Text>
        <Text fz={12}>
          <sup>2</sup> {t("orderText13")}&nbsp;
          <Text variant="link" component="a" href="mailto:william@bidscape.com">
            {t("orderText14")}
          </Text>
        </Text>
      </Card>
    </FadeTransition>
  );
};

export default OrderTotals;
