import { gql, useQuery } from "@apollo/client";
import { Query, QueryClosedEstimatesForBuyerArgs, QueryClosedQuotesForBuyerArgs } from "../../typing/gql.schema";
import { ClosedRfq, ClosedRfqType } from "../types";

export const closedRfqsGql = gql`
  query closedRfqs($id: ID!) {
    closedQuotesForBuyer(id: $id) {
      __typename
      id
      phased
      status
      deliveryDate
      cart {
        id
        name
      }
      bestQuote {
        id
        hmac
      }
    }
    closedEstimatesForBuyer(id: $id) {
      __typename
      id
      phased
      status
      deliveryDate
      cart {
        id
        name
      }
      bestQuote {
        id
        hmac
      }
    }
  }
`;

const useClosedRfqsQuery = (id: string) => {
  const { loading, error, data, refetch } = useQuery<
    {
      closedQuotesForBuyer: Query["closedQuotesForBuyer"];
      closedEstimatesForBuyer: Query["closedEstimatesForBuyer"];
    },
    QueryClosedEstimatesForBuyerArgs | QueryClosedQuotesForBuyerArgs
  >(closedRfqsGql, {
    variables: {
      id,
    },
    skip: !id,
  });

  const quotes = (data?.closedQuotesForBuyer || []).reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, { ...current, closedRfqType: "quote" as ClosedRfqType }];
  }, [] as Array<ClosedRfq>);

  const estimates = (data?.closedEstimatesForBuyer || []).reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, { ...current, closedRfqType: "estimate" as ClosedRfqType }];
  }, [] as Array<ClosedRfq>);

  const rfqs = [...quotes, ...estimates].sort((a, b) => {
    if (a.deliveryDate < b.deliveryDate) return 1;
    if (a.deliveryDate > b.deliveryDate) return -1;
    return 0;
  });

  return { loading, error, refetch, rfqs };
};

export default useClosedRfqsQuery;
