import { Button, Card, CardSection, Center, Image } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { FadeTransition } from "../../components/Transitions";
import { Photo } from "../../typing/gql.schema";
import useSetCartItemPhoto from "../hooks/useSetCartItemPhoto";

type Props = {
  photo: Photo;
  refetchDefaultPhoto: () => void;
  openMediaModal: (filename?: string) => void;
  cartItemId: string;
};

const PhotoItem: React.FC<Props> = ({ photo, openMediaModal, cartItemId, refetchDefaultPhoto }) => {
  const { t } = useTranslation();
  const setCartItemPhoto = useSetCartItemPhoto(cartItemId);

  return (
    <FadeTransition>
      <Card shadow="xs" radius="sm" withBorder data-testid="option-item">
        <CardSection>
          <Image
            className="photo"
            data-testid="show-photo-btn"
            onClick={() => openMediaModal(photo?.filename)}
            src={`//img.bidscape.com/yard-product-photos/${photo?.filename}`}
          />
        </CardSection>
        <CardSection withBorder inheritPadding py="xs">
          <Center>
            <Button
              variant="default"
              loading={setCartItemPhoto.loading}
              onClick={async () => {
                await setCartItemPhoto.setCartItemPhoto(photo.id);
                refetchDefaultPhoto();
              }}
            >
              {t("defaultPhotoText22")}
            </Button>
          </Center>
        </CardSection>
      </Card>
    </FadeTransition>
  );
};

export default PhotoItem;
