import { gql, useLazyQuery } from "@apollo/client";
import { Query, QueryUserArgs } from "../../../typing/gql.schema";

export const userDetailQuery = gql`
  query userQuery($id: ID!) {
    user(id: $id) {
      __typename
      id
      name
      email
      phone
      wholesaleLicenseURL
      hasWholesaleLicense
      created
      modified
    }
  }
`;

const useUserQuery = () => {
  const [query, { loading, error, data, refetch }] = useLazyQuery<{ user: Query["user"] }, QueryUserArgs>(userDetailQuery, {
    fetchPolicy: "network-only",
  });
  return { loading, error, data, refetch, query };
};

export default useUserQuery;
