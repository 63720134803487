import * as Sentry from "@sentry/react";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import usePrevious from "../../../hooks/usePrevious";

const useBadRouteLog = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const previousRoute = usePrevious(location.pathname);

  const goToNextPage = useCallback(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (location.pathname === "/not-found" && previousRoute !== location.pathname) {
      goToNextPage();
      Sentry.captureException(new Error(`Bad route: ${previousRoute}`));
    }
  }, [location.pathname, previousRoute, goToNextPage]);
};

export default useBadRouteLog;
