const estimate = {
  estimateText1: "Estimate",
  estimateText2: "Checking availability",
  estimateText3: "Estimated Delivery Date:",
  estimateText4: "Estimate Notice:",
  estimateText5: "The prices below are the",
  estimateText6: "maximum",
  estimateText7: "that you will be charged. We've calculated prices that we",
  estimateText8: "guarantee",
  estimateText9: `so you can use them directly without worrying about overages or safety margins. However, we do not know availability or final (lower) pricing until you click the button below to check it, which can take up to 24 hours.`,
  estimateText10: "Estimate Notice:",
  estimateText11: "The prices below are",
  estimateText12: "estimated",
  estimateText13: `based on a detailed analysis. We do not know availability or final pricing until you click the button below to check it, which can take up to 24 hours.`,
  estimateText14: "for",
  estimateText15: "less than",
  estimateText16: "each and",
  estimateText16_1: "total",
  estimateText17: "Remove",
  estimateText18: "was removed",
  estimateText19: "Add",
  estimateText20: "Ready to buy",
  estimateText21: "Back",
  estimateText22: "Share",
  estimateText23: "Download estimate as Excel (.xlsx)",
  estimateText24: "Share estimate",
  estimateText25: "Share this estimate with:",
  estimateText26: "Email address",
  estimateText27: "A valid email is require",
  estimateText28: "Share",
  estimateText29: "Add plant",
  estimateText30: "Will you like to add plant?",
  estimateText31: "Confirm",
  estimateText32: "Remove Plant",
  estimateText33: "Will you like to remove plant?",
  estimateText34: "Confirm",
  estimateText35: "Contract and Hold",
  estimateText36: "Ready to secure your plant material well in advance of your projected delivery date? Please email",
  estimateText37: "to discuss contract growing or long term holding of your plant material.",
  estimateText38: "Ready to buy?",
  estimateText39: "Ready to set up immediate delivery? The estimated delivery date we have on file is {{deliveryDate}}. If you're ready to order, email",
  estimateText40: "and let us know what your updated delivery date is so that we can get your order processed for the earliest available shipment.",
  estimateText41: "individually",
  estimateText42: "bundled",
  estimateText43: "or",
  estimateText44: "Subtotal:",
  estimateText45: "Estimated delivery",
  estimateText46: "Subtotal:",
  estimateText47: "- Your delivery total is based on a single delivery for all listed materials. Split deliveries will result in additional costs.",
  estimateText48: "Individual subtotal: ",
  estimateText49: "Bundled subtotal: ",
  estimateText50: "saved",
  estimateText60: "Close",
  estimateText61: "Commonly purchased with other items in your project",
  estimateText62: "Add plant",
  estimateText63: "Excel (.xlsx)",
  estimateText64: "Estimated Delivery Date",
  estimateText65: "Share",
  estimateText66: "This estimate has expired and has been archived. To renew it, please contact ",
  estimateText67: "Service Fee",

  estimateQueryErrorText1: `An error occurred while loading your estimate, please make sure you have an internet connection, or else contact Jules at`,
  estimateQueryErrorText2: `An error occurred while loading your estimate, contact Jules at`,
  estimateQueryErrorText3: `for assistance.`,
  estimateQueryErrorText4: `Back`,
  estimateQueryErrorText5: `Try again`,

  estimateItemAvailabilityText1: "Loading availability...",
  estimateItemAvailabilityText2: "Low Availability",
  estimateItemAvailabilityText3: "Limited Availability",
  estimateItemAvailabilityText4: "Available",
};

export default estimate;
