import { gql, useQuery } from "@apollo/client";
import { Order, Query, QueryClosedOrdersForBuyerArgs } from "../../typing/gql.schema";

export const closedOrdersGql = gql`
  query closedOrders($id: ID!) {
    closedOrdersForBuyer(id: $id) {
      __typename
      id
      quote {
        id
        rfq {
          id
          phased
          deliveryDate
          cart {
            id
            name
          }
        }
      }
    }
  }
`;

const useClosedOrdersQuery = (id: string) => {
  const { loading, error, data, refetch } = useQuery<
    {
      closedOrdersForBuyer: Query["closedOrdersForBuyer"];
    },
    QueryClosedOrdersForBuyerArgs
  >(closedOrdersGql, {
    variables: {
      id,
    },
    skip: !id,
  });

  const closedOrders = (data?.closedOrdersForBuyer || []).reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, current];
  }, [] as Array<Order>);

  return { loading, error, closedOrders, refetch };
};

export default useClosedOrdersQuery;
