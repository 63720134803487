import { gql, useQuery } from "@apollo/client";
import { Query, QueryEstimateItemArgs } from "../../typing/gql.schema";

export const estimateItemsAvailabilityGQL = gql`
  query estimateForRfq($id: ID!) {
    estimateForRfq(id: $id) {
      id
      __typename
      estimateItems {
        id
        __typename
        availability
      }
    }
  }
`;

const useEstimateItemsAvailability = (id: string = "") => {
  const { loading, data, refetch, error } = useQuery<{ estimateForRfq: Query["estimateForRfq"] }, QueryEstimateItemArgs>(estimateItemsAvailabilityGQL, {
    variables: {
      id,
    },
  });
  return { loading, data, refetch, error };
};

export default useEstimateItemsAvailability;
