import { Accordion, Tabs } from "@mantine/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Rfq } from "../../typing/gql.schema";
import ClosedRequests from "./ClosedRequests";
import OpenRequests from "./OpenRequests";

interface Props {
  rfqs: Array<Rfq>;
}

const RequestsPanel: React.FC<Props> = ({ rfqs }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string | null>("openRequests");

  return (
    <Accordion variant="contained" defaultValue={rfqs.length ? "requests" : ""} data-testid="open-requests">
      <Accordion.Item value="requests">
        <Accordion.Control>
          <strong>
            {t("requestsPanelText1")} ({rfqs.length})
          </strong>
        </Accordion.Control>
        <Accordion.Panel>
          <Tabs keepMounted={false} value={activeTab} onChange={setActiveTab}>
            <Tabs.List>
              <Tabs.Tab value="openRequests"> {t("requestsPanelText2")}</Tabs.Tab>
              <Tabs.Tab value="closedRequests"> {t("requestsPanelText3")}</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="openRequests" pt="xs">
              <OpenRequests rfqs={rfqs} />
            </Tabs.Panel>
            <Tabs.Panel value="closedRequests" pt="xs">
              <ClosedRequests />
            </Tabs.Panel>
          </Tabs>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default RequestsPanel;
