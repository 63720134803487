import { Estimate, QuoteItem } from "../../typing/gql.schema";

const useQuoteItemByCartItemID = (estimate?: Estimate, cartItemId?: string) => {
  const bestQuoteItems = estimate?.rfq.bestQuote?.quoteItems.reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, current];
  }, [] as QuoteItem[]);

  const quoteItemByCartItemID = bestQuoteItems?.find((quoteItem) => quoteItem.cartItem.id === cartItemId);

  return quoteItemByCartItemID;
};

export default useQuoteItemByCartItemID;
