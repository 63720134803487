import { Button, Card, Flex, Group, Space, Text } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Conditional from "../../components/Conditional";
import useElementHover from "../../hooks/useElementHover";
import { CartItem, Estimate } from "../../typing/gql.schema";
import useAddCartItem from "../hooks/useAddCartItem";

type Props = {
  item: CartItem;
  estimate?: Estimate;
  refreshEstimate: () => void;
};

const EstimateMissingItem: React.FC<Props> = ({ item, estimate, refreshEstimate }) => {
  const { t } = useTranslation();
  const requestSubButtonHover = useElementHover();
  const { addCartItem, loading } = useAddCartItem();
  return (
    <>
      <Container shadow="sm" p="md" radius="xs" withBorder data-testid="missing-estimate-item">
        <Flex gap="md" justify="space-between" align="flex-start" direction="row" className="flex">
          <Text>
            <strong>{item?.quantity}</strong>&nbsp;{item?.size?.name}&nbsp;<em>{item?.plant?.latinName}</em>&nbsp;{item?.notes}&nbsp;({item?.plant?.commonName}
            )&nbsp;{t("estimateText18")}
          </Text>
          <Conditional renderIf={estimate?.editable}>
            <Group className="buttons-group">
              <Button
                loading={loading}
                leftSection={<IconPlus />}
                className="add-item-btn"
                onClick={async () => {
                  await addCartItem(item);
                  refreshEstimate();
                }}
                ref={requestSubButtonHover.hoverRef}
                data-testid="add-estimate-item-btn"
                variant={requestSubButtonHover.isHover ? "outline" : "default"}
              >
                {t("estimateText19")}
              </Button>
            </Group>
          </Conditional>
        </Flex>
      </Container>
      <Space h={"md"} />
    </>
  );
};

const Container = styled(Card)`
  background-color: ${({ theme }) => {
    return theme.darkMode ? theme.colors?.dark?.[6] : theme.colors?.gray?.[0];
  }};

  .flex {
    flex-wrap: nowrap;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  .buttons-group {
    @media (max-width: 768px) {
      width: 100%;
      padding-top: 10px;
      justify-content: center;
    }
  }

  .add-item-btn {
    background-color: transparent !important;
  }
` as typeof Card;

export default EstimateMissingItem;
