import { Center, Divider, Space, Title } from "@mantine/core";
import { EstimateItem } from "../../typing/gql.schema";

type Props = {
  estimateItem: EstimateItem;
};

const DefaultPhotoHeader: React.FC<Props> = ({ estimateItem }) => {
  return (
    <>
      <Center>
        <Title>
          ({estimateItem?.cartItem?.quantity}) {estimateItem?.cartItem?.size?.name} {estimateItem?.cartItem?.plant?.latinName}
        </Title>
      </Center>
      <Space h={8} />
      <Divider />
      <Space h={15} />
    </>
  );
};

export default DefaultPhotoHeader;
