import { Alert, Center, Container, Divider, Loader, Stack, Text } from "@mantine/core";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import CheckoutForm from "./components/CheckoutForm";
import useCardPaymentIntent from "./hooks/useCardPaymentIntent";
import useQuotesQuery from "../quotes/hooks/useQuotesQuery";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const Payment: React.FC = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const intent = useCardPaymentIntent(searchParams.get("rfqId") ?? "");
  const estimate = useQuotesQuery(searchParams.get("rfqId") ?? "");

  if (intent.loading || estimate.loading) {
    return (
      <Center h="20vh">
        <Loader type="dots" />
      </Center>
    );
  }

  if (!intent?.options?.clientSecret || !estimate?.estimate) {
    return (
      <Container size="md">
        <Alert title={t("paymentText3")} color="red">
          {t("paymentText4")}
        </Alert>
      </Container>
    );
  }

  return (
    <Container size="sm">
      <Stack gap="xs">
        <Text ta="center" size="xl" fw={700} mt="xl">
          {t("paymentText1")}
        </Text>
        <Text ta="center">{t("paymentText2")}</Text>
      </Stack>
      <Divider mb="xl" mt="md" />

      <Elements stripe={stripePromise} options={intent.options}>
        <CheckoutForm estimate={estimate?.estimate} />
      </Elements>
    </Container>
  );
};

export default Payment;
