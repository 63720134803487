import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import cookies from "js-cookie";
import { useCallback } from "react";
import { Mutation, MutationUpdateUserCidArgs } from "../../typing/gql.schema";

const updateUserCidGQL = gql`
  mutation updateUserCid($cid: String!) {
    updateUserCid(cid: $cid)
  }
`;

const useUpdateUserCid = () => {
  const [mutate, { loading, data, error }] = useMutation<{ updateUserCid: Mutation["updateUserCid"] }, MutationUpdateUserCidArgs>(updateUserCidGQL);

  const updateUserCid = useCallback(async () => {
    try {
      const _ga = cookies.get("_ga");
      if (!_ga) return;
      const cid = _ga.split(".").slice(2).join(".");
      if (!cid) return;
      const res = await mutate({ variables: { cid: cid! } });
      if (!res.data?.updateUserCid) throw new Error("Error updating user CID");
    } catch (error: any) {
      Sentry.captureException(error, { extra: { hook: "useUpdateUserCid" } });
    }
  }, [mutate]);

  return { updateUserCid, loading, data, error };
};

export default useUpdateUserCid;
