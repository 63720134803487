import { Card, Center, Container, Loader, Space, Text, Title } from "@mantine/core";
import * as Sentry from "@sentry/react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FadeTransition } from "../components/Transitions";
import useLinkAuthenticator from "./hooks/useLinkAuthenticator";

const AuthenticateLink: React.FC = () => {
  useLinkAuthenticator();
  const { t } = useTranslation();
  return (
    <FadeTransition>
      <Styled>
        <Card p="lg" radius="md" withBorder className="page-card">
          <Center>
            <Title>{t("authenticateLinkText1")}</Title>
          </Center>
          <Center>
            <Text size="sm" c="dimmed">
              {t("authenticateLinkText2")}
            </Text>
          </Center>
          <Space h="lg" />
          <Center>
            <Loader variant="dots" />
          </Center>
          <Space h="lg" />
        </Card>
      </Styled>
    </FadeTransition>
  );
};

const Styled = styled(Container)`
  height: 100%;
  .page-card {
    margin: auto;
    margin-top: 50px;
    max-width: 500px;
  }
`;

export default Sentry.withProfiler(AuthenticateLink, { name: "AuthenticateLink" });
