import { Accordion, Button, Group, Space, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";

const ActionQueue: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Accordion variant="contained" defaultValue={"loyaltyTracker"} data-testid="action-queue">
      <Accordion.Item value="loyaltyTracker">
        <Accordion.Control>
          <strong>{t("action_queue_card_title")} (6)</strong>
        </Accordion.Control>
        <Accordion.Panel>
          <Text ta="center">Is 'La Scrilla Residence' still an active job?</Text>
          <Space h={15} />
          <Group justify="center">
            <Button variant="outline">{t("action_queue_card_keep_button")}</Button>
            <Button color="red" variant="outline">
              {t("action_queue_card_archive_button")}
            </Button>
          </Group>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default ActionQueue;
