import { useFormik } from "formik";
import { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import { IRequestMetaData } from "../../typing/declarations";

export const getDefaultVDeliveryDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 2);
  return date;
};

const validationSchema = Yup.object().shape({
  cartName: Yup.string().required("Required"),
  phased: Yup.boolean().required("Required"),
  deliveryDate: Yup.date().required("Required"),
  deliveryAddress: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const useProjectInfoForm = (onSubmit?: (args: IRequestMetaData) => void) => {
  const { activeUser } = useAuth();

  const initialValues = {
    status: 0,
    cartName: "",
    phased: false,
    deliveryAddress: "",
    deliveryDate: getDefaultVDeliveryDate(),
    email: activeUser?.email || "",
  };

  const [storedFormValues, setStoredFormValues] = useLocalStorage("project-info-form", initialValues);

  const handleSubmit = (values: IRequestMetaData) => {
    onSubmit?.({ ...values, deliveryDate: new Date(values.deliveryDate).toISOString().slice(0, 10) });
  };

  const form = useFormik({
    validationSchema,
    onSubmit: handleSubmit,
    //@ts-ignore
    initialValues: { ...storedFormValues, deliveryDate: new Date(storedFormValues.deliveryDate) },
  });

  const setDeliveryAddressWithGooglePlaces = (place: google.maps.places.PlaceResult) => {
    form.setFieldValue("deliveryAddress", place.formatted_address);
  };

  useEffect(() => {
    //@ts-ignore
    setStoredFormValues({ ...form.values });
  }, [form.values]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return { ...form, setDeliveryAddressWithGooglePlaces };
};

export default useProjectInfoForm;
