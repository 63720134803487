import { CartItem, Estimate } from "../../typing/gql.schema";

const useMissingItems = (estimate?: Estimate) => {
  const missingItems = estimate?.rfq?.cart?.cartItems?.reduce((acc, current) => {
    let found = false;
    estimate.estimateItems.map((estimateItem) => {
      if (estimateItem?.cartItem.id === current?.id) {
        found = true;
      }
    });
    if (!found && current) return [...acc, current];
    return acc;
  }, [] as CartItem[]);

  const sortedMissingItems = missingItems?.sort((a: CartItem, b: CartItem): number => {
    if (a.plant.latinName < b.plant.latinName) {
      return -1;
    } else if (a.plant.latinName > b.plant.latinName) {
      return 1;
    }
    return 0;
  });

  return sortedMissingItems || [];
};

export default useMissingItems;
