const dashboard = {
  //actions queue
  action_queue_card_title: "Action Queue",
  action_queue_card_keep_button: "Keep open",
  action_queue_card_archive_button: "Archive project",

  dashboardText1: "Dashboard",
  dashboardText2: "Create new request",

  requestsPanelText1: "Requests",
  requestsPanelText2: "Open Requests",
  requestsPanelText3: "Closed Requests",

  openRequestsText1: "Open Requests",
  openRequestsText2: "Request #",
  openRequestsText3: "Created:",
  openRequestsText4: "TBD (phased delivery)",
  openRequestsText5: "View",
  openRequestsText6: "Processing",
  openRequestsText7: "You currently do not have any open requests. New requests will show up once received!",
  openRequestsText8: `This request is currently being processed by our team. You will receive an email once it is ready to be viewed.`,
  openRequestsText9: "No open requests",

  closedRequestsText1: "No Closed Requests",
  closedRequestsText2: " You currently do not have any closed requests. Requests will show up here when closed!",
  closedRequestsText3: "Request #",
  closedRequestsText4: "Created:",
  closedRequestsText5: "phased delivery",
  closedRequestsText6: "View",

  ordersPanelText1: "Orders",
  ordersPanelText2: "Open Orders",
  ordersPanelText3: "Closed Orders",

  openOrdersText1: "Order #",
  openOrdersText2: "is scheduled for",
  openOrdersText3: "TBD (phased delivery)",
  openOrdersText4: "View",
  openOrdersText5: "No open orders",
  openOrdersText6: `You currently do not have any pending orders. New orders will show up once received!`,
  openOrdersText7: "Open Orders",

  closedOrdersText1: "No Closed Orders",
  closedOrdersText2: " You currently do not have any closed orders. Orders will show up here when closed!",
  closedOrdersText3: "Order #",
  closedOrdersText4: "was scheduled for",
  closedOrdersText5: "phased delivery",
  closedOrdersText6: "View",
};

export default dashboard;
