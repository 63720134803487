const photos = {
  photosText0: "CURRENTLY SELECTED -",
  photosText1: "CURRENTLY SELECTED -",
  photosText2: "- Item #",
  photosText3: "Height:",
  photosText4: "Width:",
  photosText5: "Current Unit Price:",
  photosText6: "This item is currently selected for your quote.",
  photosText7: "See options from our partner network",
  photosText8: "Load options",
  photosText9: "No other options available.",
  photosText10: "OPTION #",
  photosText11: "- Item #",
  photosText12: "Height:",
  photosText13: "Width:",
  photosText14: "Adjusted Unit Price: ",
  photosText15: "will no longer be available if selected",
  photosText16: "will be available if selected",
  photosText17: "will be subbed with",
  photosText18: "if selected",
  photosText19: "will no longer be subbed if selected",
  photosText20: "Adjusted Unit Price: ",
  photosText21: "Calculating...",
  photosText22: "Select item",
  photosText23: "Back",
  photosText24: "Adjusted unit prices",
  photosText25: "Adjusted unit prices reflect the additional costs incurred elsewhere in the order as a result of item selection.",
  photosText26: "Close",

  quoteItemQueryText1: `An error occurred while loading default photo, please make sure you have an internet connection, or else contact Jules at`,
  quoteItemQueryText2: `An error occurred while loading default photo, contact Jules at`,
  quoteItemQueryText3: `for assistance.`,
  quoteItemQueryText4: `Back`,
  quoteItemQueryText5: `Try again`,

  photoOptionsQueryErrorText1: `An error occurred while loading photo options, please make sure you have an internet connection, or else contact Jules at`,
  photoOptionsQueryErrorText2: `An error occurred while loading photo options, contact Jules at`,
  photoOptionsQueryErrorText3: `for assistance.`,
  photoOptionsQueryErrorText4: `Back`,
  photoOptionsQueryErrorText5: `Try again`,

  useForceQuoteItemText1: "Successful",
  useForceQuoteItemText2: "Option selected successfully.",
  useForceQuoteItemText3: "Error",
  useForceQuoteItemText4: "Option couldn't be selected. Contact jules@bdiscape.com for help.",
};

export default photos;
