const defaultPhoto = {
  defaultPhotoText1: "CURRENTLY SELECTED -",
  defaultPhotoText2: "- Item #",
  defaultPhotoText3: "Height:",
  defaultPhotoText4: "Width:",
  defaultPhotoText5: "Current Unit Price:",
  defaultPhotoText6: "This is a representative photo",
  defaultPhotoText7: "See representative options from our internal stock photos",
  defaultPhotoText8: "Change photo",
  defaultPhotoText9: "No other options available.",
  defaultPhotoText10: "OPTION #",
  defaultPhotoText11: "- Item #",
  defaultPhotoText12: "Height:",
  defaultPhotoText13: "Width:",
  defaultPhotoText14: "Adjusted Unit Price: ",
  defaultPhotoText15: "will no longer be available if selected",
  defaultPhotoText16: "will be available if selected",
  defaultPhotoText17: "will be subbed with",
  defaultPhotoText18: "if selected",
  defaultPhotoText19: "will no longer be subbed if selected",
  defaultPhotoText20: "Adjusted Unit Price: ",
  defaultPhotoText21: "Calculating...",
  defaultPhotoText22: "Select as default",
  defaultPhotoText23: "Back",
  defaultPhotoText24: "Adjusted unit prices reflect the additional costs incurred elsewhere in the order as a result of item selection.",
  defaultPhotoText25: "Edit Photo",

  noPhotoOptionsText1: "No options",
  noPhotoOptionsText2: "No other photo options available, please contact Jules at",
  noPhotoOptionsText3: "for assistance.",

  defaultPhotoQueryErrorText1: `An error occurred while loading default photo, please make sure you have an internet connection, or else contact Jules at`,
  defaultPhotoQueryErrorText2: `An error occurred while loading default photo, contact Jules at`,
  defaultPhotoQueryErrorText3: `for assistance.`,
  defaultPhotoQueryErrorText4: `Back`,
  defaultPhotoQueryErrorText5: `Try again`,

  useSetCartItemPhotoText1: "Default photo change successfully",
  useSetCartItemPhotoText2: "Error loading default photo options",

  defaultPhotoOptionsQueryErrorText1: `An error occurred while loading photo options, please make sure you have an internet connection, or else contact Jules at`,
  defaultPhotoOptionsQueryErrorText2: `An error occurred while loading photo options, contact Jules at`,
  defaultPhotoOptionsQueryErrorText3: `for assistance.`,
  defaultPhotoOptionsQueryErrorText4: `Back`,
  defaultPhotoOptionsQueryErrorText5: `Try again`,
};

export default defaultPhoto;
