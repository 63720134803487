import { Alert, Badge, Button, Center, Divider, Grid, Group, HoverCard, Space, Text } from "@mantine/core";
import { IconCamera } from "@tabler/icons-react";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useHover } from "usehooks-ts";
import Conditional from "../../components/Conditional";
import { RfqStatus } from "../../enums";
import { Rfq } from "../../typing/gql.schema";
import { formatDate } from "../../utils/utils";

interface Props {
  rfqs: Array<Rfq>;
}

interface OpenOrdersItemProps {
  rfq: Rfq;
  index: number;
  lastIndex: number;
}

const OpenRequestsItem: React.FC<OpenOrdersItemProps> = ({ rfq, lastIndex, index }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const viewButtonRef = useRef(null);
  const isViewButtonHover = useHover(viewButtonRef);

  return (
    <>
      <Grid columns={34} data-testid="open-request-item">
        <Grid.Col span={{ base: 34, md: 31, lg: 28 }}>
          <Text>
            {t("openRequestsText2")}
            {rfq?.id} <em>{rfq.cart.name}</em> {t("openRequestsText3")} <Conditional renderIf={!rfq?.phased}>{formatDate(rfq?.deliveryDate)}</Conditional>
            <Conditional renderIf={rfq.phased}>{t("openRequestsText4")}</Conditional>&nbsp;
            <Conditional renderIf={rfq.status === 6}>
              <IconCamera className="camera" size={20} strokeWidth={3} />
            </Conditional>
            <Conditional renderIf={rfq?.status === 7}>
              <IconCamera className="camera" color="#3BB9FF" size={20} strokeWidth={3} />
            </Conditional>
          </Text>
        </Grid.Col>
        <Grid.Col span={{ base: 34, md: 3, lg: 6 }}>
          <Group justify="center">
            <Conditional renderIf={rfq.status === RfqStatus.NewRfq}>
              <Center>
                <HoverCard width={280} shadow="md">
                  <HoverCard.Target>
                    <Badge className="badge" variant="gradient" gradient={{ from: "teal", to: "lime", deg: 105 }}>
                      {t("openRequestsText6")}
                    </Badge>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                    <Text size="sm">{t("openRequestsText8")}</Text>
                  </HoverCard.Dropdown>
                </HoverCard>
              </Center>
            </Conditional>
            <Conditional renderIf={rfq.status === RfqStatus.EstimatedRfq || rfq.status === RfqStatus.BiddingRfq}>
              <Button
                ref={viewButtonRef}
                component="a"
                href={`/quotes/estimate/${rfq?.id}`}
                variant={isViewButtonHover ? "outline" : "default"}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/quotes/estimate/${rfq?.id}`);
                }}
              >
                {t("openRequestsText5")}
              </Button>
            </Conditional>
            <Conditional renderIf={rfq.status > RfqStatus.BiddingRfq && rfq.status === RfqStatus.PhotosProvidedRfq}>
              <Button
                variant="outline"
                ref={viewButtonRef}
                component="a"
                href={`/quotes/quote/${rfq?.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/quotes/quote/${rfq?.id}`);
                }}
              >
                {t("openRequestsText5")}
              </Button>
            </Conditional>
            <Conditional renderIf={rfq.status > RfqStatus.BiddingRfq && rfq.status !== RfqStatus.PhotosProvidedRfq}>
              <Button
                ref={viewButtonRef}
                component="a"
                href={`/quotes/quote/${rfq?.id}`}
                variant={isViewButtonHover ? "outline" : "default"}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/quotes/quote/${rfq?.id}`);
                }}
              >
                {t("openRequestsText5")}
              </Button>
            </Conditional>
          </Group>
        </Grid.Col>
      </Grid>
      <Conditional renderIf={index !== lastIndex}>
        <Divider my="xs" />
      </Conditional>
    </>
  );
};

const OpenRequests: React.FC<Props> = ({ rfqs }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Space h={"lg"} />
      {rfqs.map((rfq, index) => (
        <OpenRequestsItem index={index} key={index} rfq={rfq} lastIndex={rfqs.length - 1} />
      ))}
      <Conditional renderIf={!rfqs.length}>
        <Alert title={t("openRequestsText9")} color="teal">
          {t("openRequestsText7")}
        </Alert>
        <Space h={"lg"} />
      </Conditional>
    </Container>
  );
};

const Container = styled.div`
  .camera {
    margin-bottom: -3px;
  }

  .badge {
    text-transform: none !important;
  }
`;

export default OpenRequests;
