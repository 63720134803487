import { Estimate, EstimateItem } from "../../typing/gql.schema";
import isEstimateDiscounted from "./isEstimateDiscounted";

const useShowQuoteItemBundlePricing = (estimate?: Estimate, item?: EstimateItem) => {
  if (isEstimateDiscounted(estimate)) return true;

  if (item?.individualUnitPrice === item?.unitTotal) return false;

  return true;
};

export default useShowQuoteItemBundlePricing;
