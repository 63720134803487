import { Button, Divider, Group, List, Space, TextInput, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "../../components/CustomModal";
import { Estimate } from "../../typing/gql.schema";
import useShareForm from "../hooks/usShareForm";
import useShareRfq from "../hooks/useShareRfq";
import Conditional from "../../components/Conditional";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  estimate?: Estimate;
};

const EstimateItemShareModal: React.FC<Props> = ({ isOpen, onClose, estimate }) => {
  const { t } = useTranslation();
  const shareRfq = useShareRfq(estimate?.rfq.id!, "estimate");
  const form = useShareForm(shareRfq.share, onClose);
  const sharedEmailsList = estimate?.rfq?.sharedWithEmails?.map((email) => <List.Item key={email}>{email}</List.Item>);

  return (
    <CustomModal size="xl" centered opened={isOpen} onClose={onClose} title={t("estimateText24")}>
      <Divider />
      <Space h={15} />
      <TextInput
        type="email"
        name="email"
        aria-label="email"
        onBlur={form.handleBlur}
        onChange={form.handleChange}
        label={t("estimateText25")}
        placeholder={t("estimateText26")}
        error={form.touched.email && form.errors.email ? t("estimateText27") : ""}
      />
      <Space h={15} />
      <Conditional renderIf={sharedEmailsList?.length && sharedEmailsList?.length > 0}>
        <Text fs="italic">Already shared with:</Text>
        <List withPadding fs="italic">{sharedEmailsList}</List>
        <Space h={5} />
      </Conditional>
      <Divider />
      <Space h={15} />
      <Group>
        <Button loading={shareRfq.loading} data-testid="confirm-share-estimate-btn" onClick={() => form.handleSubmit()}>
          {t("estimateText28")}
        </Button>
        <Button variant="default" onClick={onClose}>
          {t("estimateText60")}
        </Button>
      </Group>
    </CustomModal>
  );
};

export default EstimateItemShareModal;
