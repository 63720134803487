import { Card, CardSection, Group, Image, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FadeTransition } from "../../components/Transitions";
import { QuoteItem } from "../../typing/gql.schema";
import { extractFt, extractIn, formatCurrency, getImageUrl } from "../../utils/utils";

type Props = {
  quoteItem: QuoteItem;
  openMediaModal: (filename: string) => void;
};

const CurrentlySelected: React.FC<Props> = ({ quoteItem, openMediaModal }) => {
  const { t } = useTranslation();
  return (
    <FadeTransition>
      <Styled>
        <Card shadow="xs" radius="sm">
          <CardSection p="sm" withBorder className="header">
            {t("photosText1")} ({quoteItem?.cartItem?.quantity}) {quoteItem?.yardProduct?.size?.name} {quoteItem?.yardProduct?.plant?.latinName} (
            {quoteItem?.yardProduct?.plant?.commonName}) {t("photosText2")}
            {quoteItem?.id}
          </CardSection>
          <CardSection p="md">
            <Group>
              {quoteItem?.yardProduct?.approvedPhotos.map((photo, index) => (
                <Image
                  key={index}
                  width={200}
                  height={200}
                  radius="sm"
                  data-testid="show-photo-btn"
                  onClick={() => openMediaModal(photo?.filename!)}
                  src={getImageUrl(photo?.filename)}
                />
              ))}
            </Group>
          </CardSection>
          <CardSection p="md" withBorder>
            <Text>
              {t("photosText3")} {extractFt(quoteItem?.yardProduct?.height)}'{extractIn(quoteItem?.yardProduct?.height)}"
            </Text>
            <Text>
              {t("photosText4")} {extractFt(quoteItem?.yardProduct?.width)}'{extractIn(quoteItem?.yardProduct?.width)}"
            </Text>
            <Text fw={700}>
              {t("photosText5")} {formatCurrency(quoteItem?.estimateItem?.unitTotal)}
            </Text>
            <Text>{t("photosText6")}</Text>
          </CardSection>
        </Card>
      </Styled>
    </FadeTransition>
  );
};

const Styled = styled.div`
  .header {
    color: #38a169;
    font-weight: bold;
    background-color: ${({ theme }) => {
      return theme.darkMode ? theme.colors?.dark?.[5] : theme.colors?.green?.[0];
    }};
  }
`;

export default CurrentlySelected;
