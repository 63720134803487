import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { useEffect, useMemo } from "react";
import useAppSettings from "../../hooks/useAppSettings";

export const createCardPaymentIntentGql = gql`
  mutation CreateCardPaymentIntent($rfqId: ID!) {
    createCardPaymentIntent(rfqId: $rfqId) {
      clientSecret
    }
  }
`;

interface MutationCreateCardPaymentIntentArgs {
  rfqId: string;
}

interface PaymentIntent {
  clientSecret: string;
}

const useCardPaymentIntent = (rfqId: string = "") => {
  const { darkMode } = useAppSettings();
  const [mutate, { data, error, loading }] = useMutation<{ createCardPaymentIntent: PaymentIntent }, MutationCreateCardPaymentIntentArgs>(createCardPaymentIntentGql);

  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        const res = await mutate({ variables: { rfqId } });
        if (!res.data?.createCardPaymentIntent) throw new Error("Error creating payment intent");
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    createPaymentIntent();
  }, []);

  const options: StripeElementsOptions = useMemo(() => {
    return {
      appearance: {
        theme: darkMode ? "night" : "flat",
        labels: "floating",
        variables: {
          colorText: darkMode ? "#ffffff" : "#000000",
        },
      },
      clientSecret: data?.createCardPaymentIntent.clientSecret,
    };
  }, [darkMode, data?.createCardPaymentIntent.clientSecret]);

  return {
    error,
    options,
    loading,
  };
};

export default useCardPaymentIntent;
