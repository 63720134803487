import { useFormik } from "formik";
import * as Yup from "yup";

const initialValues = {
  price: 0,
};

const validationSchema = Yup.object().shape({
  price: Yup.number().required("Required"),
});

type IOnSubmit = (price: number) => Promise<void>;

const useNegotiateForm = (onSubmit: IOnSubmit, onClose?: () => void) => {
  const handleSubmit = async (values: typeof initialValues) => {
    try {
      await onSubmit(values.price);
    } finally {
      onClose?.();
    }
  };

  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return form;
};

export default useNegotiateForm;
