import React from "react";
import styled, { keyframes } from "styled-components";

interface Props {
  fullPage?: boolean;
}

interface ContainerProps {
  $fullPage?: boolean;
}

const Loader: React.FC<Props> = ({ fullPage }) => {
  return (
    <Container $fullPage={fullPage} className="page-loader-outer">
      <div data-testid="loader" className="page-loader-middle">
        <div className="page-loader-inner">
          <div className="mul15">
            <div className="m15c m15c1"></div>
            <div className="m15c m15c2"></div>
            <div className="m15c m15c3"></div>
            <div className="m15c m15c4"></div>
            <div className="m15c m15c5"></div>
            <div className="m15c m15c6"></div>
            <div className="m15c m15c7"></div>
            <div className="m15c m15c8"></div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const animateLoader = () => keyframes`
  0%,
    40%,
    100% {
      background-color: #95c11e;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    20% {
      background-color: #008d36;
      -webkit-transform: translateY(-12px);
      transform: translateY(-12px);
    }
`;

const Container = styled.section<ContainerProps>`
  // Loading animation
  .mul15 {
    height: 14px;
    width: 144px;
  }

  .m15c {
    height: 14px;
    width: 14px;
    margin: 0 2px;
    border-radius: 50%;
    float: left;
    background-color: #95c11e;
  }

  .m15c8 {
    background-color: #c84668 !important;
  }

  .m15c1,
  .m15c2,
  .m15c3,
  .m15c4,
  .m15c5,
  .m15c6,
  .m15c7,
  .m15c8 {
    -webkit-animation: ${animateLoader} 2s infinite ease-in-out;
    animation: ${animateLoader} 2s infinite ease-in-out;
  }

  .m15c2 {
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;
  }

  .m15c3 {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }

  .m15c4 {
    -webkit-animation-delay: 0.75s;
    animation-delay: 0.75s;
  }

  .m15c5 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }

  .m15c6 {
    -webkit-animation-delay: 1.25s;
    animation-delay: 1.25s;
  }

  .m15c7 {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
  }

  .m15c8 {
    -webkit-animation-delay: 1.75s;
    animation-delay: 1.75s;
  }

  .page-loader-outer {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 75%;
    width: 100%;
  }

  .page-loader-middle {
    display: table-cell;
    vertical-align: middle;
  }

  .page-loader-inner {
    margin-left: auto;
    margin-right: auto;
    width: 144px;
  }
  // maker full screen
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ $fullPage }) => ($fullPage ? "70vh" : "40px")};
`;

export default Loader;
