import { Alert, Button, Divider, Grid, Group, Loader, Space, Text } from "@mantine/core";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useHover } from "usehooks-ts";
import Conditional from "../../components/Conditional";
import useAuth from "../../hooks/useAuth";
import { Order } from "../../typing/gql.schema";
import { formatDate } from "../../utils/utils";
import useClosedOrdersQuery from "../hooks/useClosedOrdersQuery";

interface Props {
  orders: Array<Order>;
}

interface OpenOrdersItemProps {
  order: Order;
  index: number;
  lastIndex: number;
}

const OpenOrdersItem: React.FC<OpenOrdersItemProps> = ({ order, lastIndex, index }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const viewButtonRef = useRef(null);
  const isViewButtonHover = useHover(viewButtonRef);

  return (
    <>
      <Grid columns={34} data-testid="order-item">
        <Grid.Col span={{ base: 34, md: 31, lg: 28 }}>
          <Text>
            {t("closedOrdersText3")}
            {order?.id} <em>{order?.quote?.rfq?.cart?.name}</em> {t("closedOrdersText4")}{" "}
            <Conditional renderIf={!order?.quote?.rfq?.phased}>{formatDate(order?.quote?.rfq?.deliveryDate)}</Conditional>
            <Conditional renderIf={order?.quote?.rfq?.phased}>{t("closedOrdersText5")}</Conditional>
          </Text>
        </Grid.Col>
        <Grid.Col span={{ base: 34, md: 3, lg: 6 }}>
          <Group justify="center">
            <Button ref={viewButtonRef} onClick={() => navigate(`/orders/${order?.id}`)} variant={isViewButtonHover ? "outline" : "default"}>
              {t("closedOrdersText6")}
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
      <Conditional renderIf={index !== lastIndex}>
        <Divider my="xs" />
      </Conditional>
    </>
  );
};

const ClosedOrders: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { activeUser } = useAuth();
  const query = useClosedOrdersQuery(activeUser?.id!);

  if (query.loading) {
    return (
      <Group justify="center">
        <Loader type="dots" />
      </Group>
    );
  }
  return (
    <>
      <Space h={"lg"} />
      {query.closedOrders.map((order, index) => (
        <OpenOrdersItem key={index} order={order} index={index} lastIndex={query.closedOrders.length - 1} />
      ))}
      <Conditional renderIf={!query.closedOrders.length}>
        <Alert title={t("closedOrdersText1")} color="teal">
          {t("closedOrdersText2")}
        </Alert>
        <Space h={"lg"} />
      </Conditional>
    </>
  );
};

export default ClosedOrders;
