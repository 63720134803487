import { Button, Divider, Group, Space, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "../../components/CustomModal";
import { Estimate, EstimateItem } from "../../typing/gql.schema";
import useQuoteItemByCartItemID from "../hooks/useQuoteItemByCartItemID";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  estimate?: Estimate;
  openRemoveModal: () => void;
  activeItem?: Partial<EstimateItem>;
};

const QuoteItemEditModal: React.FC<Props> = ({ isOpen, onClose, estimate, activeItem, openRemoveModal }) => {
  const { t } = useTranslation();
  const quoteItemByCartItemID = useQuoteItemByCartItemID(estimate, activeItem?.cartItem?.id!);

  return (
    <CustomModal size="xl" centered opened={isOpen} onClose={onClose} title={t("quotesText33")}>
      <Divider />
      <Space h={15} />
      <Text>
        {t("quotesText34")} <strong>{activeItem?.cartItem?.quantity}</strong>&nbsp;
        {quoteItemByCartItemID?.yardProduct?.size?.name}&nbsp;
        <em>{quoteItemByCartItemID?.yardProduct?.plant?.latinName}</em>?
      </Text>
      <Space h={15} />
      <Group justify="center">
        <Button color="red" onClick={() => openRemoveModal()} data-testid="remove-item-btn">
          {t("quotesText35")}
        </Button>
      </Group>
      <Space h={15} />
      <Divider />
      <Space h={15} />
      <Group>
        <Button variant="default" onClick={onClose}>
          {t("quotesText78")}
        </Button>
      </Group>
    </CustomModal>
  );
};

export default QuoteItemEditModal;
