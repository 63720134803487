import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RfqType } from "../../enums";
import { trackAction } from "../../services/firebaseAnalytics";
import { Estimate, Mutation, MutationSendRfqArgs } from "../../typing/gql.schema";

export const MUTATION_SENT_RFQ = gql`
  mutation sendRfq($id: ID!) {
    sendRfq(id: $id)
  }
`;

const useCheckAvailability = (estimate?: Estimate) => {
  const { t } = useTranslation();
  const [mutate, { loading, data, error }] = useMutation<{ sendRfq: Mutation["sendRfq"] }, MutationSendRfqArgs>(MUTATION_SENT_RFQ);

  const check = async () => {
    const id = estimate?.rfq.id!;
    const cartName = estimate?.rfq.cart.name!;
    try {
      const res = await mutate({ variables: { id } });
      if (!res.data?.sendRfq) throw new Error("Error checking availability");

      showNotification({
        color: "green",
        title: t("useCheckAvailabilityText1"),
        message: t("useCheckAvailabilityText2", { cartName: estimate?.rfq.cart.name!, numSent: res.data.sendRfq }),
      });

      let checkAvailabilityEventValue = 0;
      if (estimate?.rfq.type === RfqType.NURSERY_NET) {
        checkAvailabilityEventValue = estimate?.serviceFee ? estimate.serviceFee * 0.25 : 0;
      } else {
        checkAvailabilityEventValue = estimate?.subtotal ? estimate?.subtotal * 0.025 : 0;
      }

      trackAction("check_availability", { cart_item_id: id, value: checkAvailabilityEventValue });
    } catch (error: any) {
      showNotification({ color: "red", title: t("useCheckAvailabilityText3"), message: t("useCheckAvailabilityText4", { cartName }) });
      Sentry.captureException(error, { extra: { id }, tags: { where: "useCheckAvailability" } });
    }
  };

  return { check, loading, error, data };
};

export default useCheckAvailability;
