import React from "react";
import { Plant } from "../../typing/gql.public.schema";
import { formatMarkdown } from "../../utils/utils";
import useFormatDescription from "../hooks/useFormatDescription";
import classes from "../styles/PlantDescription.module.css";

type Props = {
  plant: Plant | null;
  lowPriceForPlantAndSize?: number;
  highPriceForPlantAndSize?: number;
};

const PlantDescription: React.FC<Props> = ({ plant }) => {
  const formattedHighlights = formatMarkdown(plant?.highlights);
  const formattedDescription = useFormatDescription(plant?.description);

  return (
    <>
      <p className={classes.description}>{formattedDescription}</p>
      <div className={classes.highlights} dangerouslySetInnerHTML={{ __html: formattedHighlights }} />
    </>
  );
};

export default PlantDescription;
