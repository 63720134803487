import { useFormik } from "formik";
import { useMemo } from "react";
import * as Yup from "yup";
import { FirmMetadata } from "../../typing/gql.public.schema";

const phoneRegExp = /^1?[- ]*(\d{3}|\(\d{3}\))[- ]*(\d{3})[- ]*(\d{4})$/;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email format").required("Required"),
  address: Yup.string().required("Required"),
  website: Yup.string().required("Required"),
  phone: Yup.string().matches(phoneRegExp, "Invalid phone number").required("Required"),
  projectMin: Yup.number().required("Required").min(1, "Minimum project cost must be greater than 0"),
  projectMax: Yup.number().required("Required").min(1, "Maximum project cost must be greater than 0"),
  maintenance: Yup.boolean().required(),
  commercial: Yup.boolean().required(),
  residential: Yup.boolean().required(),
});

const useContractorForm = (firmMetadata?: FirmMetadata) => {
  const initialValues = useMemo(
    () => ({
      name: firmMetadata?.name || "",
      email: firmMetadata?.email || "",
      phone: firmMetadata?.phone || "",
      address: firmMetadata?.address || "",
      website: firmMetadata?.website || "",
      projectMin: firmMetadata?.projectMin || 0,
      projectMax: firmMetadata?.projectMax || 0,
      maintenance: firmMetadata?.maintenance || false,
      commercial: firmMetadata?.commercial || false,
      residential: firmMetadata?.residential || false,
    }),
    [firmMetadata]
  );

  const form = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const selectedServiceProvided = form.values.maintenance || form.values.commercial || form.values.residential;

  const isFormValid = selectedServiceProvided && form.isValid;

  return { ...form, isFormValid, selectedServiceProvided };
};

export default useContractorForm;
