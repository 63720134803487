import { gql, useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { Photo, Query, QueryPhotosForPlantAndSizeArgs } from "../../typing/gql.schema";

export const photosForPlantAndSizeGQL = gql`
  query photosForPlantAndSize($plantId: ID!, $sizeId: ID!) {
    photosForPlantAndSize(plantId: $plantId, sizeId: $sizeId) {
      id
      filename
      approved
    }
  }
`;

const usePhotosForPlantAndSize = (plantId?: string, sizeId?: string) => {
  const [showed, setShowed] = useState(false);
  const [query, { loading, data, error }] = useLazyQuery<{ photosForPlantAndSize: Query["photosForPlantAndSize"] }, QueryPhotosForPlantAndSizeArgs>(
    photosForPlantAndSizeGQL,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );

  const load = async () => {
    if (!plantId || !sizeId) return;
    try {
      setShowed(true);
      await query({ variables: { plantId, sizeId } });
    } catch (error) {}
  };

  const photos = (data?.photosForPlantAndSize || []).reduce((acc, current) => {
    if (current) return [...acc, current];
    return acc;
  }, [] as Array<Photo>);

  return { query, load, showed, loading, data, error, photos };
};

export default usePhotosForPlantAndSize;
