import { Modal, ModalProps } from "@mantine/core";
import React from "react";

const CustomModal: React.FC<ModalProps> = (props) => {
  return (
    <Modal transitionProps={{ exitDuration: 200 }} {...props}>
      {props.children}
    </Modal>
  );
};

export default CustomModal;
