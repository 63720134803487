import { Button, Center, Divider, Group, Space, Text, Title } from "@mantine/core";
import { IconShare } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import useAuth from "../../hooks/useAuth";
import { Order } from "../../typing/gql.schema";
import { formatDate } from "../../utils/utils";

type Props = {
  order: Order;
  openShareModal: () => void;
};

const OrderHeader: React.FC<Props> = ({ order, openShareModal }) => {
  const { t } = useTranslation();
  const { loggedInUser } = useAuth();

  return (
    <>
      <Space h={15} />
      <Center>
        <Title>
          {order?.quote?.rfq?.cart?.name} (#{order?.id})
        </Title>
      </Center>
      <Space h={8} />
      <Divider />
      <Space h={15} />
      <Group justify="space-between">
        <div>
          <Text mb="md" fz={20}>
            {t("orderText1")}&nbsp;
            <Conditional renderIf={order?.quote?.rfq?.phased !== true}>
              <Text span fw={700}>
                {formatDate(order?.quote?.rfq?.deliveryDate)}
              </Text>
            </Conditional>
            <Conditional renderIf={order?.quote?.rfq?.phased === true}>
              <Text span fw={700}>
                {t("orderText2")}
              </Text>
            </Conditional>
          </Text>

          <Text fs="italic">
            {t("orderText3")}&nbsp;
            <Text c="green" component="a" href="mailto:accountsreceivable@bidscape.com">
              accountsreceivable@bidscape.com
            </Text>
          </Text>
          <Text fs="italic" display="none">
            {t("orderText20")}&nbsp;
            <Text c="green" component="a" href="mailto:accountsreceivable@bidscape.com">
              accountsreceivable@bidscape.com
            </Text>
          </Text>
        </div>
        <Conditional renderIf={loggedInUser?.id === order?.quote?.rfq?.cart?.user?.id || loggedInUser?.isAdmin}>
          <Group justify="center">
            <Button size="lg" variant="default" onClick={openShareModal} rightSection={<IconShare size={30} />} data-testid="open-share-button1">
              {t("orderText16")}
            </Button>
          </Group>
        </Conditional>
      </Group>
    </>
  );
};

export default OrderHeader;
