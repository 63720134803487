import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const useStaticEstimatePage = (deliveryDate?: string) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  // all buttons except for the ones in excludeButtons will be hidden
  const excludeButtons = [t("quotesTermsText1"), t("estimateText21")];

  // all texts in showTexts will be shown, these texts are hidden by default
  const showTexts: Array<string> = [t("estimateText66")];

  const handleDisableActionButtons = useCallback(() => {
    if (!ref.current) return;

    ref.current.querySelectorAll("button").forEach((button) => {
      if (button.textContent && excludeButtons.includes(button.textContent)) return;
      button.style.display = "none";
    });

    ref.current.querySelectorAll("p").forEach((text) => {
      for (let showText of showTexts) {
        if (text.textContent?.includes(showText)) {
          text.style.display = "block";
        }
      }
    });

    // disable all links
    ref.current.querySelectorAll("a").forEach((link) => {
      link.style.pointerEvents = "none";
    });
  }, [ref, excludeButtons]);

  useEffect(() => {
    if (deliveryDate && new Date(deliveryDate) < new Date()) {
      handleDisableActionButtons();
    }
  }, [deliveryDate, handleDisableActionButtons]);

  return ref;
};

export default useStaticEstimatePage;
