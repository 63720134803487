import { Center, Divider, Space, Title } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { QuoteItem } from "../../typing/gql.schema";

type Props = {
  quoteItem: QuoteItem;
};

const PhotoHeader: React.FC<Props> = ({ quoteItem }) => {
  const { t } = useTranslation();
  return (
    <>
      <Center>
        <Title>
          {t("photosText0")} ({quoteItem?.cartItem?.quantity}) {quoteItem?.yardProduct?.size?.name} {quoteItem?.yardProduct?.plant?.latinName}
        </Title>
      </Center>
      <Space h={8} />
      <Divider />
      <Space h={15} />
    </>
  );
};

export default PhotoHeader;
