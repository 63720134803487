import { Button, Container, Group, Loader, Space, Text } from "@mantine/core";
import * as Sentry from "@sentry/react";
import { IconArrowLeft, IconShare } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import LightBoxModal from "../components/LightBoxModal";
import { FadeTransition } from "../components/Transitions";
import useMediaModal from "../hooks/useMediaModal";
import useModal from "../hooks/useModal";
import OrderHeader from "./components/OrderHeader";
import OrderQueryError from "./components/OrderQueryError";
import OrderTotals from "./components/OrderTotals";
import OrderTruck from "./components/OrderTruck";
import ShareModal from "./components/ShareModal";
import Terms from "./components/Terms";
import useExtractYards from "./hooks/useExtractYards";
import useOrderQuery from "./hooks/useOrderQuery";
import useStaticOrderPage from "./hooks/useStaticOrderPage";
import useViewOrder from "./hooks/useViewOrder";

interface Props {}

const Order: React.FC<Props> = () => {
  const shareModal = useModal();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { orderId } = useParams();
  const mediaModal = useMediaModal();
  const query = useOrderQuery(orderId!);
  const { yards, yardLookup } = useExtractYards(query.order);
  const pageRef = useStaticOrderPage(query.order?.quote.rfq.deliveryDate);

  useViewOrder(query.order);

  if (query.loading) {
    return (
      <Group justify="center" mt="xl">
        <Loader data-testid="loader" variant="bars" />
      </Group>
    );
  }

  if (query.error) return <OrderQueryError error={query.error} refetch={query.refetch} />;

  return (
    <FadeTransition>
      <Container ref={pageRef}>
        <OrderHeader order={query.order!} openShareModal={shareModal.open} />
        <Space h={30} />
        <Text tt="uppercase" fw={700}>
          {t("orderText18")}
        </Text>
        <Space h={15} />
        {yards.map((yard, index) => (
          <OrderTruck key={index} yard={yard} yardLookup={yardLookup} openMediaModal={mediaModal.open} />
        ))}
        <OrderTotals order={query.order!} />
        <Space h={25} />
        <Group justify="center">
          <Button size="lg" onClick={() => navigate("/dashboard")} leftSection={<IconArrowLeft />} variant="default">
            {t("orderText15")}
          </Button>
          <Button size="lg" variant="default" onClick={shareModal.open} rightSection={<IconShare size={30} />} data-testid="open-share-button2">
            {t("orderText16")}
          </Button>
        </Group>
        <Space h={40} />
        <Terms />
        <Space h={40} />
        <ShareModal opened={shareModal.isActive} order={query?.order} close={shareModal.close} />
        <LightBoxModal filename={mediaModal.filename} opened={mediaModal.isActive} onClose={mediaModal.close} />
      </Container>
    </FadeTransition>
  );
};

export default Sentry.withProfiler(Order, { name: "Order" });
