import { Button, Divider, Grid, Group, Text } from "@mantine/core";
import { IconPencil, IconTrash } from "@tabler/icons-react";
import React from "react";
import styled from "styled-components";
import Conditional from "../../components/Conditional";
import { FadeTransition } from "../../components/Transitions";
import { DeepPartial } from "../../typing/declarations";
import { CartItem } from "../../typing/gql.schema";
import usePlantEntryItem from "../hooks/usePlantEntryItem";
import useSizesForPlantQuery from "../hooks/useSizesForPlantQuery";
import EditPlantEntryItem from "./EditPlantEntryItem";

interface Props {
  item: DeepPartial<CartItem>;
  removeCartItem: (id: string) => void;
}

const PlantEntryItem: React.FC<Props> = ({ item, removeCartItem }) => {
  const entry = usePlantEntryItem(item);
  const { sizes } = useSizesForPlantQuery(item.plant?.id);

  return (
    <FadeTransition>
      <Container>
        <Conditional renderIf={!entry.isEditing}>
          <Grid columns={24}>
            <Grid.Col span={{ base: 24, sm: 24, md: 20 }}>
              <Text>
                <strong>{item.quantity}</strong>&nbsp;{item?.size?.name}&nbsp;
                <i>{item?.plant?.latinName}</i>&nbsp;<Conditional renderIf={item?.notes}>({item?.notes})</Conditional> ({item?.plant?.commonName})
              </Text>
            </Grid.Col>
            <Grid.Col span={{ base: 24, sm: 24, md: 4 }}>
              <Group justify="center">
                <Button.Group>
                  <Button variant="outline" size="xs" data-testid="edit-cart-item" onClick={entry.editCartItem}>
                    <IconPencil />
                  </Button>
                  <Button variant="outline" size="xs" color="red" onClick={() => removeCartItem(item.id!)}>
                    <IconTrash />
                  </Button>
                </Button.Group>
              </Group>
            </Grid.Col>
          </Grid>
        </Conditional>
        <Conditional renderIf={entry.isEditing}>
          <EditPlantEntryItem
            item={item}
            sizes={sizes}
            onSizeSelect={entry.setSize}
            onQuantityChange={entry.setQuantity}
            finishEditCartItem={entry.finishEditCartItem}
            cancelEditCartItem={entry.cancelEditCartItem}
          />
        </Conditional>
        <Divider my="sm" />
      </Container>
    </FadeTransition>
  );
};

const Container = styled.div`
  .text {
    margin: auto;
    margin-left: 0px;
  }
  .btn {
    margin: auto;
  }
`;

export default PlantEntryItem;
