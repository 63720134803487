import { useEffect, useState } from "react";

export const usePrerenderReady = () => {
  const [plantDetailsQueryDone, setPlantDetailsQueryDone] = useState(false);
  const [commonPairingsForPlantsQueryDone, setCommonPairingsForPlantsQueryDone] = useState(false);

  const onPlantDetailsQueryDone = () => setPlantDetailsQueryDone(true);
  const onCommonPairingsForPlantsQueryDone = () => setCommonPairingsForPlantsQueryDone(true);

  useEffect(() => {
    if (plantDetailsQueryDone && commonPairingsForPlantsQueryDone) {
      window.prerenderReady = true;
    }
  }, [plantDetailsQueryDone, commonPairingsForPlantsQueryDone]);

  return { onPlantDetailsQueryDone, onCommonPairingsForPlantsQueryDone };
};
