import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { StripePaymentElementChangeEvent } from "@stripe/stripe-js";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const useStripeCheckout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [landing, setLanding] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleChange = (event: StripePaymentElementChangeEvent) => {
    setDisabled(!event.complete);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLanding(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return showNotification({
        color: "red",
        title: t("useStripeCheckoutText1"),
        message: t("useStripeCheckoutText2"),
      });
    }

    try {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment/success?rfqId=${searchParams.get("rfqId")}`,
        },
      });

      if (result.error) {
        showNotification({
          color: "red",
          title: t("useStripeCheckoutText1"),
          message: result.error.message || t("useStripeCheckoutText2"),
        });
        Sentry.captureException(result.error);
      } else {
        showNotification({
          color: "green",
          title: t("useStripeCheckoutText3"),
          message: t("useStripeCheckoutText4"),
        });
      }
    } catch (error) {
      showNotification({
        color: "red",
        title: t("useStripeCheckoutText1"),
        message: t("useStripeCheckoutText2"),
      });
      Sentry.captureException(error);
    } finally {
      setLanding(false);
    }
  };

  return {
    stripe,
    elements,
    handleSubmit,
    handleChange,
    disabled,
    landing,
  };
};

export default useStripeCheckout;
