import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Mutation, MutationSetCartItemPhotoArgs } from "../../typing/gql.schema";

export const setCartItemPhotoGQL = gql`
  mutation setCartItemPhoto($id: ID!, $photoId: ID!) {
    setCartItemPhoto(id: $id, photoId: $photoId)
  }
`;

const useSetCartItemPhoto = (cartItemId: string) => {
  const { t } = useTranslation();
  const [photoId, setPhotoId] = useState("");

  const [mutation, { loading, data, error }] =
    useMutation<{ setCartItemPhoto: Mutation["setCartItemPhoto"] }, MutationSetCartItemPhotoArgs>(setCartItemPhotoGQL);

  const setCartItemPhoto = async (photoId: string) => {
    try {
      setPhotoId(photoId);
      await mutation({ variables: { id: cartItemId, photoId } });
      showNotification({ color: "teal", message: t("useSetCartItemPhotoText1") });
    } catch (error: any) {
      showNotification({ color: "red", message: t("useSetCartItemPhotoText2") });
      Sentry.captureException(error, { extra: { photoId }, tags: { hook: "useSetCartItemPhoto" } });
    }
  };
  return { setCartItemPhoto, photoId, loading, data, error };
};

export default useSetCartItemPhoto;
