import { useTranslation } from "react-i18next";
// import Modal from "../../modals/modal";
import { Button, Divider, Group, Space, Text } from "@mantine/core";
import CustomModal from "../../components/CustomModal";
import { Estimate, EstimateItem } from "../../typing/gql.schema";
import useAddPhotoRequest from "../hooks/useAddPhotoRequest";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  estimate?: Estimate;
  activeItem?: Partial<EstimateItem>;
  refreshQuote: () => void;
};

const QuoteItemRequestPhotoModal: React.FC<Props> = ({ isOpen, activeItem, estimate, onClose, refreshQuote }) => {
  const { t } = useTranslation();
  const { addPhotoRequest, loading } = useAddPhotoRequest();

  return (
    <CustomModal centered size="xl" opened={isOpen} onClose={onClose} title={t("quotesText51")} transitionProps={{ exitDuration: 200 }}>
      <Divider />
      <Space h={15} />
      <Text>{t("quotesText52")}</Text>
      <Space h={15} />
      <Text>{t("quotesText53")}</Text>
      <Space h={15} />
      <Text>{t("quotesText54")}</Text>
      <Space h={15} />
      <Divider />
      <Space h={15} />
      <Group>
        <Button
          loading={loading}
          data-testid="confirm-request-photos-btn"
          onClick={async () => {
            await addPhotoRequest({ rfqID: estimate?.rfq.id!, cartItemID: activeItem?.cartItem?.id! });
            onClose();
            refreshQuote();
          }}
        >
          {t("quotesText55")}
        </Button>
        <Button variant="default" onClick={onClose}>
          {t("quotesText78")}
        </Button>
      </Group>
    </CustomModal>
  );
};

export default QuoteItemRequestPhotoModal;
