import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { CustomMantineProvider, StyledThemeProvider } from "../providers";
import { persistor, store } from "../redux/store";
import { AppRouterProvider } from "../router";
import apolloClient from "../services/apolloClient";

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ApolloProvider client={apolloClient}>
          <CustomMantineProvider>
            <StyledThemeProvider>
              <AppRouterProvider />
            </StyledThemeProvider>
          </CustomMantineProvider>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  );
}

const WithProfiler = Sentry.withProfiler(App, { name: "buyerApp" });
export default Sentry.withErrorBoundary(WithProfiler, { fallback: () => <div>Something went wrong</div> });
