import { useEffect, useRef, useState } from "react";
import useGoogleMapsApi from "../../hooks/useGoogleMapsApi";

type IOnPlaceChange = (e: google.maps.places.PlaceResult) => void;

const usePlacesInput = (onPlaceChange?: IOnPlaceChange) => {
  const googleMapsApi = useGoogleMapsApi();
  const inputRef = useRef<HTMLInputElement>(null);
  const [place, setPlace] = useState<google.maps.places.PlaceResult>();
  const autocompleteRef = useRef<google.maps.places.Autocomplete>();

  useEffect(() => {
    if (!googleMapsApi || !inputRef.current) return;
    autocompleteRef.current = new googleMapsApi.places.Autocomplete(inputRef.current, { types: ["address"] });
    autocompleteRef.current.addListener("place_changed", () => {
      const p = autocompleteRef.current?.getPlace();
      if (p) {
        setPlace(p);
        onPlaceChange?.(p);
      }
    });
  }, [googleMapsApi]);

  return { autocompleteRef, inputRef, place };
};

export default usePlacesInput;
