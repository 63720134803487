import { useMemo } from "react";
import useAuth from "../hooks/useAuth";
import { getRoutes } from "./routes";

export const useRoutes = () => {
  const { isLoggedIn } = useAuth();

  return useMemo(() => {
    return getRoutes(isLoggedIn);
  }, [isLoggedIn]);
};
