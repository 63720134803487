import { Button, Divider, Space, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "../../components/CustomModal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const EstimateContractAndHoldModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  return (
    <CustomModal size="xl" centered opened={isOpen} onClose={onClose} title={t("estimateText38")}>
      <Divider />
      <Space h={15} />
      <Text data-testid="contract-hold-body">
        {t("estimateText36")}
        <a href="mailto:orders@bidscape.com"> orders@bidscape.com </a>
        {t("estimateText37")}
      </Text>
      <Space h={15} />
      <Divider />
      <Space h={15} />
      <Button variant="default" onClick={onClose}>
        {t("estimateText60")}
      </Button>
    </CustomModal>
  );
};

export default EstimateContractAndHoldModal;
