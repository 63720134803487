import { Estimate, EstimateItem, QuoteItem } from "../../typing/gql.schema";

interface Substitution {
  oldLatinName: string;
  oldCommonName: string;
  oldSizeName: string;
  newLatinName: string;
  newCommonName: string;
  newSizeName: string;
}

const useSubstitution = (estimate?: Estimate, cartItemId?: string) => {
  const itemByCartItemID = estimate?.estimateItems.reduce((acc, current) => {
    if (!current) return acc;
    return acc.set(current.cartItem.id, current);
  }, new Map<string, EstimateItem>());

  const bestQuoteItems = estimate?.rfq.bestQuote?.quoteItems.reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, current];
  }, [] as QuoteItem[]);

  const substitutions = bestQuoteItems?.reduce((acc, current) => {
    const ei = itemByCartItemID?.get(current.cartItem.id);
    if (!ei) return acc;
    if (ei.cartItem.plant.id !== current.yardProduct.plant.id || ei.cartItem.size.id !== current.yardProduct.size.id) {
      const substitution = {
        oldSizeName: ei.cartItem.size.name,
        oldLatinName: ei.cartItem.plant.latinName,
        newSizeName: current.yardProduct.size.name,
        oldCommonName: ei.cartItem.plant.commonName,
        newLatinName: current.yardProduct.plant.latinName,
        newCommonName: current.yardProduct.plant.commonName,
      };
      acc.set(ei.cartItem.id, substitution);
    }
    return acc;
  }, new Map<string, Substitution>());

  return substitutions?.get(cartItemId || "");
};

export default useSubstitution;
