import { ItemDetail } from "../../typing/declarations";
import { EstimateItem, Order, QuoteItem, Yard } from "../../typing/gql.schema";

const useExtractYards = (order?: Order) => {
  if (!order) {
    return {
      yards: new Array<Yard>(),
      yardLookup: new Map<string, ItemDetail[]>(),
    };
  }

  const quoteItems = order.quote.quoteItems.reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, current];
  }, [] as Array<QuoteItem>);

  const estimateItems = order.quote.estimate.estimateItems.reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, current];
  }, [] as Array<EstimateItem>);

  const yards = order.quote.suppliers.reduce((acc, current) => {
    if (!current) return acc;
    if (acc.some((item) => item.id === current.id)) return acc;
    return [...acc, current];
  }, [] as Array<Yard>);

  const yardLookup = yards.reduce((acc, yard) => {
    const itemDetails = new Array<ItemDetail>();

    quoteItems.map((tmpQuoteItem) => {
      if (!(tmpQuoteItem.yardProduct.yard.id === yard.id)) return;

      estimateItems.map((tmpEstimateItem) => {
        if (!(tmpQuoteItem.cartItem.id === tmpEstimateItem.cartItem.id)) return;

        itemDetails.push({
          quoteItem: tmpQuoteItem,
          estimateItem: tmpEstimateItem,
        });
      });
    });

    return acc.set(yard.id, itemDetails);
  }, new Map<string, ItemDetail[]>());

  return { yards, yardLookup };
};

export default useExtractYards;
