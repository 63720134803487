import { gql, useQuery } from "@apollo/client";
import { Query, QueryEstimateForRfqArgs } from "../../typing/gql.schema";

export const buyerEstimateQuery = gql`
  query estimateForRfq($id: ID!) {
    estimateForRfq(id: $id) {
      __typename
      id
      guaranteed
      editable
      total
      subtotal
      deliveryFee
      serviceFee
      rfq {
        id
        hmac
        type
        status
        deliveryDate
        sharedWithEmails
        cart {
          id
          name
          user {
            id
          }
          cartItems {
            id
            notes
            quantity
            plant {
              id
              latinName
              commonName
            }
            size {
              id
              name
            }
          }
        }
      }
      estimateItems {
        id
        unitTotal
        total
        individualUnitPrice
        cartItem {
          id
          quantity
          notes
          ignore
          plant {
            id
            latinName
            commonName
            category {
              id
              name
            }
          }
          size {
            id
            name
          }
          defaultPhoto {
            id
            filename
          }
        }
      }
    }
  }
`;

const useEstimateQuery = (id: string = "") => {
  const { loading, data, refetch, error } = useQuery<{ estimateForRfq: Query["estimateForRfq"] }, QueryEstimateForRfqArgs>(buyerEstimateQuery, {
    variables: {
      id,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const estimate = data?.estimateForRfq || undefined;

  return { loading, data, refetch, error, estimate };
};

export default useEstimateQuery;
