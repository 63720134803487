import { Button, Divider, Group, Space, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "../../components/CustomModal";
import { EstimateItem } from "../../typing/gql.schema";
import useAddSubRequest from "../hooks/useAddSubRequest";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  activeItem?: Partial<EstimateItem>;
};

const QuoteMissingItemSubModal: React.FC<Props> = ({ isOpen, activeItem, onClose }) => {
  const { t } = useTranslation();
  const { loading, addSubRequest } = useAddSubRequest();

  return (
    <CustomModal size="xl" centered opened={isOpen} onClose={onClose} title={t("quotesText61")}>
      <Divider />
      <Space h={15} />
      <Text>{t("quotesText62")}</Text>
      <Space h={15} />
      <Divider />
      <Space h={15} />
      <Group>
        <Button
          loading={loading}
          data-testid="confirm-sub-request-btn"
          onClick={async () => {
            await addSubRequest({ id: activeItem?.cartItem?.id! });
            onClose();
          }}
        >
          {t("quotesText63")}
        </Button>
        <Button variant="default" onClick={onClose}>
          {t("quotesText78")}
        </Button>
      </Group>
    </CustomModal>
  );
};

export default QuoteMissingItemSubModal;
