import { Estimate, Photo, QuoteItem } from "../../typing/gql.schema";

const useApprovedPhotos = (estimate?: Estimate, cartItemId?: string) => {
  const bestQuoteItems = estimate?.rfq.bestQuote?.quoteItems.reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, current];
  }, [] as QuoteItem[]);

  const approvedPhotosLookup = bestQuoteItems?.reduce((acc, current) => {
    const approvedPhotos = current.yardProduct.approvedPhotos.reduce((photosAcc, currentPhoto) => {
      if (!currentPhoto) return photosAcc;
      return [...photosAcc, currentPhoto];
    }, [] as Array<Photo>);

    if (!approvedPhotos.length) return acc;

    return acc.set(current.cartItem.id, approvedPhotos);
  }, new Map<string, Array<Photo>>());

  return approvedPhotosLookup?.get(cartItemId || "");
};

export default useApprovedPhotos;
