import { Container, Divider, Space } from "@mantine/core";
import * as Sentry from "@sentry/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CommonPairingsForPlants from "../PlantsListingBase/CommonPairingsForPlants";
import Conditional from "../components/Conditional";
import LightBoxModal from "../components/LightBoxModal";
import Loader from "../components/Loader";
import { RfqStatus } from "../enums";
import useMediaModal from "../hooks/useMediaModal";
import useUpdateUrlSearchParams from "../hooks/useUpdateUrlSearchParams";
import EstimateActions from "./components/EstimateActions";
import EstimateContractAndHoldModal from "./components/EstimateContractAndHoldModal";
import EstimateFooter from "./components/EstimateFooter";
import EstimateHeader from "./components/EstimateHeader";
import EstimateItem from "./components/EstimateItem";
import EstimateItemRemoveModal from "./components/EstimateItemRemoveModal";
import EstimateItemShareModal from "./components/EstimateItemShareModal";
import EstimateMissingItem from "./components/EstimateMissingItem";
import EstimateQueryError from "./components/EstimateQueryError";
import EstimateReadyToBuyDisabledModal from "./components/EstimateReadyToBuyDisabledModal";
import EstimateTotals from "./components/EstimateTotals";
import Terms from "./components/Terms";
import useEstimateItemsAvailability from "./hooks/useEstimateItemsAvailability";
import useEstimatePlantIds from "./hooks/useEstimatePlantIds";
import useEstimateQuery from "./hooks/useEstimateQuery";
import useExtractEstimateItems from "./hooks/useExtractEstimateItems";
import useMissingItems from "./hooks/useMissingItems";
import useModals from "./hooks/useModals";
import useStaticEstimatePage from "./hooks/useStaticEstimatePage";
import useViewEstimate from "./hooks/useViewEstimate";

const Estimate: React.FC = () => {
  const { id } = useParams();
  const modals = useModals();
  const { t } = useTranslation();
  const mediaModal = useMediaModal();
  const query = useEstimateQuery(id);
  const missingItems = useMissingItems(query.estimate);
  const estimateItems = useExtractEstimateItems(query.estimate);
  const estimateItemAvailability = useEstimateItemsAvailability(id);
  const plantIds = useEstimatePlantIds(query.estimate);
  const pageRef = useStaticEstimatePage(query.estimate?.rfq?.deliveryDate);

  useViewEstimate(query.estimate);
  useUpdateUrlSearchParams({ rfqId: id!, source: "estimate", cartName: query.estimate?.rfq.cart.name || "" });

  if (query.loading) return <Loader fullPage={true} />;
  if (query.error) return <EstimateQueryError error={query.error} refetch={query.refetch} />;

  return (
    <Container ref={pageRef}>
      <EstimateHeader estimate={query.estimate} openShareModal={modals.openShare} />
      {estimateItems.map((item, index) => (
        <EstimateItem
          item={item}
          key={index}
          estimate={query.estimate}
          openRemoveItemModal={modals.openRemove}
          loadingEstimateItemAvailability={estimateItemAvailability.loading}
          estimateItemAvailability={estimateItemAvailability.data?.estimateForRfq!}
        />
      ))}
      {missingItems?.map((item) => (
        <EstimateMissingItem item={item} key={item.id} estimate={query.estimate} refreshEstimate={query.refetch} />
      ))}
      <Space h="sx" />
      <EstimateActions
        estimate={query.estimate}
        openContractAndHoldModal={modals.openContractAndHold}
        openReadyToByDisabledModal={modals.openReadyToByDisabled}
      />
      <Space h="md" />
      <EstimateTotals estimate={query.estimate} />
      <Space h="md" />
      <Divider />
      <Conditional renderIf={query.estimate?.rfq.status === RfqStatus.EstimatedRfq}>
        <CommonPairingsForPlants limit={8} plantIds={plantIds} title={t("estimateText61")} />
      </Conditional>
      <EstimateFooter estimate={query.estimate} openShareModal={modals.openShare} />
      <Terms />
      <Space h="xl" />
      <EstimateItemShareModal isOpen={modals.share} estimate={query.estimate} onClose={modals.closeShare} />
      <EstimateItemRemoveModal isOpen={modals.remove} onClose={modals.closeRemove} activeItem={modals.modalItem!} refreshEstimate={query.refetch} />
      <EstimateReadyToBuyDisabledModal estimate={query.estimate} isOpen={modals.readyToByDisabled} onClose={modals.closeReadyToByDisabled} />
      <EstimateContractAndHoldModal isOpen={modals.contractAndHold} onClose={modals.closetContractAndHold} />
      <LightBoxModal filename={mediaModal.filename} opened={mediaModal.isActive} onClose={mediaModal.close} />
    </Container>
  );
};

export default Sentry.withProfiler(Estimate, { name: "Estimate" });
