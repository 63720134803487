import { Button, Center, Container, Text } from "@mantine/core";
import { useNavigate } from "react-router";

const SuccessfullySubmitted: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Text mt="xl" ta="center" mb="md">
        Your company information has been updated successfully
      </Text>
      <Center>
        <Button size="md" component="a" href="/" onClick={() => navigate("/")}>
          Browse plants
        </Button>
      </Center>
    </Container>
  );
};

export default SuccessfullySubmitted;
