import { Container, Grid, Space } from "@mantine/core";
import * as Sentry from "@sentry/react";
import Conditional from "../components/Conditional";
import Loader from "../components/Loader";
import { FadeTransition } from "../components/Transitions";
import useAuth from "../hooks/useAuth";
import { isDevelopment } from "../utils/utils";
import ActionQueue from "./components/ActionQueue";
import DashboardHeader from "./components/DashboardHeader";
import LoyaltyTracker from "./components/LoyaltyTracker";
import OrdersPanel from "./components/OrdersPanel";
import RequestsPanel from "./components/RequestsPanel";
import useBuyerDashboard from "./hooks/useBuyerDashboard";

interface Props {
  loyaltyCounter?: number;
  development?: boolean;
}

const Dashboard: React.FC<Props> = (props) => {
  const { activeUser } = useAuth();
  const development = isDevelopment(props.development);
  const { orders, rfqs, loading } = useBuyerDashboard(activeUser?.id!);

  if (loading) return <Loader fullPage={true} />;

  return (
    <FadeTransition>
      <Container size={"lg"}>
        <Space h={30} />
        <DashboardHeader name={activeUser?.name!} />
        <Space h={15} />
        <Conditional renderIf={development}>
          <Grid grow>
            <Grid.Col span={{ base: 1, sm: 6 }}>
              <ActionQueue />
            </Grid.Col>
            <Grid.Col span={{ base: 1, sm: 6 }}>
              <LoyaltyTracker />
            </Grid.Col>
          </Grid>
          <Space h={15} />
        </Conditional>
        <Grid grow>
          <Grid.Col span={{ sm: 6 }}>
            <OrdersPanel orders={orders} />
          </Grid.Col>
          <Grid.Col span={{ sm: 6 }}>
            <RequestsPanel rfqs={rfqs} />
          </Grid.Col>
        </Grid>
        <Space h="xl" />
      </Container>
    </FadeTransition>
  );
};

Dashboard.defaultProps = { loyaltyCounter: 0, development: false };

export default Sentry.withProfiler(Dashboard, { name: "Dashboard" });
