import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { trackAction } from "../../services/firebaseAnalytics";
import { CartItem, Mutation, MutationAddCartItemArgs } from "../../typing/gql.schema";

export const MUTATION_ADD_CART_ITEM = gql`
  mutation addCartItem($id: ID!) {
    addCartItem(id: $id)
  }
`;

const useAddCartItem = () => {
  const { t } = useTranslation();
  const [mutate, { loading, data, error }] = useMutation<{ addCartItem: Mutation["addCartItem"] }, MutationAddCartItemArgs>(MUTATION_ADD_CART_ITEM);

  const addCartItem = async (item: CartItem) => {
    try {
      const result = await mutate({ variables: { id: item.id } });
      if (!result.data?.addCartItem) throw new Error("Error adding item");
      showNotification({ color: "teal", title: t("useAddItemText1"), message: t("useAddItemText2") });
      trackAction("add_to_cart", {
        items: [
          {
            item_id: item.plant.id,
            item_name: item.plant.latinName,
            item_category: item.plant.category.name,
            item_category2: item.size.name,
          },
        ],
      });
    } catch (error: any) {
      showNotification({ color: "red", title: t("useAddItemText3"), message: t("useAddItemText4") });
      Sentry.captureException(error, { extra: { data, where: "useAddCartItem" } });
    }
  };

  return { addCartItem, loading, error, data };
};

export default useAddCartItem;
