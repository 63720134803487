import { Button, Center, Group, Loader, RingProgress, Text, ThemeIcon, Title } from "@mantine/core";
import * as Sentry from "@sentry/react";
import { PaymentIntent, loadStripe } from "@stripe/stripe-js";
import { IconCheck, IconX } from "@tabler/icons-react";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Conditional from "../components/Conditional";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const PaymentSuccess: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = React.useState(true);
  const [status, setStatus] = React.useState<PaymentIntent.Status | undefined>();

  useEffect(() => {
    const checkPaymentStatus = async () => {
      try {
        setLoading(true);
        const stripe = await stripePromise;
        const clientSecret = searchParams.get("payment_intent_client_secret");

        if (!stripe || !clientSecret) return;
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

        setStatus(paymentIntent?.status);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setLoading(false);
      }
    };

    checkPaymentStatus();
  }, [searchParams]);

  if (loading) {
    return (
      <Center mt="xl">
        <Loader />
      </Center>
    );
  }

  return (
    <Container>
      <Conditional renderIf={status === "succeeded" || status === "requires_capture" || status === "processing"}>
        <Center>
          <RingProgress
            sections={[{ value: 100, color: "green" }]}
            label={
              <Center>
                <ThemeIcon c="green" variant="light" radius="xl" size="xl">
                  <IconCheck size={22} />
                </ThemeIcon>
              </Center>
            }
          />
        </Center>
        <Title ta="center">Payment Successful</Title>
        <Text ta="center">Your payment was submitted! It will be processed soon and you will then receive an email notification for order delivery or pickup schedule tracking.</Text>
        <Group justify="center" mt="md">
          <Button
            onClick={() => {
              navigate("/");
            }}
          >
            Continue Shopping
          </Button>
        </Group>
      </Conditional>

      <Conditional renderIf={status !== "succeeded" && status !== "requires_capture" && status !== "processing"}>
        <Center>
          <RingProgress
            sections={[{ value: 100, color: "red" }]}
            label={
              <Center>
                <ThemeIcon c="red" variant="light" radius="xl" size="xl">
                  <IconX size={22} />
                </ThemeIcon>
              </Center>
            }
          />
        </Center>
        <Title ta="center">Payment Failed</Title>
        <Text ta="center">
          Your payment was not successful. Please try again or or contact{" "}
          <Text variant="link" component="a" href="mailto:jules@bidscape.com">
            jules@bidscape.com
          </Text>
          ; if you need assistance.
        </Text>
        <Group justify="center" mt="md">
          <Button
            onClick={() => {
              navigate(`/payment?rfqId=${searchParams.get("rfqId")}`);
            }}
          >
            Try Again
          </Button>
        </Group>
      </Conditional>
    </Container>
  );
};

const Container = styled.div`
  height: 70vh;
  margin: auto !important;
`;

export default PaymentSuccess;
