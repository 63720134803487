import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { trackAction } from "../../services/firebaseAnalytics";
import { Mutation, MutationRemoveCartItemArgs } from "../../typing/gql.schema";

export const MUTATION_REMOVE_ITEM = gql`
  mutation removeCartItem($id: ID!) {
    removeCartItem(id: $id)
  }
`;

const useRemoveItem = () => {
  const { t } = useTranslation();
  const [mutate, { loading, data, error }] = useMutation<{ removeCartItem: Mutation["removeCartItem"] }, MutationRemoveCartItemArgs>(MUTATION_REMOVE_ITEM);

  const remove = async (data: MutationRemoveCartItemArgs) => {
    try {
      const res = await mutate({ variables: { ...data } });
      if (!res.data?.removeCartItem) throw new Error("Error removing item from cart");
      showNotification({ color: "teal", title: t("useRemoveItemText1"), message: t("useRemoveItemText2") });
      trackAction("remove_item", { item_id: data.id });
    } catch (error: any) {
      showNotification({ color: "red", title: t("useRemoveItemText3"), message: t("useRemoveItemText4") });
      Sentry.captureException(error, { extra: { data, where: "useRemoveItem" } });
    }
  };

  return { remove, loading, error, data };
};

export default useRemoveItem;
