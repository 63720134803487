import { AnimatePresence, motion } from "framer-motion";
import { fadeAnimation } from "../utils/constants";

interface FadeTransitionProps {
  transition?: object;
  children: React.ReactNode;
}

interface SlideProps {
  children: React.ReactNode;
  direction: "left" | "right";
}

export const FadeTransition: React.FC<FadeTransitionProps> = ({ children, transition }) => {
  const overRideTransition = transition ? { ...fadeAnimation, ...transition } : fadeAnimation;
  return (
    <AnimatePresence>
      <motion.div {...overRideTransition}>{children}</motion.div>
    </AnimatePresence>
  );
};

export const slideRightAnimation = {
  exit: { x: 0, opacity: 0 },
  transition: { duration: 0.2 },
  animate: { x: 0, opacity: 1 },
  initial: { x: 300, opacity: 0 },
};

export const slideLeftAnimation = {
  exit: { x: 0, opacity: 0 },
  transition: { duration: 0.2 },
  animate: { x: 0, opacity: 1 },
  initial: { x: -300, opacity: 0 },
};

export const SlideTransition: React.FC<SlideProps> = ({ children, direction }) => {
  const transition = direction === "left" ? slideLeftAnimation : slideRightAnimation;
  return (
    <AnimatePresence>
      <motion.div {...transition}>{children}</motion.div>
    </AnimatePresence>
  );
};
