import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useRedirectPath from "../../hooks/useRedirectPath";
import { trackAction } from "../../services/firebaseAnalytics";
import useUpdateUserCid from "./useUpdateUserCid";

const useLinkAuthenticator = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { key, value } = useParams();
  const { setLoginToken } = useAuth();
  const { updateUserCid } = useUpdateUserCid();
  const { redirectPath } = useRedirectPath();
  const [anErrorOccurred, setAnErrorOccurred] = useState(false);
  const [isLoginComplete, setIsLoginComplete] = useState(false);

  const authenticateLink = useCallback(async (key?: string, value?: string) => {
    try {
      const response = await fetch("//api.bidscape.com/use-login-link", {
        method: "POST",
        body: JSON.stringify({ Key: key, Value: value }),
      });

      const data: { Token?: string } = await response.json();

      if (!data.Token) throw new Error("Error authenticating link");

      await setLoginToken(data.Token);

      updateUserCid();
      setIsLoginComplete(true);
      trackAction("login", { method: "local" });
    } catch (error: any) {
      setAnErrorOccurred(true);
      showNotification({ color: "red", title: t("useLinkAuthenticatorText1"), message: t("useLinkAuthenticatorText2") });
      Sentry.captureException(error, { extra: { key, value }, tags: { where: "useLinkAuthenticator" } });
    }
  }, []);

  useEffect(() => {
    authenticateLink(key, value);
  }, [key, value, authenticateLink]);

  useEffect(() => {
    if (isLoginComplete) {
      console.log("useLinkAuthenticator: navigate to", redirectPath);
      navigate(redirectPath, { replace: true });
    }
  }, [isLoginComplete, navigate]);

  useEffect(() => {
    if (anErrorOccurred) {
      navigate("/login", { replace: true });
    }
  }, [anErrorOccurred, navigate]);
};

export default useLinkAuthenticator;
