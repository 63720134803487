import { Button, Group, List, Space, Text, Title } from "@mantine/core";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CategoriesListingBase from "../../CategoriesListingBase/CategoryListingBase";
import PlantListingBase from "../../PlantsListingBase/PlantsListingBase";
import PlantsListingSearchBar from "../../PlantsListingBase/components/PlantsListingSearchBar";
import Conditional from "../../components/Conditional";
import { FadeTransition, SlideTransition } from "../../components/Transitions";
import useProjectCartItems from "../../hooks/useCartItems";
import usePlantEntry from "../hooks/usePlantEntry";
import PlantEntryItem from "./PlantEntryItem";

interface Props {
  onNext: () => void;
  transitionDirection: "right" | "left";
}

const PlantEntry: React.FC<Props> = ({ onNext, transitionDirection }) => {
  const { t } = useTranslation();
  const plantEntry = usePlantEntry();
  const { cartItems, removeCartItem } = useProjectCartItems();

  return (
    <SlideTransition direction={transitionDirection}>
      <Container>
        <Title>{t("requestPlantEntryText1")}</Title>
        <Text>{t("requestPlantEntryText2")}</Text>
        <Space h={"lg"} />
        <Conditional renderIf={cartItems.length}>
          <List>
            {cartItems.map((item, index) => (
              <PlantEntryItem key={index} item={item} removeCartItem={removeCartItem} />
            ))}
          </List>
        </Conditional>
        <Conditional renderIf={cartItems.length}>
          <FadeTransition>
            <Group justify="center" mt="md">
              <Button data-testid="finish-plant-entry-btn" onClick={onNext} rightSection={<IconArrowRight />}>
                {t("requestPlantEntryText8")}
              </Button>
            </Group>
          </FadeTransition>
        </Conditional>
        <Space h="xl" />
        <FadeTransition>
          <Conditional renderIf={cartItems.length}>
            <Title order={2} ta="center">
              {t("requestPlantEntryText10")}
            </Title>
            <Space h="md" />
          </Conditional>
          <PlantsListingSearchBar onSearchKey={plantEntry.search.onSearchKey} defaultSearchKey={plantEntry.search.searchKey} />
          <Conditional renderIf={!(plantEntry.search.searchKey || plantEntry.category)}>
            <CategoriesListingBase onItemActionButtonClick={plantEntry.setCategory} />
          </Conditional>
          <Conditional renderIf={plantEntry.search.searchKey || plantEntry.category}>
            <PlantListingBase searchKey={plantEntry.search.searchKey} categoryId={plantEntry.category?.id} />
          </Conditional>
        </FadeTransition>
        <Conditional renderIf={plantEntry.category}>
          <Group justify="center" mt="md">
            <Button variant="default" onClick={plantEntry.backToCategories} leftSection={<IconArrowLeft />}>
              {t("requestPlantEntryText9")}
            </Button>
          </Group>
        </Conditional>
      </Container>
    </SlideTransition>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 928px;

  @media (max-width: 576px) {
    border-top: 1px solid #eee;
  }
`;

PlantEntry.defaultProps = {
  transitionDirection: "right",
};

export default PlantEntry;
