import { Button, Card, Group, Image, Space, Text } from "@mantine/core";
import { IconCamera, IconPencil, IconPhoto } from "@tabler/icons-react";
import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Conditional from "../../components/Conditional";
import { RfqType } from "../../enums";
import useAuth from "../../hooks/useAuth";
import useElementHover from "../../hooks/useElementHover";
import { Estimate, EstimateItem } from "../../typing/gql.schema";
import { formatCurrency, getImageUrl } from "../../utils/utils";
import useApprovedPhotos from "../hooks/useApprovedPhotos";
import useIsCreatedMoreThan30DaysAgoAndDeliveryDateLessThan30DaysAway from "../hooks/useIsCreatedMoreThan30DaysAgoAndDeliveryDateLessThan30DaysAway";
import useModals from "../hooks/useModals";
import useQuoteItemByCartItemID from "../hooks/useQuoteItemByCartItemID";
import useShowQuoteItemBundlePricing from "../hooks/useShowQuoteItemBundlePricing";
import useSubstitution from "../hooks/useSubstitution";

type Props = {
  item: EstimateItem;
  estimate?: Estimate;
  modals: ReturnType<typeof useModals>;
  openDefaultPhotoModal: (filename: string) => void;
  openApprovedPhotoModal: (filename: string) => void;
};

const QuoteItem: React.FC<Props> = ({ item, estimate, modals, openApprovedPhotoModal, openDefaultPhotoModal }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const editButtonHover = useElementHover();
  const negotiateButtonHover = useElementHover();
  const requestPhotosButtonHover = useElementHover();
  const substitution = useSubstitution(estimate, item.cartItem.id);
  const approvedPhotos = useApprovedPhotos(estimate, item.cartItem.id);
  const showBundlePricing = useShowQuoteItemBundlePricing(estimate!, item);
  const quoteItemByCartItemID = useQuoteItemByCartItemID(estimate, item.cartItem.id);
  const isCreatedMoreThan30DaysAgoAndDeliveryDateLessThan30DaysAway = useIsCreatedMoreThan30DaysAgoAndDeliveryDateLessThan30DaysAway(estimate);

  const isNurseryNet = estimate?.rfq.type === RfqType.NURSERY_NET;
  const showIndividualUnitPrice = (estimate?.rfq.type === RfqType.ONLINE_TYPE || estimate?.rfq.type === RfqType.DEFAULT);
  const showUnitTotal = isNurseryNet;

  return (
    <Container data-testid="quote-item">
      <Card shadow="sm" p="md" radius="xs" withBorder>
        <Conditional renderIf={!substitution}>
          <Text>
            <strong>{item?.cartItem?.quantity}</strong>&nbsp;{item?.cartItem?.size?.name}&nbsp;<em>{item?.cartItem?.plant?.latinName}</em>&nbsp;
            {item?.cartItem?.notes}&nbsp;({item?.cartItem?.plant?.commonName})&nbsp;{t("quotesText2")}&nbsp;
            <Conditional renderIf={showIndividualUnitPrice}>
              <Conditional renderIf={!showBundlePricing}>
                <Text component="strong">{formatCurrency(item.individualUnitPrice)}</Text>
                &nbsp;<span>{t("quotesText79")}</span>
              </Conditional>
              <Conditional renderIf={showBundlePricing}>
                <Text fw={700} component="span" td="line-through">
                  {formatCurrency(item.individualUnitPrice)}
                </Text>
                <Text fw={700} component="span" c="green">
                  &nbsp;{formatCurrency(item.unitTotal)} {t("quotesText70")}
                </Text>
              </Conditional>
            </Conditional>
            <Conditional renderIf={showUnitTotal}>
              <strong>{formatCurrency(item.unitTotal)}</strong>&nbsp;
              <Conditional renderIf={item.cartItem.quantity > 1}>{t("quotesText79")}</Conditional>
            </Conditional>
          </Text>
        </Conditional>
        <Conditional renderIf={substitution}>
          <Text>
            <strong>{item?.cartItem?.quantity}</strong>&nbsp;{substitution?.oldSizeName}
            &nbsp;<em>{substitution?.oldLatinName}</em>&nbsp;({substitution?.oldCommonName})&nbsp;
            {t("quotesText5")}&nbsp;<strong className="text-danger">{t("quotesText6")}</strong>&nbsp;{t("quotesText7")}&nbsp;
            {substitution?.newSizeName} <em>{substitution?.newLatinName}</em>
            {item?.cartItem?.notes} ({substitution?.newCommonName}) {t("quotesText2")}&nbsp;
            <Conditional renderIf={showIndividualUnitPrice}>
              <Conditional renderIf={!showBundlePricing}>
                <Text component="strong">{formatCurrency(item.individualUnitPrice)}</Text>
                &nbsp;<span>{t("quotesText79")}</span>
              </Conditional>
              <Conditional renderIf={showBundlePricing}>
                <Text fw={700} component="span" td="line-through">
                  {formatCurrency(item.individualUnitPrice)}
                </Text>
                <Text fw={700} component="span" c="green">
                  &nbsp;{formatCurrency(item.unitTotal)} {t("quotesText70")}
                </Text>
              </Conditional>
            </Conditional>
            <Conditional renderIf={showUnitTotal}>
              <strong>{formatCurrency(item.unitTotal)}</strong>&nbsp;
              <Conditional renderIf={item.cartItem.quantity > 1}>{t("quotesText79")}</Conditional>
            </Conditional>
          </Text>
        </Conditional>
        <Space h={15} />
        <Conditional renderIf={approvedPhotos}>
          <Group>
            {approvedPhotos?.map((photo, index) => (
              <Image
                key={index}
                width={100}
                height={100}
                radius={"sm"}
                src={getImageUrl(photo.filename)}
                onClick={() => openApprovedPhotoModal(photo.filename)}
              />
            ))}
          </Group>
        </Conditional>
        <Conditional renderIf={item.cartItem.defaultPhoto && !approvedPhotos}>
          <Group>
            <Image
              width={100}
              height={100}
              radius={"sm"}
              className={cx({
                grayImage: isCreatedMoreThan30DaysAgoAndDeliveryDateLessThan30DaysAway,
              })}
              onClick={() => openDefaultPhotoModal(item?.cartItem.defaultPhoto?.filename!)}
              src={getImageUrl(item?.cartItem.defaultPhoto?.filename)}
            />
          </Group>
        </Conditional>
        <Space h={15} />
        <Group justify="center" gap={8}>
          <Conditional
            renderIf={
              estimate?.editable &&
              item.cartItem.defaultPhoto &&
              !item?.cartItem?.photosRequested &&
              !approvedPhotos &&
              isCreatedMoreThan30DaysAgoAndDeliveryDateLessThan30DaysAway
            }
          >
            <Button
              leftSection={<IconCamera />}
              ref={requestPhotosButtonHover.hoverRef}
              onClick={() => modals.openPhoto(item!)}
              data-testid="quote-item-request-photos-btn"
              variant={requestPhotosButtonHover.isHover ? "outline" : "default"}
            >
              {t("quotesText75")}
            </Button>
          </Conditional>
          <Conditional
            renderIf={
              estimate?.editable &&
              !approvedPhotos &&
              !item?.cartItem?.photosRequested &&
              (!item.cartItem.defaultPhoto || (auth.loggedInUser?.isAdmin && !isCreatedMoreThan30DaysAgoAndDeliveryDateLessThan30DaysAway))
            }
          >
            <Button
              leftSection={<IconCamera />}
              ref={requestPhotosButtonHover.hoverRef}
              onClick={() => modals.openPhoto(item!)}
              data-testid="quote-item-request-photos-btn"
              variant={requestPhotosButtonHover.isHover ? "outline" : "default"}
            >
              {t("quotesText10")}
            </Button>
          </Conditional>
          <Conditional renderIf={item?.cartItem?.photosRequested && !approvedPhotos}>
            <Button disabled variant="filled" leftSection={<IconCamera />}>
              {t("quotesText11")}
            </Button>
          </Conditional>
          <Conditional renderIf={item?.cartItem?.photosRequested && approvedPhotos}>
            <a href={`/photos/view/${quoteItemByCartItemID?.id}`}>
              <Button color="blue" variant="outline" leftSection={<IconPhoto />}>
                {t("quotesText12")}
              </Button>
            </a>
          </Conditional>
          <Conditional renderIf={estimate?.editable && !isNurseryNet}>
            <Button
              data-testid="negotiate-item-btn"
              ref={negotiateButtonHover.hoverRef}
              onClick={() => modals.openNegotiate(item!)}
              variant={negotiateButtonHover.isHover ? "outline" : "default"}
            >
              {t("quotesText42")}
            </Button>
          </Conditional>
          <Conditional renderIf={estimate?.editable}>
            <Button
              leftSection={<IconPencil />}
              data-testid="edit-item-btn"
              ref={editButtonHover.hoverRef}
              onClick={() => modals.openEdit(item!)}
              variant={editButtonHover.isHover ? "outline" : "default"}
            >
              {t("quotesText13")}
            </Button>
          </Conditional>
        </Group>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 30px;

  .grayImage {
    opacity: 0.2;
    filter: alpha(opacity=40); /* msie */
  }

  .text-danger {
    color: #ff3860;
  }
`;

export default QuoteItem;
