import { Container, Image, Space, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import CategoriesListingBase from "../CategoriesListingBase/CategoryListingBase";
import PlantListingBase from "../PlantsListingBase/PlantsListingBase";
import PlantsListingSearchBar from "../PlantsListingBase/components/PlantsListingSearchBar";
import useUrlSearchKey from "../PlantsListingBase/hooks/useUrlSearchKey";
import Conditional from "../components/Conditional";
import classes from "./styles/index.module.css";

const Home: React.FC = () => {
  const { t } = useTranslation();
  const search = useUrlSearchKey();

  return (
    <Container fluid p="0">
      <div className={classes.banner}>
        <a href="/">
          <Image
            height={200}
            src="https://images.squarespace-cdn.com/content/v1/5895039bd2b857fb4143a8d7/1486195479531-S06L0511FKNF3W1HV2ZG/forest-background.jpg"
          />
        </a>
        <div className={classes.overlay} />
        <div className={classes.bannerContent}>
          <Title size={50} c="white" ta="center">
            {t("homeText1")}
          </Title>
          <Text size="lg" c="white" ta="center">
            {t("homeText2")}
          </Text>
        </div>
      </div>
      <Space h="xl" />
      <Container size="lg">
        <PlantsListingSearchBar onSearchKey={search.onSearchKey} defaultSearchKey={search.searchKey} />
        <Conditional renderIf={!search.searchKey}>
          <CategoriesListingBase pageSize={81} />
        </Conditional>
        <Conditional renderIf={search.searchKey}>
          <PlantListingBase searchKey={search.searchKey} />
        </Conditional>
      </Container>
    </Container>
  );
};

export default Home;
