import { Button, Center, Container, Group, Loader, SimpleGrid, Space } from "@mantine/core";
import * as Sentry from "@sentry/react";
import { IconArrowLeft, IconPhoto } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Conditional from "../components/Conditional";
import LightBoxModal from "../components/LightBoxModal";
import { FadeTransition } from "../components/Transitions";
import useAuth from "../hooks/useAuth";
import CurrentPhoto from "./components/CurrentPhoto";
import DefaultPhotoHeader from "./components/DefaultPhotoHeader";
import DefaultPhotoOptionsQueryError from "./components/DefaultPhotoOptionsQueryError";
import DefaultPhotoQueryError from "./components/DefaultPhotoQueryError";
import NoPhotoOptions from "./components/NoPhotoOptions";
import PhotoItem from "./components/PhotoItem";
import getUsePhotosForPlantAndSizeArgs from "./hooks/getUsePhotosForPlantAndSizeArgs";
import useEstimateItemQuery from "./hooks/useEstimateItemQuery";
import useModals from "./hooks/useModals";
import usePhotosForPlantAndSize from "./hooks/usePhotosForPlantAndSize";

const DefaultPhoto: React.FC = () => {
  const auth = useAuth();
  const modals = useModals();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { estimateItemId } = useParams();
  const estimateItemQuery = useEstimateItemQuery(estimateItemId);
  const options = usePhotosForPlantAndSize(...getUsePhotosForPlantAndSizeArgs(estimateItemQuery.estimateItem!));

  if (estimateItemQuery.loading) {
    return (
      <Container>
        <Space h={50} />
        <Center>
          <Loader variant="bars" />
        </Center>
      </Container>
    );
  }

  if (estimateItemQuery.error) {
    return <DefaultPhotoQueryError error={estimateItemQuery.error} refetch={estimateItemQuery.refetch} />;
  }

  return (
    <FadeTransition>
      <Container>
        <DefaultPhotoHeader estimateItem={estimateItemQuery.estimateItem!} />
        <Conditional renderIf={estimateItemQuery.estimateItem}>
          <CurrentPhoto estimateItem={estimateItemQuery.estimateItem!} />
        </Conditional>
        <Space h={20} />
        <Conditional renderIf={auth.loggedInUser?.isAdmin && !options.showed}>
          <Center>
            <Button size="lg" variant="outline" onClick={options.load} leftSection={<IconPhoto />}>
              {t("defaultPhotoText8")}
            </Button>
          </Center>
        </Conditional>
        <Conditional renderIf={options.showed && options.loading}>
          <Center>
            <Loader variant="dots" />
          </Center>
        </Conditional>
        <Conditional renderIf={!options.loading && options.showed && options.error}>
          <DefaultPhotoOptionsQueryError error={options.error} refetch={options.load} />
        </Conditional>
        <Conditional renderIf={!options.loading && options.showed && options.photos?.length === 0 && !options.error}>
          <NoPhotoOptions />
        </Conditional>
        <Conditional renderIf={!options.loading && options?.photos.length > 0}>
          <SimpleGrid cols={{ base: 1, sm: 3 }} spacing={20}>
            {options.photos.map((photo, index) => (
              <PhotoItem
                key={index}
                photo={photo}
                openMediaModal={modals.openMedia}
                refetchDefaultPhoto={estimateItemQuery.refetch}
                cartItemId={estimateItemQuery.estimateItem!.cartItem!.id}
              />
            ))}
          </SimpleGrid>
        </Conditional>
        <Space h={30} />
        <Group justify="center">
          <Button size="lg" onClick={() => navigate(-1)} leftSection={<IconArrowLeft />} variant="default">
            {t("defaultPhotoText23")}
          </Button>
        </Group>
        <Space h={30} />
        <LightBoxModal filename={modals.mediaFilename} opened={modals.media} onClose={modals.closeMedia} />
      </Container>
    </FadeTransition>
  );
};

export default Sentry.withProfiler(DefaultPhoto, { name: "DefaultPhoto" });
