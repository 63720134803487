const quotes = {
  quotesText0: "Quote",
  quotesText1: "Estimated Delivery Date",
  quotesText2: "for",
  quotesText3: "each and",
  quotesText4: "total",
  quotesText5: "was",
  quotesText6: "substituted",
  quotesText7: "with",
  quotesText8: "each and",
  quotesText9: "total",
  quotesText10: "Request Photos",
  quotesText11: "Photos Requested",
  quotesText12: "Photo Options",
  quotesText13: "Edit",
  quotesText14: "was removed",
  quotesText15: "is unavailable",
  quotesText16: "Add",
  quotesText17: "Request Sub",
  quotesText18: "Subtotal:",
  quotesText19: "Delivery Charge",
  quotesText20: "Tax",
  quotesText21: "Total:",
  quotesText22: "Rebate:",
  quotesText23: `- Tax is applied if you do not have a reseller license on file with us. Have a license?`,
  quotesText24: "Contact us",
  quotesText25: "Pay with card",
  quotesText26: "Pay with terms",
  quotesText27: "Back",
  quotesText28: "Share",
  quotesText29: "Download quote as Excel (.xlsx)",
  quotesText30: "Add plant",
  quotesText31: "Will you like to add plant?",
  quotesText32: "Confirm",
  quotesText33: "Edit Plant",
  quotesText34: "What would you like to do to your",
  quotesText35: "Remove",
  quotesText36: "Remove Plant",
  quotesText37: "Removing your",
  quotesText38: "may impact the pricing and availability of the rest of your order.",
  quotesText39: "As well, item removals reduce your",
  quotesText40: "Rebate",
  quotesText41: "Instead of removing the item, consider using our price negotiation tool to try and hit your price targets.",
  quotesText42: "Negotiate Price",
  quotesText43: "Remove",
  quotesText44: "Negotiate Price",
  quotesText45: "Set your target unit price for",
  quotesText46: "Target Price:",
  quotesText47: "Price",
  quotesText48: "A valid price is required",
  quotesText49: "Note: Negotiation is fully automated, but takes up to 24-hours to finalize.",
  quotesText50: "Begin Negotiation",
  quotesText51: "Confirm Photo Request",
  quotesText52: "Please only request photos for items you are actually looking to purchase.",
  quotesText53: `We welcome the use of Bidscape to get estimates on projects, but to maximize your options, we send photo requests to all of the yards that reported having the item (in some cases it can be dozens), and it takes significant time and effort to respond.`,
  quotesText54: "We encourage you to keep this in mind as you make your requests.",
  quotesText55: "Confirm Photo Request",
  quotesText56: "Share quote",
  quotesText57: "Share this quote with:",
  quotesText58: "Email address",
  quotesText59: "A valid email is required",
  quotesText60: "Share",
  quotesText61: "Confirm Substitute Request",
  quotesText62: "Requesting a substitute will take up to 24 hours to complete.",
  quotesText63: "Confirm request",
  quotesText64: "Apply for financing",
  quotesText65: " Bidscape can offer terms for your plant material, depending on the invoice. Contact",
  quotesText66: "for details.",
  quotesText67: "Excel (.xlsx)",
  quotesText68: "- Your delivery total is based on a single delivery for all listed materials. Split deliveries will result in additional costs.",
  quotesText69: "individually",
  quotesText70: "each (bundled)",
  quotesText71: "Individual subtotal: ",
  quotesText72: "Bundled subtotal: ",
  quotesText73: "saved",
  quotesText74: "or",
  quotesText75: "Request Current Photos",
  quotesText76: "View Photo",
  quotesText77: "This is a representative photo",
  quotesText78: "Close",
  quotesText79: "each",
  quotesText80: "Please note: Any changes made to your quote (deleting items or splitting shipments) may result in changes to item cost and freight.",
  quotesText81: "This quote has expired and has been archived. To renew it, please contact Jules at",
  quotesText82: "Service Fee",
  quotesText83: "(Credit card payments have an additional 3% processing fee applied)",

  //quotes terms
  quotesTermsText1: "Terms & Conditions",
  quotesTermsText2: "Quotes are good for 7 days and are subject to change based on availability.",
  quotesTermsText3: "Any changes made to quotes (adding or deleting items) will result in price fluctuations in the overall quote.",
  quotesTermsText4: "A minimum 20% restocking fee applies to ALL cancelled orders.",
  quotesTermsText5: `A licensed representative or representatives of your organization must be present at the delivery site on the day of delivery along with the equipment and manpower required to offload the delivery truck.`,
  quotesTermsText6: "Items to be returned or exchanged shall be left on the delivery to be returned to the supplying vendor.",
  quotesTermsText7: "No returns or exchanges will be accepted after delivery.",

  // use add item
  useAddItemText1: "Successful",
  useAddItemText2: "Item added successfully.",
  useAddItemText3: "Error",
  useAddItemText4: "Item couldn't be added. Email jules@bidscape.com for help..",

  // use add photo request
  useAddPhotoRequestText1: "Successful",
  useAddPhotoRequestText2: "Photos requested successfully!.",
  useAddPhotoRequestText3: "Error",
  useAddPhotoRequestText4: "Couldn't request photos. Email jules@bidscape.com for help.",

  // use add sub request
  useAddSubRequestText1: "Successful",
  useAddSubRequestText2: "Substitution requested successfully.",
  useAddSubRequestText3: "Error",
  useAddSubRequestText4: "Substitution couldn't be requested. Email jules@bidscape.com for help.",

  // use share rfq
  useNegotiateText1: "Successful",
  useNegotiateText2: "Negotiation started successfully.",
  useNegotiateText3: "Error",
  useNegotiateText4: "Error occurred, please contact heather@bidscape.com",

  // use share quote

  useShareRfqText1: "Successful",
  useShareRfqText2: "Rfq shared successfully.",
  useShareRfqText3: "Error",
  useShareRfqText4: "Rfq couldn't be shared. Email jules@bidscape.com for help.",

  // use remove item
  useRemoveItemText1: "Successful",
  useRemoveItemText2: "Item removed successfully.",
  useRemoveItemText3: "Error",
  useRemoveItemText4: "Item couldn't be removed.  Email jules@bidscape.com for help.",

  // use use check availability
  useCheckAvailabilityText1: "Successful",
  useCheckAvailabilityText2: "{{cartName}} is being checked with {{numSent}} yards!.",
  useCheckAvailabilityText3: "Error",
  useCheckAvailabilityText4: "An error occurred checking {{cartName}} availability.",

  quoteQueryErrorText1: `An error occurred while loading your quote, please make sure you have an internet connection, or else contact Jules at`,
  quoteQueryErrorText2: `An error occurred while loading your quote, contact Jules at`,
  quoteQueryErrorText3: `for assistance.`,
  quoteQueryErrorText4: `Back`,
  quoteQueryErrorText5: `Try again`,
};

export default quotes;
