import { useEffect } from "react";
import { trackAction } from "../../services/firebaseAnalytics";
import { Order } from "../../typing/gql.schema";

const useViewOrder = (order?: Order) => {
  useEffect(() => {
    if (order) {
      trackAction("view_order", { id: order.id });
    }
  }, [order]);
};

export default useViewOrder;
