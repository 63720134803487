import { Alert, Button, Divider, Grid, Group, Loader, Space, Text } from "@mantine/core";
import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useHover } from "usehooks-ts";
import Conditional from "../../components/Conditional";
import useAuth from "../../hooks/useAuth";
import { formatDate } from "../../utils/utils";
import useClosedRfqsQuery from "../hooks/useClosedRfqsQuery";
import { ClosedRfq } from "../types";

interface ClosedRequestsItemProps {
  index: number;
  rfq: ClosedRfq;
  lastIndex: number;
}

const ClosedRequestsItem: React.FC<ClosedRequestsItemProps> = ({ rfq, lastIndex, index }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const viewButtonRef = useRef(null);
  const isViewButtonHover = useHover(viewButtonRef);

  const viewUrl = useMemo(() => {
    if (rfq.closedRfqType === "quote") {
      return `/quotes/quote/${rfq.id}`;
    }
    console.log("rfq", rfq);
    return `/quotes/estimate/${rfq.id}`;
  }, [rfq]);

  return (
    <>
      <Grid columns={34} data-testid="open-request-item">
        <Grid.Col span={{ base: 34, md: 31, lg: 28 }}>
          <Text>
            {t("closedRequestsText3")}
            {rfq?.id} <em>{rfq.cart.name}</em> {t("closedRequestsText4")} <Conditional renderIf={!rfq?.phased}>{formatDate(rfq?.deliveryDate)}</Conditional>
            <Conditional renderIf={rfq.phased}>{t("closedRequestsText5")}</Conditional>&nbsp;
          </Text>
        </Grid.Col>
        <Grid.Col span={{ base: 34, md: 3, lg: 6 }}>
          <Button
            component="a"
            href={viewUrl}
            ref={viewButtonRef}
            variant={isViewButtonHover ? "outline" : "default"}
            onClick={(e) => {
              e.preventDefault();
              navigate(viewUrl);
            }}
          >
            {t("closedRequestsText6")}
          </Button>
        </Grid.Col>
      </Grid>
      <Conditional renderIf={index !== lastIndex}>
        <Divider my="xs" />
      </Conditional>
    </>
  );
};

const ClosedRequests: React.FC = () => {
  const { t } = useTranslation();
  const { activeUser } = useAuth();
  const query = useClosedRfqsQuery(activeUser?.id!);

  if (query.loading) {
    return (
      <Group justify="center">
        <Loader type="dots" />
      </Group>
    );
  }

  return (
    <>
      <Space h={"lg"} />
      {query.rfqs.map((rfq, index) => (
        <ClosedRequestsItem index={index} key={index} rfq={rfq} lastIndex={query.rfqs.length - 1} />
      ))}
      <Conditional renderIf={!query.rfqs.length}>
        <Alert title={t("closedRequestsText1")} color="teal">
          {t("closedRequestsText2")}
        </Alert>
        <Space h={"lg"} />
      </Conditional>
    </>
  );
};

export default ClosedRequests;
