import { gql, useQuery } from "@apollo/client";
import { Order, Query, Rfq } from "../../typing/gql.schema";

export const buyerDashboardGQL = gql`
  query buyerDashboard($id: ID!) {
    openOrdersForBuyer(id: $id) {
      __typename
      id
      quote {
        id
        rfq {
          id
          phased
          deliveryDate
          cart {
            id
            name
          }
        }
      }
    }
    openRfqsForBuyer(id: $id) {
      __typename
      id
      phased
      status
      deliveryDate
      cart {
        id
        name
      }
      bestQuote {
        id
        hmac
      }
    }
  }
`;

interface BuyerDashboard {
  openRfqsForBuyer: Query["openRfqsForBuyer"];
  openOrdersForBuyer: Query["openOrdersForBuyer"];
}

const useBuyerDashboard = (id: string) => {
  const { loading, error, data, refetch } = useQuery<BuyerDashboard>(buyerDashboardGQL, {
    variables: {
      id,
    },
    skip: !id,
  });

  const orders = (data?.openOrdersForBuyer || []).reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, current];
  }, [] as Array<Order>);

  const rfqs = (data?.openRfqsForBuyer || []).reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, current];
  }, [] as Array<Rfq>);

  return { data, error, refetch, loading, rfqs, orders };
};

export default useBuyerDashboard;
