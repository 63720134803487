import { gql, useQuery } from "@apollo/client";
import { Query, QueryEstimateItemArgs } from "../../typing/gql.schema";

export const estimateItemGQL = gql`
  query estimateItem($id: ID!) {
    estimateItem(id: $id) {
      id
      cartItem {
        id
        quantity
        plant {
          id
          latinName
          commonName
        }
        size {
          id
          name
        }
        defaultPhoto {
          id
          filename
          yardProduct {
            id
          }
        }
      }
    }
  }
`;

const useEstimateItemQuery = (id: string = "") => {
  const { loading, data, error, refetch } = useQuery<{ estimateItem: Query["estimateItem"] }, QueryEstimateItemArgs>(estimateItemGQL, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const estimateItem = data?.estimateItem;

  return { estimateItem, loading, error, data, refetch };
};

export default useEstimateItemQuery;
