import { Estimate } from "../../typing/gql.schema";

interface IUseExtractEstimateItemAvailability {
  estimate?: Estimate;
  estimateItemId?: string;
}

const useExtractEstimateItemAvailability = (props: IUseExtractEstimateItemAvailability) => {
  const { estimate, estimateItemId } = props;

  const estimateItem = estimate?.estimateItems.find((estimateItem) => estimateItem?.id === estimateItemId);

  const availability = estimateItem?.availability;

  return availability;
};

export default useExtractEstimateItemAvailability;
