import moment from "moment";
import { Order, Rfq } from "../typing/gql.schema";

export const setToken = (token: string) => {
  localStorage.clear();
  localStorage.setItem("authToken", token);
};

export const getToken = () => {
  return localStorage.getItem("authToken");
};

export const guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
};

export const deliveryToday = (o: Order) => {
  const today = new Date();
  let deliveryDate = new Date(o.quote.rfq.deliveryDate);
  if (deliveryDate.getDate() === today.getDate() && deliveryDate.getMonth() === today.getMonth() && deliveryDate.getFullYear() === today.getFullYear()) {
    return true;
  }
  return false;
};

export const sortOrders = (a: Order, b: Order) => {
  if (a.quote.rfq.deliveryDate < b.quote.rfq.deliveryDate) {
    return -1;
  } else if (a.quote.rfq.deliveryDate > b.quote.rfq.deliveryDate) {
    return 1;
  }
  return 0;
};

export const sortRfqs = (a: Rfq, b: Rfq): number => {
  if (a.modified > b.modified) {
    return -1;
  } else if (a.modified < b.modified) {
    return 1;
  }
  return 0;
};

export const isDevelopment = (force?: boolean) => {
  if (force !== undefined) return force;
  if (!import.meta.env.NODE_ENV || import.meta.env.NODE_ENV === "development") return true;
  return false;
};

/**
 * Formate currency values
 * @param { number | string } currency
 */
export const formatCurrency = (currency: number | string = 0) => {
  const formatter = new Intl.NumberFormat("en-US", {
    currency: "USD",
    style: "currency",
  });

  if (typeof currency === "string") {
    return formatter.format(parseFloat(currency));
  }
  return formatter.format(currency);
};

/**
 * Formate date
 * @param { string } date
 */
export const formatDate = (date?: string) => {
  return moment.utc(date).format("ddd, MMM D, YYYY");
};

export const isEmpty = (obj: object) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};

const padDate = (n: number) => {
  if (`${n}`.length === 1) {
    return `0${n}`;
  }
  return `${n}`;
};

export const minDeliveryDate = () => {
  const now = new Date();
  const minDaysToDelivery = 2;

  now.setDate(now.getDate() + minDaysToDelivery);
  return `${now.getFullYear()}-${padDate(now.getMonth() + 1)}-${padDate(now.getDate())}`;
};

export const extractFt = (inches: number) => {
  return Math.round(inches / 12);
};

export const extractIn = (inches: number) => {
  return inches % 12;
};

export const formatMarkdown = (markdown: string = "") => {
  // Replace bullet points with <li> tags
  markdown = markdown.replace(/^- (.+)/gm, "<li>$1</li>");

  // Replace bold text with <strong> tags
  markdown = markdown.replace(/\*\*([^*]+)\*\*/g, "<strong>$1</strong>");

  // Replace italicized text with <em> tags
  markdown = markdown.replace(/\*([^*]+)\*/g, "<em>$1</em>");

  // Replace line breaks with <br> tags
  // markdown = markdown.replace(/\\n/g, "<br>");

  // Replace headers (e.g., ## Header) with <h2> tags
  // markdown = markdown.replace(/##\s(.+)/g, "<h2>$1</h2>");

  // Replace unordered lists (e.g., - Item) with <ul> tags
  // markdown = markdown.replace(/^- (.+)/g, "<ul><li>$1</li></ul>");

  // Replace paragraphs with <p> tags
  // markdown = markdown.replace(/(.+)/g, "<p>$1</p>");

  return markdown;
};

export const getImageUrl = (filename?: string) => {
  return `https://img.bidscape.com/yard-product-photos/${filename}`;
};

export const scrollToTop = () => {
  document.querySelector("body")?.scrollTo(0, 0);
};
