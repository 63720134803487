import { gql, useQuery } from "@apollo/client";
import { Query, QueryQuoteItemArgs } from "../../typing/gql.schema";

export const buyerPhotosGQL = gql`
  query quoteItem($id: ID!) {
    quoteItem(id: $id) {
      __typename
      id
      quoteId
      editable
      cartItem {
        id
        notes
        quantity
      }
      estimateItem {
        id
        unitTotal
      }
      yardProduct {
        id
        height
        width
        plant {
          id
          latinName
          commonName
        }
        size {
          id
          name
        }
        approvedPhotos {
          id
          filename
        }
      }
      quote {
        id
        rfq {
          id
        }
      }
    }
  }
`;

const usePhotosQuery = (id: string = "") => {
  const { loading, data, refetch, error } = useQuery<{ quoteItem: Query["quoteItem"] }, QueryQuoteItemArgs>(buyerPhotosGQL, {
    variables: {
      id,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const quoteItem = data?.quoteItem || undefined;

  return { loading, data, refetch, error, quoteItem };
};

export default usePhotosQuery;
