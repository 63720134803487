import { Accordion, Tabs } from "@mantine/core";
import React, { useState } from "react";
//import { useTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import { Order } from "../../typing/gql.schema";
import ClosedOrders from "./ClosedOrders";
import OpenOrders from "./OpenOrders";

interface Props {
  orders: Array<Order>;
}

const OrdersPanel: React.FC<Props> = ({ orders }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string | null>("openOrders");

  return (
    <Accordion variant="contained" defaultValue={orders.length ? "openOrders" : ""} data-testid="open-orders">
      <Accordion.Item value="openOrders">
        <Accordion.Control>
          <strong>
            {t("ordersPanelText1")} ({orders.length})
          </strong>
        </Accordion.Control>
        <Accordion.Panel>
          <Tabs keepMounted={false} value={activeTab} onChange={setActiveTab}>
            <Tabs.List>
              <Tabs.Tab value="openOrders"> {t("ordersPanelText2")}</Tabs.Tab>
              <Tabs.Tab value="closedOrders"> {t("ordersPanelText3")}</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="openOrders" pt="xs">
              <OpenOrders orders={orders} />
            </Tabs.Panel>
            <Tabs.Panel value="closedOrders" pt="xs">
              <ClosedOrders orders={orders} />
            </Tabs.Panel>
          </Tabs>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default OrdersPanel;
