import { Button, Divider, Flex, Group, Space, Text, Title } from "@mantine/core";
import { IconDownload, IconShare } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Conditional from "../../components/Conditional";
import { RfqType } from "../../enums";
import useAuth from "../../hooks/useAuth";
import useElementHover from "../../hooks/useElementHover";
import { Estimate } from "../../typing/gql.schema";
import { formatDate } from "../../utils/utils";

type Props = {
  estimate?: Estimate;
  openShareModal: () => void;
};

const QuoteHeader: React.FC<Props> = ({ estimate, openShareModal }) => {
  const { t } = useTranslation();
  const { loggedInUser } = useAuth();
  const shareButtonHover = useElementHover();
  const downloadButtonHover = useElementHover();

  return (
    <Container>
      <Title ta="center">
        {estimate?.rfq?.cart?.name} (#{estimate?.rfq?.id}) {t("quotesText0")}
      </Title>
      <Space h={5} />
      <Divider />
      <Space h={15} />
      <Flex gap="md" justify="space-between" align="flex-start" direction="row" className="flex">
        <Text fz="xl">
          {t("quotesText1")}:&nbsp;
          <strong>{formatDate(estimate?.rfq?.deliveryDate)}</strong>
        </Text>
        <Group className="buttons">
          <Conditional renderIf={(estimate?.rfq.type === RfqType.DEFAULT || estimate?.rfq.type === RfqType.ONLINE_TYPE)}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`//api.bidscape.com/quote/excel/${estimate?.rfq?.bestQuote?.id}/${estimate?.rfq?.bestQuote?.hmac}`}
            >
              <Button leftSection={<IconDownload />} ref={downloadButtonHover.hoverRef} variant={downloadButtonHover.isHover ? "outline" : "default"}>
                <span>{t("quotesText67")}</span>
              </Button>
            </a>
          </Conditional>
          <Conditional renderIf={estimate?.editable && (estimate.rfq.cart.user.id === loggedInUser?.id || loggedInUser?.isAdmin)}>
            <Button
              ref={shareButtonHover.hoverRef}
              variant={shareButtonHover.isHover ? "outline" : "default"}
              onClick={openShareModal}
              data-testid="share-quote-btn"
              rightSection={<IconShare />}
            >
              {t("quotesText28")}
            </Button>
          </Conditional>
        </Group>
      </Flex>
      <Space h={15} />
      <Text c="red" fw={700}>
        {t("quotesText80")}
      </Text>
      <Text display="none" fw={700}>
        {t("quotesText81")}&nbsp;
        <Text component="a" c="green" href="mailto:jules@bidscape.com">
          jules@bidscape.com
        </Text>
      </Text>
      <Space h={15} />
    </Container>
  );
};

const Container = styled.div`
  .flex {
    flex-wrap: wrap;
    @media (min-width: 768px) {
      flex-wrap: nowrap;
    }
  }
  .buttons {
    @media (max-width: 768px) {
      width: 100%;
      justify-content: center;
    }
  }
`;

export default QuoteHeader;
