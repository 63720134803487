import { Estimate } from "../../typing/gql.schema";

const useEstimatePlantIds = (estimate?: Estimate) => {
  return (estimate?.estimateItems || []).reduce((acc, estimateItem) => {
    if (estimateItem?.cartItem.plant) return [...acc, estimateItem.cartItem.plant.id];
    return acc;
  }, [] as Array<string>);
};

export default useEstimatePlantIds;
