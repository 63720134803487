import { EstimateItem } from "../../typing/gql.schema";

const useIndividualSubTotal = (estimateItems: EstimateItem[]) => {
  const total = estimateItems.reduce((acc, item) => {
    if (!item.individualUnitPrice) return acc;
    return acc + item.individualUnitPrice * item.cartItem.quantity;
  }, 0);
  return total;
};

export default useIndividualSubTotal;
