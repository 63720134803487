import { useEffect } from "react";
import { trackAction } from "../../services/firebaseAnalytics";
import { Estimate } from "../../typing/gql.schema";

const useViewEstimate = (estimate?: Estimate) => {
  useEffect(() => {
    if (estimate) {
      trackAction("view_estimate", { id: estimate.id });
    }
  }, [estimate]);
};

export default useViewEstimate;
