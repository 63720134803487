import * as Sentry from "@sentry/react";
import "@mantine/carousel/styles.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "font-awesome/css/font-awesome.min.css";
import { createRoot } from "react-dom/client";
import App from "./app/App";
import "./assets/css/index.css";
import { initializeFirebaseApp } from "./services/firebaseAnalytics";
import "./services/sentry";
import "./translations/i18n";

initializeFirebaseApp();

window.addEventListener('error', (event) => {
  Sentry.captureException(event.error, {
    tags: { errorType: 'global' },
    extra: { errorMessage: event.message }
  });
});

window.addEventListener('unhandledrejection', (event) => {
  Sentry.captureException(event.reason, {
    tags: { errorType: 'unhandledPromiseRejection' },
    extra: { errorMessage: event.reason.message }
  });
});

const element = document.getElementById("root");
const root = createRoot(element!);

root.render(<App />);