import { Estimate, EstimateItem } from "../../typing/gql.schema";

const useExtractEstimateItems = (estimate?: Estimate) => {
  const estimateItems = (estimate?.estimateItems || []).reduce((acc, current) => {
    if (!current) return acc;
    if (current.cartItem.ignore) return acc;
    return [...acc, current];
  }, [] as EstimateItem[]);

  return estimateItems.sort((a, b) => a.cartItem.plant.latinName.localeCompare(b.cartItem.plant.latinName));
};

export default useExtractEstimateItems;
