import { Estimate } from "../../typing/gql.schema";

const daysAwayFromToday = (date?: string) => {
  if (!date) return 0;
  const estimateDeliveryDate = new Date(date);
  const today = new Date();
  const diff = estimateDeliveryDate.getTime() - today.getTime();
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
  return days;
};

const daysOldFromToday = (date?: string) => {
  if (!date) return 0;
  const estimateDeliveryDate = new Date(date);
  const today = new Date();
  const diff = today.getTime() - estimateDeliveryDate.getTime();
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
  return days;
};

const isEstimateDeliveryDateLessThan30DaysAway = (estimate?: Estimate) => {
  if (!estimate) return false;
  const days = daysAwayFromToday(estimate.rfq.deliveryDate);
  return days < 30;
};

const isEstimateCreatedMoreThan30DaysAgo = (estimate?: Estimate) => {
  if (!estimate) return false;
  const days = daysOldFromToday(estimate.created);
  return days > 30;
};
const useIsCreatedMoreThan30DaysAgoAndDeliveryDateLessThan30DaysAway = (estimate?: Estimate) => {
  const isCreatedMoreThan30DaysAgoAndDeliveryDateLessThan30DaysAway = (() => {
    return isEstimateDeliveryDateLessThan30DaysAway(estimate) && isEstimateCreatedMoreThan30DaysAgo(estimate);
  })();

  return isCreatedMoreThan30DaysAgoAndDeliveryDateLessThan30DaysAway;
};

export default useIsCreatedMoreThan30DaysAgoAndDeliveryDateLessThan30DaysAway;
