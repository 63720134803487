import { Text } from "@mantine/core";
import { IconCheck, IconClock, IconExclamationCircle } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { FadeTransition } from "../../components/Transitions";
import { Estimate } from "../../typing/gql.schema";
import useExtractEstimateItemAvailability from "../hooks/useExtractEstimateItemAvailability";

type Props = {
  loading?: boolean;
  estimate?: Estimate;
  estimateItemId: string;
};

const EstimateItemAvailability: React.FC<Props> = ({ loading, estimateItemId, estimate }) => {
  const { t } = useTranslation();
  const availability = useExtractEstimateItemAvailability({ estimateItemId, estimate });

  if (loading) {
    return (
      <Text fs="italic" data-testid="estimate-item-availability-loading">
        {t("estimateItemAvailabilityText1")}
      </Text>
    );
  }

  if (availability === undefined) return null;

  return (
    <FadeTransition>
      <Conditional renderIf={availability === 0}>
        <Text w="140px" c="red">
          <IconExclamationCircle size={15} /> {t("estimateItemAvailabilityText2")}
        </Text>
      </Conditional>
      <Conditional renderIf={availability === 1}>
        <Text w="140px" c="red">
          <IconExclamationCircle size={15} /> {t("estimateItemAvailabilityText2")}
        </Text>
      </Conditional>
      <Conditional renderIf={availability === 2}>
        <Text w="160px" c="yellow">
          <IconClock size={15} /> {t("estimateItemAvailabilityText3")}
        </Text>
      </Conditional>
      <Conditional renderIf={availability === 3}>
        <Text w="90px" c="green">
          <IconCheck size={15} /> {t("estimateItemAvailabilityText4")}
        </Text>
      </Conditional>
    </FadeTransition>
  );
};

export default EstimateItemAvailability;
