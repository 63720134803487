import { showNotification } from "@mantine/notifications";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useProjectCartItems from "../../hooks/useCartItems";
import { trackAction } from "../../services/firebaseAnalytics";
import { Plant, Size } from "../../typing/gql.public.schema";
import { MutationAddItemToEstimateArgs } from "../../typing/gql.schema";
import { guid } from "../../utils/utils";

type SizeArg = Size | null | undefined;
type PlantArg = Plant | null | undefined;
type OnAddItemToEstimate = (data: MutationAddItemToEstimateArgs) => Promise<void>;

const useIsAlreadyInCart = (cartItems: any, plantId: string = "", sizeId: string = "") => {
  return cartItems.some((item: any) => {
    return item.plant?.id === plantId && item.size?.id === sizeId;
  });
};

const useAddToProject = (plant: PlantArg, size: SizeArg, addItemToEstimate: OnAddItemToEstimate, price?: number) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [quantity, _setQuantity] = useState(1);
  const { cartItems, addCartItem } = useProjectCartItems();
  const isAlreadyInCart = useIsAlreadyInCart(cartItems, plant?.id, size?.id);

  const source = searchParams.get("source");
  const rfqId = searchParams.get("rfqId");
  const cartName = searchParams.get("cartName");

  const setQuantity = (value: number | string) => {
    const parsedValue = parseInt(String(value));
    if (parsedValue >= 1) _setQuantity(parsedValue);
  };

  const navigateToProject = () => {
    if (source === "estimate" && rfqId) {
      navigate(`/quotes/estimate/${rfqId}`);
    } else {
      navigate(`/get-started`);
    }
  };

  const add = async () => {
    if (!plant || !size) return;

    try {
      if (source === "estimate" && rfqId) {
        await addItemToEstimate({ rfqId, plantId: plant.id, sizeId: size.id, quantity });
      } else {
        addCartItem({ id: guid(), plant: plant, size: size, quantity });
      }

      trackAction("add_to_cart", {
        items: [
          {
            item_id: plant.id,
            item_name: plant.latinName,
            item_category: plant.category.name,
            item_category2: size.name,
            quantity,
            price,
          },
        ],
      });

      navigateToProject();
      showNotification({
        icon: "$",
        color: "teal",
        title: "Added to Project",
        message: `${quantity} ${plant.commonName} (${size.name}) added to project`,
      });
    } catch (error: any) {
      showNotification({
        icon: "$",
        color: "red",
        title: "Error",
        message: error.message,
      });
    }
  };

  const incrementQuantity = () => setQuantity(quantity + 1);
  const decrementQuantity = () => setQuantity(quantity - 1);

  return { quantity, isAlreadyInCart, incrementQuantity, add, decrementQuantity, setQuantity, navigateToProject, rfqId, source, cartName };
};

export default useAddToProject;
