import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { trackAction } from "../../services/firebaseAnalytics";
import { Mutation, MutationViewedQuoteArgs } from "../../typing/gql.schema";

export const MUTATION_VIEWED_QUOTE = gql`
  mutation viewedQuote($id: ID!) {
    viewedQuote(id: $id)
  }
`;

const useViewedQuote = (id: string = "") => {
  const { loggedInUser } = useAuth();
  const [mutate, { loading, data, error }] = useMutation<{ viewedQuote: Mutation["viewedQuote"] }, MutationViewedQuoteArgs>(MUTATION_VIEWED_QUOTE);

  const viewedQuote = async (data: MutationViewedQuoteArgs) => {
    try {
      await mutate({ variables: { ...data } });
      trackAction("view_quote", { rqf_id: id });
    } catch (error: any) {
      Sentry.captureException(error, { extra: { data, where: "useViewedQuote" } });
    }
  };

  useEffect(() => {
    if (id && !loggedInUser?.isAdmin) {
      viewedQuote({ id });
    }
  }, [id]);

  return { viewedQuote, loading, error, data };
};

export default useViewedQuote;
