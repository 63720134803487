import { Accordion, Space, Text, Title } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";

const Terms: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Accordion variant="contained">
      <Accordion.Item value="terms">
        <Accordion.Control>
          <Title order={4}>{t("termsText1")}</Title>
        </Accordion.Control>
        <Accordion.Panel>
          <Text>{t("termsText2")}</Text>
          <Space h="lg" />
          <Text>{t("termsText3")}</Text>
          <Space h="lg" />
          <Text>{t("termsText4")}</Text>
          <Space h="lg" />
          <Text>{t("termsText5")} </Text>
          <Space h="lg" />
          <Text>{t("termsText6")} </Text>
          <Space h="lg" />
          <Text>{t("termsText7")} </Text>
          <Space h="lg" />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default Terms;
