import { gql, useQuery } from "@apollo/client";
import { Query, QueryFirmMetadataArgs } from "../../typing/gql.public.schema";

export const firmMetadataGql = gql`
  query firmMetadata($id: ID!, $hmac: String!) {
    firmMetadata(id: $id, hmac: $hmac) {
      website
      type
      residential
      projectMin
      projectMax
      phone
      name
      maintenance
      id
      email
      commercial
      address
    }
  }
`;

const useFirmMetadata = (id: string = "", hmac: string = "") => {
  const { data, ...result } = useQuery<{ firmMetadata: Query["firmMetadata"] }, QueryFirmMetadataArgs>(firmMetadataGql, {
    variables: { id, hmac },
    skip: !id || !hmac,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    context: { public: true },
  });

  const firmMetadata = data?.firmMetadata ?? undefined;

  return { firmMetadata, ...result };
};

export default useFirmMetadata;
