import { Button, Divider, Space, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "../../components/CustomModal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const QuoteFinancingModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  return (
    <CustomModal centered opened={isOpen} onClose={onClose} title={t("quotesText64")}>
      <Divider />
      <Space h={15} />
      <Text data-testid="apply-financing-body">
        {t("quotesText65")}&nbsp;
        <a href="mailto:jules@bidscape.com">jules@bidscape.com</a> {t("quotesText66")}
      </Text>
      <Space h={15} />
      <Divider />
      <Space h={15} />
      <Button onClick={onClose} variant="default">
        {t("quotesText78")}
      </Button>
    </CustomModal>
  );
};

export default QuoteFinancingModal;
