import { Estimate } from "../../typing/gql.schema";

const isEstimateDiscounted = (estimate?: Estimate) => {
  if (!estimate) return false;

  if ((estimate?.estimateItems?.length === 1 && estimate?.estimateItems[0]?.cartItem.quantity === 1) || estimate?.subtotal < 2500) {
    return false;
  }

  return true;
};

export default isEstimateDiscounted;
