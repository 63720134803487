import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { trackAction } from "../../services/firebaseAnalytics";
import { Mutation, MutationAddSubRequestArgs } from "../../typing/gql.schema";

export const MUTATION_ADD_SUB_REQUEST = gql`
  mutation addSubRequest($id: ID!) {
    addSubRequest(id: $id)
  }
`;

const useAddSubRequest = () => {
  const { t } = useTranslation();
  const [mutate, { loading, data, error }] = useMutation<{ addSubRequest: Mutation["addSubRequest"] }, MutationAddSubRequestArgs>(MUTATION_ADD_SUB_REQUEST);

  const addSubRequest = async (data: MutationAddSubRequestArgs) => {
    try {
      const res = await mutate({ variables: { ...data } });
      if (!res.data?.addSubRequest) throw new Error("Error adding sub request");
      showNotification({ color: "teal", title: t("useAddSubRequestText1"), message: t("useAddSubRequestText2") });
      trackAction("add_sub_request", { cart_item_id: data.id });
    } catch (error: any) {
      showNotification({ color: "red", title: t("useAddSubRequestText3"), message: t("useAddSubRequestText4") });
      Sentry.captureException(error, { extra: { data }, tags: { where: "useAddSubRequest" } });
    }
  };

  return { addSubRequest, loading, error, data };
};

export default useAddSubRequest;
