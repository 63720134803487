import { Button, Divider, Flex, Group, Space, Text, Title } from "@mantine/core";
import { IconDownload, IconPlus, IconShare } from "@tabler/icons-react";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Conditional from "../../components/Conditional";
import { RfqStatus, RfqType } from "../../enums";
import useAuth from "../../hooks/useAuth";
import useElementHover from "../../hooks/useElementHover";
import { Estimate } from "../../typing/gql.schema";
import { formatDate } from "../../utils/utils";

type Props = {
  estimate?: Estimate;
  openShareModal: () => void;
};

const EstimateHeader: React.FC<Props> = ({ estimate, openShareModal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loggedInUser } = useAuth();
  const shareButtonHover = useElementHover();
  const downloadButtonHover = useElementHover();
  const [searchParams] = useSearchParams();

  const addPlantURL = useMemo(() => {
    return `/categories?${searchParams.toString()}`;
  }, [estimate, searchParams]);

  const onAddPlantButtonClick = useCallback(() => navigate(addPlantURL), [addPlantURL]);

  return (
    <Container>
      <Title ta="center">
        {estimate?.rfq?.cart?.name} (#{estimate?.rfq?.id}) {t("estimateText1")}
      </Title>
      <Conditional renderIf={estimate?.rfq?.status == 4}>
        <Text fs="italic">{t("estimateText2")}</Text>
      </Conditional>
      <Space h={5} />
      <Divider />
      <Space h={15} />
      <Flex gap="md" justify="space-between" align="flex-start" direction="row" className="flex">
        <Text fz="xl">
          <Conditional renderIf={estimate?.rfq.type !== RfqType.NURSERY_NET}>
            {t("estimateText64")}:&nbsp;
            <strong>{formatDate(estimate?.rfq?.deliveryDate)}</strong>
          </Conditional>
        </Text>
        <Group className="buttons">
          <Conditional renderIf={estimate?.rfq.type === RfqType.DEFAULT || estimate?.rfq?.type === RfqType.ONLINE_TYPE}>
            <a target="_blank" rel="noopener noreferrer" href={`//api.bidscape.com/estimate/excel/${estimate?.rfq?.id}/${estimate?.rfq?.hmac}`}>
              <Button leftSection={<IconDownload />} ref={downloadButtonHover.hoverRef} variant={downloadButtonHover.isHover ? "outline" : "default"}>
                <span>{t("estimateText63")}</span>
              </Button>
            </a>
          </Conditional>
          <Conditional renderIf={estimate?.editable && (estimate.rfq.cart.user.id === loggedInUser?.id || loggedInUser?.isAdmin)}>
            <Conditional renderIf={estimate?.rfq.status === RfqStatus.EstimatedRfq}>
              <Button leftSection={<IconPlus />} variant="outline" onClick={onAddPlantButtonClick}>
                {t("estimateText62")}
              </Button>
            </Conditional>
            <Button
              rightSection={<IconShare />}
              onClick={openShareModal}
              ref={shareButtonHover.hoverRef}
              data-testid="share-estimate-btn"
              variant={shareButtonHover.isHover ? "outline" : "default"}
            >
              {t("estimateText65")}
            </Button>
          </Conditional>
        </Group>
      </Flex>
      <Space h={15} />
      <Text display="none" fw={700}>
        {t("estimateText66")}&nbsp;
        <Text component="a" c="green" href="mailto:jules@bidscape.com">
          jules@bidscape.com
        </Text>
      </Text>
      <Space h={15} />
    </Container>
  );
};

const Container = styled.div`
  .flex {
    flex-wrap: wrap;
    @media (min-width: 768px) {
      flex-wrap: nowrap;
    }
  }
  .buttons {
    @media (max-width: 768px) {
      width: 100%;
      justify-content: center;
    }
  }
`;

export default EstimateHeader;
