import { Button, Divider, Group, Space, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "../../components/CustomModal";
import { EstimateItem } from "../../typing/gql.schema";
import useRemoveItem from "../hooks/useRemoveItem";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  refreshEstimate: () => void;
  activeItem?: Partial<EstimateItem>;
};

const EstimateItemRemoveModal: React.FC<Props> = ({ isOpen, onClose, activeItem, refreshEstimate }) => {
  const { t } = useTranslation();
  const { remove, loading } = useRemoveItem();

  return (
    <CustomModal size="xl" centered opened={isOpen} onClose={onClose} title={t("estimateText32")}>
      <Divider />
      <Space h={15} />
      <Text fs="italic">{t("estimateText33")}</Text>
      <Space h={15} />
      <Divider />
      <Space h={15} />
      <Group>
        <Button
          color="red"
          loading={loading}
          data-testid="confirm-remove-estimate-item-btn"
          onClick={async () => {
            await remove({ id: activeItem?.cartItem?.id! });
            onClose();
            refreshEstimate();
          }}
        >
          {t("estimateText34")}
        </Button>
        <Button variant="default" onClick={onClose}>
          {t("estimateText60")}
        </Button>
      </Group>
    </CustomModal>
  );
};

export default EstimateItemRemoveModal;
