import { useState } from "react";
import useUrlSearchKey from "../../PlantsListingBase/hooks/useUrlSearchKey";
import { Category } from "../../typing/gql.public.schema";

const usePlantEntry = () => {
  const search = useUrlSearchKey();
  const [category, setCategory] = useState<Category>();

  const backToCategories = () => {
    setCategory(undefined);
    search.onSearchKey("");
  };

  return { search, category, backToCategories, setCategory };
};

export default usePlantEntry;
