import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Estimate, MutationAddItemToEstimateArgs } from "../../typing/gql.schema";

export const addItemToEstimateGql = gql`
  mutation addItemToEstimate($rfqId: ID!, $plantId: ID!, $sizeId: ID!, $quantity: Int!) {
    addItemToEstimate(rfqId: $rfqId, plantId: $plantId, sizeId: $sizeId, quantity: $quantity) {
      id
    }
  }
`;

const useAddItemToEstimate = () => {
  const [mutate, { loading, error, data }] = useMutation<{ addItemToEstimate: Estimate }, MutationAddItemToEstimateArgs>(addItemToEstimateGql);

  const addItemToEstimate = async (data: MutationAddItemToEstimateArgs) => {
    try {
      await mutate({ variables: { ...data } });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return { loading, error, data, addItemToEstimate };
};

export default useAddItemToEstimate;
