import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const useStaticOrderPage = (deliveryDate?: string) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  // all buttons except for the ones in excludeButtons will be hidden
  const excludeButtons: Array<string> = [t("orderText15"), t("termsText1")];

  const hiddenTexts: Array<string> = [t("orderText3")];

  // all texts in showTexts will be shown, these texts are hidden by default
  const showTexts: Array<string> = [t("orderText20")];

  const handleDisableActionButtons = useCallback(() => {
    if (!ref.current) return;
    // hide all buttons except for the ones in excludeButtons
    ref.current.querySelectorAll("button").forEach((button) => {
      if (button.textContent && excludeButtons.includes(button.textContent)) return;
      button.style.display = "none";
    });

    ref.current.querySelectorAll("p").forEach((text) => {
      for (let hiddenText of hiddenTexts) {
        if (text.textContent?.includes(hiddenText)) {
          text.style.display = "none";
        }
      }
      for (let showText of showTexts) {
        if (text.textContent?.includes(showText)) {
          text.style.display = "block";
        }
      }
    });
  }, [ref, excludeButtons]);

  useEffect(() => {
    if (deliveryDate && new Date(deliveryDate) < new Date()) {
      handleDisableActionButtons();
    }
  }, [deliveryDate, handleDisableActionButtons]);

  return ref;
};

export default useStaticOrderPage;
