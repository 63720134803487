import { gql, useQuery } from "@apollo/client";
import { Query, QueryGuaranteedEstimateForRfqArgs } from "../../typing/gql.schema";

export const buyerQuotesQuery = gql`
  query guaranteedEstimateForRfq($id: ID!) {
    guaranteedEstimateForRfq(id: $id) {
      __typename
      id
      guaranteed
      subtotal
      tax
      deliveryFee
      rebate
      total
      stripeTotal
      editable
      created
      serviceFee
      rfq {
        id
        type
        status
        taxRate
        deliveryDate
        sharedWithEmails
        cart {
          id
          name
          user {
            id
          }
          cartItems {
            id
            notes
            quantity
            ignore
            plant {
              id
              latinName
              commonName
            }
            size {
              id
              name
            }
          }
        }
        bestQuote {
          id
          hmac
          quoteItems {
            id
            cartItem {
              id
            }
            yardProduct {
              id
              plant {
                id
                latinName
                commonName
              }
              size {
                id
                name
              }
              approvedPhotos {
                id
                filename
                approved
              }
            }
          }
        }
      }
      estimateItems {
        id
        total
        unitTotal
        individualUnitPrice
        cartItem {
          id
          quantity
          notes
          ignore
          photosRequested
          plant {
            id
            latinName
            commonName
          }
          size {
            id
            name
          }
          defaultPhoto {
            id
            filename
          }
        }
      }
    }
  }
`;

const useQuotesQuery = (id: string = "") => {
  const { loading, data, refetch, error } = useQuery<{ guaranteedEstimateForRfq: Query["guaranteedEstimateForRfq"] }, QueryGuaranteedEstimateForRfqArgs>(
    buyerQuotesQuery,
    {
      variables: {
        id,
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );

  const estimate = data?.guaranteedEstimateForRfq || undefined;

  return { loading, data, refetch, error, estimate };
};

export default useQuotesQuery;
