import { Accordion, Space, Text, Title } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";

const Terms: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Accordion variant="contained">
      <Accordion.Item value="terms">
        <Accordion.Control>
          <Title order={4}>
            <Text>{t("quotesTermsText1")}</Text>
          </Title>
        </Accordion.Control>
        <Accordion.Panel>
          <Text>{t("quotesTermsText2")}</Text>
          <Space h="lg" />
          <Text>{t("quotesTermsText3")}</Text>
          <Space h="lg" />
          <Text>{t("quotesTermsText4")}</Text>
          <Space h="lg" />
          <Text>{t("quotesTermsText5")} </Text>
          <Space h="lg" />
          <Text>{t("quotesTermsText6")} </Text>
          <Space h="lg" />
          <Text>{t("quotesTermsText7")} </Text>
          <Space h="lg" />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default Terms;
