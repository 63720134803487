import { Accordion, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

const LoyaltyTracker = () => {
  const { t } = useTranslation();
  return (
    <Accordion variant="contained" defaultValue={"loyaltyTracker"} data-testid="loyalty-tracker">
      <Accordion.Item value="loyaltyTracker">
        <Accordion.Control>
          <strong>{t("dashboard_loyalty_tracker_card_title")}</strong>
        </Accordion.Control>
        <Accordion.Panel>
          <Text fz={30} fw={700} ta="center">
            {1003} pts
          </Text>
          <Text fz={18} ta="center">
            (Redemption coming soon)
          </Text>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default LoyaltyTracker;
