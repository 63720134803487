import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}

const StepDescription: React.FC<Props> = (props: Props) => {
  return <Container>{props.children}</Container>;
};

const Container = styled.div`
  max-width: 200px;
`;

export default StepDescription;
