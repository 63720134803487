import { Button, Card, Text } from "@mantine/core";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { Estimate } from "../../typing/gql.schema";
import { formatCurrency } from "../../utils/utils";
import useStripeCheckout from "../hooks/useStripeCheckout";

interface CheckoutFormProps {
  estimate: Estimate;
}

const CheckoutForm = ({ estimate }: CheckoutFormProps) => {
  const { t } = useTranslation();
  const stripeCheckout = useStripeCheckout();

  return (
    <form onSubmit={stripeCheckout.handleSubmit}>
      <PaymentElement onChange={stripeCheckout.handleChange} />

      <Card mt="lg">
        <Text>
          {t("checkoutFormText1")}: <strong>{formatCurrency(estimate.subtotal)}</strong>
        </Text>
        <Text>
          {t("checkoutFormText2")}: <strong>{formatCurrency(estimate.deliveryFee)}</strong>
        </Text>
        <Text>
          {t("checkoutFormText3")}: <strong>{formatCurrency(estimate.tax)}</strong>
        </Text>
        <Conditional renderIf={estimate.serviceFee > 0}>
          <Text>
            {t("checkoutFormText4")} (5%): <strong>{formatCurrency(estimate.serviceFee)}</strong>
          </Text>
        </Conditional>
        <Conditional renderIf={estimate.stripeTotal - estimate.total > 0}>
          <Text>
            {t("checkoutFormText7")} (3%): <strong>{formatCurrency(estimate.stripeTotal - estimate.total)}</strong>
          </Text>
        </Conditional>
        <Text fw="bold">
          {t("checkoutFormText5")}: <strong>{formatCurrency(estimate.stripeTotal)}</strong>
        </Text>
      </Card>

      <Button size="lg" mt="lg" fullWidth type="submit" loading={stripeCheckout.landing} disabled={!stripeCheckout.stripe || stripeCheckout.disabled}>
        {t("checkoutFormText6")}
      </Button>
    </form>
  );
};

export default CheckoutForm;
