import { Avatar, Button, Divider, Group, Space, Stack, Text } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

type Props = {
  name: string;
};

const DashboardHeader: React.FC<Props> = ({ name }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <FlexContainer>
        <Group justify="left" className="avatar">
          <Avatar c="green" size="lg" radius="xl">
            {name?.replace(/[^a-zA-Z0-9 ]/g, "").match(/\b(\w)/g)}
          </Avatar>
          <Stack gap={2}>
            <Text>{name || "Your"} </Text>
            <Text component="span" size="xs">
              {t("dashboardText1")}
            </Text>
          </Stack>
        </Group>
        <Group className="create-request">
          <Button leftSection={<IconPlus />} size="lg" onClick={() => navigate("/new-request")}>
            {t("dashboardText2")}
          </Button>
        </Group>
      </FlexContainer>
      <Space h={15} />
      <Divider />
    </>
  );
};

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  .avatar {
    width: 50%;
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  .create-request {
    width: 50%;
    justify-content: flex-end;
    @media (max-width: 576px) {
      width: 100%;
      margin-top: 10px;
      justify-content: start;
    }
  }
`;

export default DashboardHeader;
