import { Button, Center, Group, RingProgress, Text, ThemeIcon, Title } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SlideTransition } from "../../components/Transitions";

interface Props {
  onBuyAgain?: () => void;
  transitionDirection?: "right" | "left";
}

const RequestSuccess: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <SlideTransition direction={props.transitionDirection || "right"}>
      <Container>
        <Center>
          <RingProgress
            sections={[{ value: 100, color: "green" }]}
            label={
              <Center>
                <ThemeIcon c="green" variant="light" radius="xl" size="xl">
                  <IconCheck size={22} />
                </ThemeIcon>
              </Center>
            }
          />
        </Center>
        <Center>
          <Title>{t("requestRequestSuccessText1")}</Title>
        </Center>
        <Center>
          <Text>{t("requestRequestSuccessText2")}</Text>
        </Center>
        <Group justify="center" mt="md">
          <Button data-testid="buy-again-btn" onClick={props.onBuyAgain}>
            {t("requestRequestSuccessText5")}
          </Button>
        </Group>
      </Container>
    </SlideTransition>
  );
};

const Container = styled.div`
  margin: auto !important;
  height: 70vh;
`;

RequestSuccess.defaultProps = {
  transitionDirection: "right",
};

export default RequestSuccess;
