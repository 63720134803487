import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

type Props = Record<string, string>;

const useUpdateUrlSearchParams = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const values = Object.entries(props).reduce((acc, [key, value]) => {
      if (value) acc[key] = value;
      return acc;
    }, {} as Props);

    const params = new URLSearchParams(searchParams);

    for (const key in values) {
      params.set(key, values[key]);
    }

    setSearchParams(params);
  }, []);
};

export default useUpdateUrlSearchParams;
