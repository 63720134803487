import { Alert, Button, Divider, Grid, Group, Space, Text } from "@mantine/core";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useHover } from "usehooks-ts";
import Conditional from "../../components/Conditional";
import { Order } from "../../typing/gql.schema";
import { formatDate } from "../../utils/utils";

interface Props {
  orders: Array<Order>;
}

interface OpenOrdersItemProps {
  order: Order;
  index: number;
  lastIndex: number;
}

const OpenOrdersItem: React.FC<OpenOrdersItemProps> = ({ order, lastIndex, index }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const viewButtonRef = useRef(null);
  const isViewButtonHover = useHover(viewButtonRef);

  return (
    <>
      <Grid columns={34} data-testid="order-item">
        <Grid.Col span={{ base: 34, md: 31, lg: 28 }}>
          <Text>
            {t("openOrdersText1")}
            {order?.id} <em>{order?.quote?.rfq?.cart?.name}</em> {t("openOrdersText2")}{" "}
            <Conditional renderIf={!order?.quote?.rfq?.phased}>{formatDate(order?.quote?.rfq?.deliveryDate)}</Conditional>
            <Conditional renderIf={order?.quote?.rfq?.phased}>{t("openOrdersText3")}</Conditional>
          </Text>
        </Grid.Col>
        <Grid.Col span={{ base: 34, md: 3, lg: 6 }}>
          <Group justify="center">
            <Button
              ref={viewButtonRef}
              component="a"
              href={`/orders/${order?.id}`}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/orders/${order?.id}`);
              }}
              variant={isViewButtonHover ? "outline" : "default"}
            >
              {t("openOrdersText4")}
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
      <Conditional renderIf={index !== lastIndex}>
        <Divider my="xs" />
      </Conditional>
    </>
  );
};

const OpenOrders: React.FC<Props> = ({ orders }) => {
  const { t } = useTranslation();

  return (
    <>
      <Space h={"lg"} />
      {orders.map((order, index) => (
        <OpenOrdersItem key={index} order={order} index={index} lastIndex={orders.length - 1} />
      ))}
      <Conditional renderIf={!orders.length}>
        <Alert title={t("openOrdersText5")} color="teal">
          {t("openOrdersText6")}
        </Alert>
        <Space h={"lg"} />
      </Conditional>
    </>
  );
};

export default OpenOrders;
