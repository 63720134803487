import { Button, Divider, Group, NumberInput, Space, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "../../components/CustomModal";
import { Estimate, EstimateItem } from "../../typing/gql.schema";
import useNegotiateForm from "../hooks/useNegotiateForm";
import useNegotiatePrice from "../hooks/useNegotiatePrice";
import useQuoteItemByCartItemID from "../hooks/useQuoteItemByCartItemID";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  estimate?: Estimate;
  activeItem?: Partial<EstimateItem>;
};

const QuoteNegotiateModal: React.FC<Props> = ({ isOpen, onClose, estimate, activeItem }) => {
  const { t } = useTranslation();
  const { negotiate, loading } = useNegotiatePrice(activeItem?.cartItem?.id!);
  const form = useNegotiateForm(negotiate, onClose);
  const quoteItemByCartItemID = useQuoteItemByCartItemID(estimate, activeItem?.cartItem?.id!);

  return (
    <CustomModal size="xl" centered opened={isOpen} onClose={onClose} title={t("quotesText44")}>
      <Divider />
      <Space h={15} />
      <Text>
        {t("quotesText45")}&nbsp;
        {quoteItemByCartItemID?.yardProduct?.size?.name}&nbsp;
        <em>{quoteItemByCartItemID?.yardProduct?.plant?.latinName}</em>.
      </Text>
      <Space h={15} />
      <NumberInput
        leftSection={"$"}
        min={0.01}
        step={0.01}
        name="price"
        decimalScale={2}
        aria-label="price"
        label={t("quotesText46")}
        placeholder={t("quotesText47")}
        onBlur={form.handleBlur}
        onChange={(value) => form.setFieldValue("price", value)}
        error={form.touched.price && form.errors.price ? t("quotesText48") : ""}
      />
      <Space h={15} />
      <Text fs="italic">{t("quotesText49")}</Text>
      <Space h={15} />
      <Divider />
      <Space h={15} />
      <Group>
        <Button loading={loading} data-testid="confirm-negotiate-item-btn" onClick={() => form.handleSubmit()} disabled={!form.isValid}>
          {t("quotesText50")}
        </Button>
        <Button variant="default" onClick={onClose}>
          {t("quotesText78")}
        </Button>
      </Group>
    </CustomModal>
  );
};

export default QuoteNegotiateModal;
