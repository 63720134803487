const paymentTranslations = {
  useStripeCheckoutText1: "Payment Error",
  useStripeCheckoutText2: "Something went wrong. Please try again.",
  useStripeCheckoutText3: "Payment Success",
  useStripeCheckoutText4: "Your payment was successful.",

  paymentText1: "Payment Details",
  paymentText2: "Please enter your payment details to complete your purchase.",
  paymentText3: "Payment Error",
  paymentText4: "Something went wrong while processing your payment. Please try again later.",

  checkoutFormText1: "Subtotal",
  checkoutFormText2: "Delivery charge",
  checkoutFormText3: "Tax",
  checkoutFormText4: "Service fee",
  checkoutFormText5: "Total payable",
  checkoutFormText6: "Pay",
  checkoutFormText7: "Credit card processing fee",
};

export default paymentTranslations;
