import { useRef } from "react";
import { useHover } from "usehooks-ts";

const useElementHover = () => {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);
  return { hoverRef, isHover };
};

export default useElementHover;
