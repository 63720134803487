import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { trackAction } from "../../services/firebaseAnalytics";
import { Mutation, MutationShareRfqArgs } from "../../typing/gql.schema";

/**
 * Share mutation query string
 */
export const shareRfqGQL = gql`
  mutation shareRfq($id: ID!, $email: String!) {
    shareRfq(id: $id, email: $email)
  }
`;

/**
 * Share form validator schema with yup
 */
const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email format").required("Required"),
});

/**
 * This hooks handle order sharing logic
 * Keeps form state and validation with formik
 * @param {string} orderId to share
 */
const useShareOrder = (orderId: string = "", close?: () => void) => {
  const { t } = useTranslation();
  const [mutate, { loading, data, error }] = useMutation<{ shareRfq: Mutation["shareRfq"] }, MutationShareRfqArgs>(shareRfqGQL, {
    notifyOnNetworkStatusChange: true,
  });

  const initialValues = {
    email: "",
  };

  const onSubmit = async ({ email }: typeof initialValues) => {
    try {
      const res = await mutate({ variables: { id: orderId, email } });
      if (!res.data?.shareRfq) throw new Error("Error sharing order");
      showNotification({ color: "teal", title: t("useShareOrderText1"), message: t("useShareOrderText2", { email, orderId }) });
      trackAction("share", { method: "Email", item_id: orderId, content_type: "order" });
      close && close();
    } catch (error: any) {
      showNotification({ color: "red", title: t("useShareOrderText3"), message: t("useShareOrderText4") });
      Sentry.captureException(error, { extra: { orderId, email, where: "useShareOrder" } });
    }
  };

  const form = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    validateOnMount: true,
  });

  return { form, loading, data, error };
};

export default useShareOrder;
