import { useState } from "react";

const useModals = () => {
  const [info, setInfo] = useState(false);
  const [media, setMedia] = useState(false);
  const [mediaFilename, setMediaFilename] = useState("");

  const openMedia = (filename: string = "") => {
    setMedia(true);
    setMediaFilename(filename);
  };

  const closeMedia = () => {
    setMedia(false);
    setMediaFilename("");
  };

  const openInfo = () => {
    setInfo(true);
  };

  const closeInfo = () => {
    setInfo(false);
  };

  return { info, media, mediaFilename, openInfo, closeInfo, openMedia, closeMedia };
};

export default useModals;
