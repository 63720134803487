import { Space, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { RfqType } from "../../enums";
import { Estimate } from "../../typing/gql.schema";
import { formatCurrency } from "../../utils/utils";
import useExtractEstimateItems from "../hooks/useExtractEstimateItems";
import useIndividualSubTotal from "../hooks/useIndividualSubTotal";
import useShowTotalsBundlePricing from "../hooks/useShowTotalsBundlePricing";

type Props = {
  estimate?: Estimate;
};

const EstimateTotals: React.FC<Props> = ({ estimate }) => {
  const { t } = useTranslation();
  const estimateItems = useExtractEstimateItems(estimate);
  const individualSubTotal = useIndividualSubTotal(estimateItems);
  const showBundlePricing = useShowTotalsBundlePricing(estimate!, individualSubTotal);

  const showIndividualSubTotal = (estimate?.rfq.type === RfqType.ONLINE_TYPE || estimate?.rfq.type === RfqType.DEFAULT);
  const showSubTotal = estimate?.rfq.type === RfqType.NURSERY_NET;

  return (
    <Stack gap={0}>
      <Conditional renderIf={showIndividualSubTotal}>
        <Conditional renderIf={!showBundlePricing}>
          <Text fz={20} fw={700}>
            {t("estimateText44")} {formatCurrency(estimate?.subtotal)}
          </Text>
        </Conditional>
        <Conditional renderIf={showBundlePricing}>
          <Text fz={20} fw={700} td="line-through">
            {t("estimateText48")}
            {formatCurrency(individualSubTotal)}
          </Text>
          <Text fz={20} fw={700} c="green">
            {t("estimateText49")}
            {formatCurrency(estimate?.subtotal)} ({t("estimateText50")} {formatCurrency(individualSubTotal - estimate?.subtotal!)})
          </Text>
        </Conditional>
        <Conditional renderIf={estimate?.serviceFee}>
          <Text fz={20}>
            {t("estimateText67")}: {formatCurrency(estimate?.serviceFee)}
          </Text>
        </Conditional>
        <Text fz={20}>
          {t("estimateText45")}
          <Text component="sup" size="xs" c="red">
            1
          </Text>
          : {formatCurrency(estimate?.deliveryFee)}
        </Text>
        <Text c="red" fz="xs">
          <Text component="sup" size="xs" c="red">
            1
          </Text>
          {t("estimateText47")}
        </Text>
      </Conditional>
      <Conditional renderIf={showSubTotal}>
        <Text fz={20} fw={700}>
          {t("estimateText46")} {formatCurrency(estimate?.subtotal)}
        </Text>
        <Conditional renderIf={estimate?.serviceFee}>
          <Text fz={20}>
            {t("estimateText67")}: {formatCurrency(estimate?.serviceFee)}
          </Text>
        </Conditional>
      </Conditional>
      <Space h={15} />
    </Stack>
  );
};

export default EstimateTotals;
