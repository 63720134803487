import { ActionIcon, Button, Card, CardSection, Group, Image, List, Space, Text } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { FadeTransition } from "../../components/Transitions";
import { QuoteItem, VariationData } from "../../typing/gql.schema";
import { extractFt, extractIn, formatCurrency } from "../../utils/utils";
import useForceQuoteItem from "../hooks/useForceQuoteItem";

type Props = {
  index: number;
  option: QuoteItem;
  quoteItem?: QuoteItem;
  openInfoModal: () => void;
  variations: Map<string, VariationData>;
  openMediaModal: (filename: string) => void;
  refetchQuoteItem: () => void;
  reLoadOptions: () => void;
};

const OptionItem: React.FC<Props> = ({ index, option, quoteItem, variations, openInfoModal, openMediaModal, refetchQuoteItem, reLoadOptions }) => {
  const { t } = useTranslation();
  const variation = variations.get(option?.id!);
  const forceQuoteItem = useForceQuoteItem();

  return (
    <FadeTransition>
      <Card shadow="sm" radius="sm" withBorder data-testid="option-item">
        <CardSection p="sm" withBorder>
          <strong>
            {t("photosText10")} {index + 1} - ({quoteItem?.cartItem?.quantity}) {option?.yardProduct?.size?.name} {option?.yardProduct?.plant?.commonName}){" "}
            {t("photosText11")}
            {option?.id}
          </strong>
        </CardSection>
        <CardSection p="sm">
          <Group>
            {option?.yardProduct?.approvedPhotos.map((photo, index) => (
              <Image
                key={index}
                width={150}
                height={150}
                radius="sm"
                data-testid="show-photo-btn"
                onClick={() => openMediaModal(photo?.filename!)}
                src={`//img.bidscape.com/yard-product-photos/${photo?.filename}`}
              />
            ))}
          </Group>
        </CardSection>
        <CardSection p="sm" withBorder>
          <Text fs="italic">
            {t("photosText12")} {extractFt(option?.yardProduct?.height)}'{extractIn(option?.yardProduct?.height)}"
          </Text>
          <Text fs="italic">
            {t("photosText13")} {extractFt(option?.yardProduct?.width)}'{extractIn(option?.yardProduct?.width)}"
          </Text>
          <Group gap="xs">
            <Text fw={700}>
              {t("photosText14")} {formatCurrency(variation?.adjustedUnitTotal)}{" "}
            </Text>
            <ActionIcon data-testid="show-info-btn" onClick={openInfoModal}>
              <IconInfoCircle color="#00abfb" />
            </ActionIcon>
          </Group>
          <Space h="lg" />
          <Conditional renderIf={variation?.missing?.length}>
            <List>
              {variation?.missing?.map((miss, missIndex) => (
                <List.Item key={missIndex}>
                  <Text fs="italic">
                    ({miss?.quantity}) {miss?.size?.name} {miss?.plant?.latinName} {miss?.notes} ({miss?.plant?.commonName}) {t("photosText15")}
                  </Text>
                </List.Item>
              ))}
            </List>
            <Space h="lg" />
          </Conditional>
          <Conditional renderIf={variation?.additional?.length}>
            <List>
              {variation?.additional?.map((added, addedIndex) => (
                <List.Item key={addedIndex}>
                  <Text fs="italic">
                    ({added?.quantity}) {added?.size?.name} {added?.plant?.latinName} {added?.notes} ({added?.plant?.commonName}) {t("photosText16")}
                  </Text>
                </List.Item>
              ))}
            </List>
            <Space h="lg" />
          </Conditional>
          <Conditional renderIf={variation?.subs?.length}>
            <List>
              {variation?.subs?.map((sub, subIndex) => (
                <List.Item key={subIndex}>
                  <Text fs="italic">
                    ({sub?.cartItem?.quantity}) {sub?.cartItem?.size?.name} {sub?.cartItem?.plant?.latinName} {t("photosText17")} {sub?.yardProduct?.size?.name}{" "}
                    {sub?.yardProduct?.plant?.latinName} {t("photosText18")}
                  </Text>
                </List.Item>
              ))}
            </List>
            <Space h="lg" />
          </Conditional>
          <Conditional renderIf={variation?.unsubs?.length}>
            <List>
              {variation?.unsubs?.map((unsub, unsubIndex) => (
                <List.Item key={unsubIndex}>
                  <Text fs="italic">
                    ({unsub?.cartItem?.quantity}) {unsub?.cartItem?.size?.name} {unsub?.cartItem?.plant?.latinName} {t("photosText19")}
                  </Text>
                </List.Item>
              ))}
            </List>
          </Conditional>
          <Conditional renderIf={!variation}>
            <Text>{t("photosText20")}</Text> &nbsp; {t("photosText21")}
          </Conditional>
        </CardSection>
        <CardSection p="sm">
          <Group>
            <Button
              variant="outline"
              onClick={async () => {
                await forceQuoteItem.force({ quoteId: quoteItem?.quoteId || "", quoteItemId: option.id });
                refetchQuoteItem();
                reLoadOptions();
              }}
            >
              <Text>{t("photosText22")}</Text>
            </Button>
          </Group>
        </CardSection>
      </Card>
      <Space h={20} />
    </FadeTransition>
  );
};

export default OptionItem;
