import browseTranslations from "./browse";
import categoryListingBase from "./categoryListingBase";
import contractorTranslation from "./contractors";
import dashboard from "./dashboard";
import defaultPhoto from "./defaultPhoto";
import estimate from "./estimate";
import homeTranslations from "./home";
import landing from "./landing";
import order from "./order";
import paymentTranslations from "./payment";
import photos from "./photos";
import plantListingBase from "./plantListingBase";
import quotes from "./quotes";
import publicRequest from "./requests";
import wrapperTranslations from "./wrapper";

const TRANSLATIONS_EN = {
  ...order,
  ...quotes,
  ...photos,
  ...landing,
  ...estimate,
  ...dashboard,
  ...defaultPhoto,
  ...publicRequest,
  ...wrapperTranslations,
  ...browseTranslations,
  ...plantListingBase,
  ...categoryListingBase,
  ...homeTranslations,
  ...contractorTranslation,
  ...paymentTranslations,
};

export default TRANSLATIONS_EN;
