import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { trackAction } from "../../services/firebaseAnalytics";
import { Mutation, MutationForceQuoteItemArgs } from "../../typing/gql.schema";

const MUTATION_FORCED_QUOTE_ITEM = gql`
  mutation forceQuoteItem($quoteId: ID!, $quoteItemId: ID!) {
    forceQuoteItem(quoteId: $quoteId, quoteItemId: $quoteItemId)
  }
`;

const useForceQuoteItem = () => {
  const { t } = useTranslation();
  const [mutate, { loading, data, error }] =
    useMutation<{ forceQuoteItem: Mutation["forceQuoteItem"] }, MutationForceQuoteItemArgs>(MUTATION_FORCED_QUOTE_ITEM);
  const [loadingSelectOption, setLoadingSelectOption] = useState("");

  const force = async (data: MutationForceQuoteItemArgs) => {
    try {
      setLoadingSelectOption(data.quoteItemId);
      const result = await mutate({ variables: { ...data } });
      if (!result.data?.forceQuoteItem) throw new Error("Error force quoting item");
      trackAction("force_quote_item", { item_id: data.quoteItemId });
      showNotification({ color: "green", title: t("useForceQuoteItemText1"), message: t("useForceQuoteItemText2") });
    } catch (error: any) {
      showNotification({ color: "red", title: t("useForceQuoteItemText3"), message: t("useForceQuoteItemText4") });
      Sentry.captureException(error, { extra: data });
    } finally {
      setLoadingSelectOption("");
    }
  };

  return { force, loading, error, data, loadingSelectOption };
};

export default useForceQuoteItem;
