import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";
import { User } from "../../../typing/gql.schema";
import useUserQuery from "./useUserQuery";

interface TextMatch {
  text: string;
  preText: string;
}

interface TextEntry {
  id: string;
  text: string;
}

interface UseSwitchUser {
  close: () => void;
  users: Array<User>;
}

const getPreText = (text: string, search: string): string => {
  if (text.toLowerCase().indexOf(search.toLowerCase()) === -1) {
    return text;
  }
  return text.slice(0, text.toLowerCase().indexOf(search.toLowerCase()));
};

const matchedText = (text: string, search: string): string => {
  return text.slice(text.toLowerCase().indexOf(search.toLowerCase()), text.toLowerCase().indexOf(search.toLowerCase()) + search.length);
};

const getPostText = (text: string, search: string): string => {
  if (text.toLowerCase().indexOf(search.toLowerCase()) === -1) {
    return "";
  }
  return text.slice(text.toLowerCase().indexOf(search.toLowerCase()) + search.length);
};

const getMatches = (text: string, search: string) => {
  let remaining = text;
  let terms = search.split(" ");
  let retVal: TextMatch[] = new Array();

  for (let term of terms) {
    const preText = getPreText(remaining, term);
    const text = matchedText(remaining, term);
    remaining = getPostText(remaining, term);
    retVal.push({ text: text.split(" ").join(`\u00A0`), preText: preText.split(" ").join(`\u00A0`) });
  }
  retVal.push({ text: "", preText: remaining.split(" ").join(`\u00A0`) });
  return retVal;
};

const useSwitchUser = (props: UseSwitchUser) => {
  const { users, close } = props;
  const { update } = useAuth();
  const { t } = useTranslation();
  const { query } = useUserQuery();

  const onUserSelect = async (id: string) => {
    try {
      const result = await query({ variables: { id } });
      if (!result.data?.user) throw new Error("User not found");
      update({ activeUser: result.data.user });
      showNotification({ color: "green", title: t("useSwitchUserText1"), message: `${result.data.user.email} ${t("useSwitchUserText2")}` });
      close();
    } catch (error: any) {
      showNotification({ color: "red", title: t("useSwitchUserText3"), message: t("useSwitchUserText4") });
      close();
      Sentry.captureException(error, { extra: { id, where: "useSwitchUser" } });
    }
  };

  const entries: Array<TextEntry> = users.reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, { id: current.id, text: current.email }];
  }, [] as Array<TextEntry>);

  return { entries, getMatches, onUserSelect };
};

export default useSwitchUser;
