import { gql, useQuery } from "@apollo/client";
import { Query, User } from "../../../typing/gql.schema";

export const userListQuery = gql`
  query usersQuery($search: String!, $orderBy: String!, $limit: Int!, $page: Int!) {
    users(search: $search, orderBy: $orderBy, limit: $limit, page: $page) {
      __typename
      id
      name
      email
      phone
      hasWholesaleLicense
      created
      modified
    }
  }
`;

const useUsersQuery = (search: string) => {
  const { loading, error, data, refetch } = useQuery<{ users: Query["users"] }>(userListQuery, {
    variables: {
      search,
      page: 1,
      limit: 5,
      orderBy: "",
    },
    skip: !search,
    fetchPolicy: "network-only",
  });

  const users = (data?.users || []).reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, current];
  }, [] as Array<User>);

  return { loading, error, data, users, refetch };
};

export default useUsersQuery;
