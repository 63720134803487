import { gql, useLazyQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import { authenticationActions } from "../redux/authentication/slice";
import { setGaUserId } from "../services/firebaseAnalytics";
import { IAuthentication, IReduxSate } from "../typing/declarations";
import { Query } from "../typing/gql.schema";
import { setToken } from "../utils/utils";

export const meQuery = gql`
  query me {
    me {
      id
      email
      name
      isAdmin
      __typename
    }
  }
`;
const selectAuthentication = (state: IReduxSate) => {
  return state.authentication;
};

const useAuth = () => {
  const dispatch = useDispatch();
  const authentication = useSelector(selectAuthentication);
  const [query] = useLazyQuery<{ me: Query["me"] }>(meQuery, {
    fetchPolicy: "network-only",
  });

  const update = 
    (data: Partial<IAuthentication>) => {
      dispatch(authenticationActions.update(data));
    }

  const setLoginToken = async (token: string) => {
      try {
        setToken(token);
        const result = await query();
        update({ activeUser: undefined, loggedInUser: result.data?.me, isLoggedIn: true, expiredToken: false });
        setGaUserId(result.data?.me?.id);
      } catch (error: any) {
        console.log(error);
        Sentry.captureException(error);
      }
    }

  const logout = async () => {
    try {
      localStorage.clear();
      update({ activeUser: undefined, loggedInUser: undefined, isLoggedIn: false, expiredToken: false });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }

  const activeUser = authentication.activeUser || authentication.loggedInUser;

  return { ...authentication, update, logout, setLoginToken, activeUser };
};

export default useAuth;
