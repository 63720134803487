import { Button, Group, Space } from "@mantine/core";
import { IconArrowLeft, IconDownload, IconShare } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Conditional from "../../components/Conditional";
import { RfqType } from "../../enums";
import useAuth from "../../hooks/useAuth";
import useElementHover from "../../hooks/useElementHover";
import { Estimate } from "../../typing/gql.schema";

type Props = {
  estimate?: Estimate;
  openShareModal: () => void;
};

const EstimateFooter: React.FC<Props> = ({ estimate, openShareModal }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loggedInUser } = useAuth();
  const backButtonHover = useElementHover();
  const shareButtonHover = useElementHover();
  const downloadButtonHover = useElementHover();

  return (
    <>
      <Space h={50} />
      <Group justify="center">
        <Button
          size="lg"
          leftSection={<IconArrowLeft />}
          onClick={() => navigate("/dashboard")}
          ref={backButtonHover.hoverRef}
          variant={backButtonHover.isHover ? "outline" : "default"}
        >
          {t("estimateText21")}
        </Button>
        <Conditional renderIf={(estimate?.rfq.type === RfqType.DEFAULT || estimate?.rfq.type === RfqType.ONLINE_TYPE)}>
          <a target="_blank" rel="noopener noreferrer" href={`//api.bidscape.com/estimate/excel/${estimate?.rfq?.id}/${estimate?.rfq?.hmac}`}>
            <Button size="lg" leftSection={<IconDownload />} ref={downloadButtonHover.hoverRef} variant={downloadButtonHover.isHover ? "outline" : "default"}>
              {t("quotesText67")}
            </Button>
          </a>
        </Conditional>
        <Conditional renderIf={estimate?.editable && (estimate.rfq.cart.user.id === loggedInUser?.id || loggedInUser?.isAdmin)}>
          <Button
            size="lg"
            onClick={openShareModal}
            rightSection={<IconShare />}
            data-testid="share-estimate-btn"
            ref={shareButtonHover.hoverRef}
            variant={shareButtonHover.isHover ? "outline" : "default"}
          >
            {t("estimateText22")}
          </Button>
        </Conditional>
      </Group>
      <Space h={50} />
    </>
  );
};

export default EstimateFooter;
