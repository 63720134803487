import { Container, Divider, Grid, Space, Text, Title } from "@mantine/core";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import CommonPairingsForPlant from "../PlantsListingBase/CommonPairingsForPlants";
import { usePrerenderReady } from "../PlantsListingBase/hooks/usePrerenderReady";
import Conditional from "../components/Conditional";
import Loader from "../components/Loader";
import AddToProject from "./components/AddToProject";
import LocationInformation from "./components/Location";
import PlantDescription from "./components/PlantDescription";
import PlantQueryError from "./components/PlantDetailsQueryError";
import PlantSEO from "./components/PlantSEO";
import PlantSizePhotos from "./components/PlantSizePhotos";
import Sizes from "./components/PlantSizes";
import usePlantQuery from "./hooks/usePlantQuery";
import usePlantSizeCombinationDetails from "./hooks/usePlantSizeCombinationDetails";
import useSelectSize from "./hooks/useSelectSize";

type Props = {};

type Params = {
  plantId: string;
  locationId: string;
};

const PlantDetails: React.FC<Props> = () => {
  const { t } = useTranslation();
  const params = useParams<Params>();
  const plantQuery = usePlantQuery(params.plantId);
  const selectSize = useSelectSize(plantQuery.size);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const plantSizeCombinationDetailsQuery = usePlantSizeCombinationDetails(params.plantId, selectSize.selectedSize?.id);

  const prerenderReady = usePrerenderReady();

  useEffect(() => {
    if (!selectSize) return;
    if (plantQuery.loading) return;
    if (plantSizeCombinationDetailsQuery.loading) return;
    prerenderReady.onPlantDetailsQueryDone();
  }, [prerenderReady.onPlantDetailsQueryDone, plantQuery.loading, selectSize.selectedSize, plantSizeCombinationDetailsQuery.loading]);

  if (plantQuery.loading) return <Loader fullPage={true} />;
  if (plantQuery.error) return <PlantQueryError error={plantQuery.error} refetch={plantQuery.refetch} />;

  return (
    <Container size={"md"}>
      <Conditional renderIf={!isMobile}>
        <Grid>
          <Grid.Col span={{ sm: 6 }}>
            <PlantSizePhotos
              photos={plantSizeCombinationDetailsQuery.photos}
              loading={plantSizeCombinationDetailsQuery.loading || !selectSize.selectedSize}
              alt={`Photo for ${plantQuery.plant?.latinName} (${plantQuery.plant?.commonName})`}
            />
          </Grid.Col>
          <Grid.Col span={{ sm: 6 }}>
            <Title>
              {plantQuery.plant?.latinName} - {plantQuery.plant?.commonName}
            </Title>
            <Space h={"xs"} />
            <Divider label="Which size?" />
            <Space h={"sm"} />
            <Sizes plantId={params.plantId} selectedSize={selectSize.selectedSize} onSelectSize={selectSize.setSelectedSize} />
            <Space h={"sm"} />
            <Divider variant="dotted" label="How many?" />
            <Space h={"xs"} />
            <AddToProject
              plant={plantQuery.plant}
              size={selectSize.selectedSize}
              lowPriceForPlantAndSize={plantSizeCombinationDetailsQuery.lowPriceForPlantAndSize}
              highPriceForPlantAndSize={plantSizeCombinationDetailsQuery.highPriceForPlantAndSize}
            />
            <Space h={"md"} />
            <Divider />
            <PlantDescription plant={plantQuery.plant} />
            <Space h={"sm"} />
            <Conditional renderIf={params.locationId}>
              <Divider />
              <Space h={"sm"} />
              <LocationInformation locationId={params.locationId!} />
              <Space h={"sm"} />
            </Conditional>
          </Grid.Col>
        </Grid>
      </Conditional>
      <Conditional renderIf={isMobile}>
        <Text lh={1} fz={30} fw="bold">
          {plantQuery.plant?.latinName} - {plantQuery.plant?.commonName}
        </Text>
        <Space h={"sm"} />
        <PlantSizePhotos
          photos={plantSizeCombinationDetailsQuery.photos}
          loading={plantSizeCombinationDetailsQuery.loading || !selectSize.selectedSize}
          alt={`Photo for ${plantQuery.plant?.latinName} (${plantQuery.plant?.commonName})`}
        />
        <Divider label="Which size?" />
        <Space h={"sm"} />
        <Sizes plantId={params.plantId} selectedSize={selectSize.selectedSize} onSelectSize={selectSize.setSelectedSize} />
        <Space h={"sm"} />
        <Divider variant="dotted" label="How many?" />
        <Space h={"xs"} />
        <AddToProject
          plant={plantQuery.plant}
          size={selectSize.selectedSize}
          lowPriceForPlantAndSize={plantSizeCombinationDetailsQuery.lowPriceForPlantAndSize}
          highPriceForPlantAndSize={plantSizeCombinationDetailsQuery.highPriceForPlantAndSize}
        />
        <Space h={"md"} />
        <Divider />
        <PlantDescription plant={plantQuery.plant} />
        <Conditional renderIf={params.locationId}>
          <Divider />
          <Space h={"sm"} />
          <LocationInformation locationId={params.locationId!} />
          <Space h={"sm"} />
        </Conditional>
      </Conditional>
      <Space h={"md"} />
      <Divider />
      <CommonPairingsForPlant
        limit={8}
        plantIds={[params.plantId!]}
        onDataLoadCompleted={prerenderReady.onCommonPairingsForPlantsQueryDone}
        title={`${t("plantDetailsText1")} ${plantQuery.plant?.latinName} (${plantQuery.plant?.commonName})`}
      />
      <PlantSEO
        plant={plantQuery.plant}
        lowPriceForPlantAndSize={plantSizeCombinationDetailsQuery.lowPriceForPlantAndSize}
        highPriceForPlantAndSize={plantSizeCombinationDetailsQuery.highPriceForPlantAndSize}
      />
    </Container>
  );
};

export default PlantDetails;
