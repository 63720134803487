import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Query, QueryOrderArgs } from "../../typing/gql.schema";

/**
 * Order query string
 */
export const orderGQL = gql`
  query order($id: ID!) {
    order(id: $id) {
      __typename
      id
      quote {
        rfq {
          id
          status
          taxRate
          deliveryDate
          sharedWithEmails
          phased
          cart {
            id
            name
            user {
              id
            }
          }
        }
        estimate {
          id
          subtotal
          total
          tax
          deliveryFee
          estimateItems {
            id
            unitTotal
            total
            cartItem {
              id
              quantity
              notes
              ignore
              plant {
                id
                latinName
                commonName
              }
              size {
                id
                name
              }
            }
          }
        }
        quoteItems {
          id
          yardProduct {
            id
            yard {
              id
            }
            approvedPhotos {
              id
              approved
              filename
            }
            size {
              id
              name
            }
            plant {
              id
              latinName
              commonName
            }
          }
          cartItem {
            id
          }
        }
        suppliers {
          id
        }
      }
    }
  }
`;

/**
 * This hook handle order query
 * @param {string} id of order to query
 */
const useOrderQuery = (id: string) => {
  const { loading, data, refetch, error } = useQuery<{ order: Query["order"] }, QueryOrderArgs>(orderGQL, {
    variables: {
      id,
    },
    notifyOnNetworkStatusChange: true,
  });

  const order = data?.order ? data.order : undefined;

  return { loading, data, refetch, error, order };
};

export default useOrderQuery;
