import { Button, Group } from "@mantine/core";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { RfqType } from "../../enums";
import { Estimate } from "../../typing/gql.schema";
import useCheckAvailability from "../hooks/useCheckAvailability";
import useIsEstimateDeliveryDate45DaysOrLessAway from "../hooks/useIsEstimateDeliveryDate45DaysOrLessAway";
import useIsEstimateDeliveryDateGreater45DaysAway from "../hooks/useIsEstimateDeliveryDateGreater45DaysAway";

type Props = {
  estimate?: Estimate;
  openContractAndHoldModal: () => void;
  openReadyToByDisabledModal: () => void;
};

const EstimateActions: React.FC<Props> = ({ estimate, openContractAndHoldModal, openReadyToByDisabledModal }) => {
  const { t } = useTranslation();
  const checkAvailability = useCheckAvailability(estimate);
  const readyToBuy = useIsEstimateDeliveryDate45DaysOrLessAway(estimate);
  const showContractAndHoldButton = useIsEstimateDeliveryDateGreater45DaysAway(estimate);

  const showReadyToBuyButton = useMemo(() => {
    return (readyToBuy && (estimate?.rfq.type === RfqType.ONLINE_TYPE || estimate?.rfq.type === RfqType.DEFAULT)) || estimate?.rfq.type === RfqType.NURSERY_NET;
  }, [estimate, readyToBuy]);

  return (
    <Group justify="center">
      <Conditional renderIf={showContractAndHoldButton && (estimate?.rfq.type === RfqType.DEFAULT || estimate?.rfq.type === RfqType.ONLINE_TYPE)}>
        <Button onClick={openContractAndHoldModal} color="green" size="lg">
          {t("estimateText35")}
        </Button>
      </Conditional>
      <Conditional renderIf={showReadyToBuyButton}>
        <Button size="lg" color="green" loading={checkAvailability.loading} onClick={checkAvailability.check}>
          {t("estimateText20")}
        </Button>
      </Conditional>
      <Conditional renderIf={(estimate?.rfq.type === RfqType.DEFAULT || estimate?.rfq.type === RfqType.ONLINE_TYPE) && !readyToBuy}>
        <Button onClick={openReadyToByDisabledModal} variant="default" size="lg">
          {t("estimateText20")}
        </Button>
      </Conditional>
    </Group>
  );
};

export default EstimateActions;
