import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { trackAction } from "../../services/firebaseAnalytics";
import { Mutation, MutationAddPhotoRequestArgs } from "../../typing/gql.schema";

export const MUTATION_ADD_PHOTO_REQUEST = gql`
  mutation addPhotoRequest($rfqID: ID!, $cartItemID: ID!) {
    addPhotoRequest(rfqID: $rfqID, cartItemID: $cartItemID)
  }
`;

const useAddPhotoRequest = () => {
  const { t } = useTranslation();
  const [mutate, { loading, data, error }] =
    useMutation<{ addPhotoRequest: Mutation["addPhotoRequest"] }, MutationAddPhotoRequestArgs>(MUTATION_ADD_PHOTO_REQUEST);

  const addPhotoRequest = async (data: MutationAddPhotoRequestArgs) => {
    try {
      const res = await mutate({ variables: { ...data } });
      if (!res.data?.addPhotoRequest) throw new Error("Error adding photo request");
      showNotification({ color: "teal", title: t("useAddPhotoRequestText1"), message: t("useAddPhotoRequestText2") });
      trackAction("add_photo_request", { cart_item_id: data.cartItemID, rfq_id: data.rfqID });
    } catch (error: any) {
      showNotification({ color: "red", title: t("useAddPhotoRequestText3"), message: t("useAddPhotoRequestText4") });
      Sentry.captureException(error, { extra: { data, where: "useAddPhotoRequest" } });
    }
  };
  return { addPhotoRequest, loading, error, data };
};

export default useAddPhotoRequest;
