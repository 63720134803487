import { gql, useQuery } from "@apollo/client";
import { Photo, Query, QueryPhotosForPlantAndSizeArgs } from "../../typing/gql.public.schema";

interface PlantSizeCombinationDetails {
  photosForPlantAndSize: Query["photosForPlantAndSize"];
  lowPriceForPlantAndSize: Query["lowPriceForPlantAndSize"];
  highPriceForPlantAndSize: Query["highPriceForPlantAndSize"];
}

export const plantSizeCombinationDetailsGQL = gql`
  query plantSizeDetails($plantId: ID!, $sizeId: ID!) {
    lowPriceForPlantAndSize(plantId: $plantId, sizeId: $sizeId)
    highPriceForPlantAndSize(plantId: $plantId, sizeId: $sizeId)
    photosForPlantAndSize(plantId: $plantId, sizeId: $sizeId) {
      id
      filename
      __typename
    }
  }
`;

const usePlantSizeCombinationDetails = (plantId: string = "", sizeId: string = "") => {
  const { data, ...result } = useQuery<PlantSizeCombinationDetails, QueryPhotosForPlantAndSizeArgs>(plantSizeCombinationDetailsGQL, {
    context: { public: true },
    skip: !sizeId || !plantId,
    variables: { sizeId, plantId },
  });

  const photos = (data?.photosForPlantAndSize ?? [])
    .reduce((acc, current) => {
      if (current) return [...acc, current];
      return acc;
    }, [] as Array<Photo>)
    .slice(0, 6);

  let lowPriceForPlantAndSize = data?.lowPriceForPlantAndSize && data?.lowPriceForPlantAndSize > 0 ? data?.lowPriceForPlantAndSize : 0;
  let highPriceForPlantAndSize = data?.highPriceForPlantAndSize && data?.highPriceForPlantAndSize > 0 ? data?.highPriceForPlantAndSize : 0;

  return { ...result, data, photos, lowPriceForPlantAndSize, highPriceForPlantAndSize };
};

export default usePlantSizeCombinationDetails;
