import { MantineThemeOverride } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { settingsActions } from "../redux/settings/slice";
import { IReduxSate } from "../typing/declarations";

interface Themes {
  dark: MantineThemeOverride;
  light: MantineThemeOverride;
}

const themes: Themes = {
  light: {
    primaryColor: "green",
    colors: {
      dark: ["#C1C2C5", "#A6A7AB", "#909296", "#5c5f66", "#373A40", "#2C2E33", "#25262b", "#1A1B1E", "#141517", "#101113"],
    },
  },
  dark: {
    primaryColor: "green",
    colors: {
      dark: ["#C1C2C5", "#A6A7AB", "#909296", "#5c5f66", "#373A40", "#2C2E33", "#25262b", "#1A1B1E", "#141517", "#101113"],
    },
  },
};

type ColorScheme = "light" | "dark" | undefined;

const selectSettings = (state: IReduxSate) => {
  return state.settings;
};

const useAppSettings = () => {
  const dispatch = useDispatch();
  const settings = useSelector(selectSettings);

  const toggleTheme = () => {
    if (settings.darkMode) {
      dispatch(settingsActions.update({ ...settings, darkMode: false }));
    } else {
      dispatch(settingsActions.update({ ...settings, darkMode: true }));
    }
  };

  const prerender = navigator.userAgent.toLowerCase().includes("prerender");
  const themeObject = prerender ? themes.light : settings.darkMode ? themes.dark : themes.light;
  const colorScheme: ColorScheme = prerender ? "light" : settings.darkMode ? "dark" : "light";

  return { ...settings, toggleTheme, themeObject, colorScheme };
};

export default useAppSettings;
