import { Button, Group, NumberInput, Space, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { Plant, Size } from "../../typing/gql.public.schema";
import { formatCurrency } from "../../utils/utils";
import useAddItemToEstimate from "../hooks/useAddItemToEstimate";
import useAddToProject from "../hooks/useAddToProject";
import classes from "../styles/AddToProject.module.css";

type Props = {
  size?: Size | null;
  plant?: Plant | null;
  lowPriceForPlantAndSize?: number;
  highPriceForPlantAndSize?: number;
};

const AddToProject: React.FC<Props> = ({ plant, size, lowPriceForPlantAndSize, highPriceForPlantAndSize }) => {
  const { t } = useTranslation();
  const addItemToEstimate = useAddItemToEstimate();
  const addToProject = useAddToProject(plant, size, addItemToEstimate.addItemToEstimate, lowPriceForPlantAndSize);
  return (
    <>
      <Conditional renderIf={lowPriceForPlantAndSize && highPriceForPlantAndSize}>
        <Text size="lg" c="green" td="italic" ta="center">
          Market price from {formatCurrency(lowPriceForPlantAndSize)} to {formatCurrency(highPriceForPlantAndSize)}
        </Text>
      </Conditional>
      <Conditional renderIf={!lowPriceForPlantAndSize || !highPriceForPlantAndSize}>
        <Text size="lg" c="green" fs="italic" ta="center">
          Add item to quote to see pricing
        </Text>
      </Conditional>
      <Space h={"sm"} />
      <Group justify="center">
        <Button disabled={addToProject.quantity === 1} className={classes.decrease} variant="default" onClick={addToProject.decrementQuantity}>
          -
        </Button>
        <NumberInput
          width={10}
          min={1}
          hideControls
          defaultValue={1}
          value={addToProject.quantity}
          className={classes.quantityInput}
          onChange={addToProject.setQuantity}
        />
        <Button className={classes.increase} variant="default" onClick={addToProject.incrementQuantity}>
          +
        </Button>
      </Group>
      <Space h={"md"} />
      <Group justify="center">
        <Button size="lg" onClick={addToProject.add} loading={addItemToEstimate.loading}>
          <Conditional renderIf={addToProject.cartName}>
            {t("plantDetailsAddToProjectText1")}&nbsp;({addToProject.cartName})
          </Conditional>
          <Conditional renderIf={!addToProject.cartName}>{t("plantDetailsAddToProjectText4")}</Conditional>
        </Button>
      </Group>
      <Conditional renderIf={addToProject.isAlreadyInCart}>
        <Space h={"sm"} />
        <Text ta="center" c="red">
          {t("plantDetailsAddToProjectText2")} &nbsp;
          <Text c="green" className="view-project" component="a" onClick={addToProject.navigateToProject}>
            {t("plantDetailsAddToProjectText3")}
          </Text>
        </Text>
      </Conditional>
    </>
  );
};

export default AddToProject;
