import { createSlice } from "@reduxjs/toolkit";
import { IAuthentication, IAuthenticationAction } from "../../typing/declarations";

const initialState: IAuthentication = { loggedInUser: undefined, activeUser: undefined, isLoggedIn: false, expiredToken: false };

export const slice = createSlice({
  initialState,
  name: "authentication",
  reducers: {
    update: (state: IAuthentication, action: IAuthenticationAction) => {
      return { ...state, ...action.payload };
    },
  },
});

export default slice.reducer;
// Action creators are generated for each case reducer function
export const authenticationActions = slice.actions;
