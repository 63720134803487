import { Button, Card, Checkbox, Container, Group, NumberInput, Space, Text, TextInput, Textarea, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Conditional from "../components/Conditional";
import Loader from "../components/Loader";
import SuccessfullySubmitted from "./components/SuccessfullySubmitted";
import useContractorForm from "./hooks/useContractorForm";
import useFirmMetadata from "./hooks/useFirmMetadata";
import useSaveFirmMetadata from "./hooks/useSaveFirmMetadata";

const ContractorListing: React.FC = () => {
  const { t } = useTranslation();
  const { id, hmac } = useParams();
  const { firmMetadata, loading } = useFirmMetadata(id, hmac);
  const form = useContractorForm(firmMetadata);
  const saveFirmMetadata = useSaveFirmMetadata();

  if (loading) return <Loader fullPage />;
  if (saveFirmMetadata.data?.saveFirmMetadata) return <SuccessfullySubmitted />;

  return (
    <Container size="md">
      <Title mb="xl" ta="center">
        {t("contractorListingText1")}
      </Title>
      <Card>
        <Text fw="bold">
          {t("contractorListingText2")}&nbsp;
          <Conditional renderIf={firmMetadata?.name}>({firmMetadata?.name})</Conditional>
        </Text>
        <Text>{t("contractorListingText3")}&nbsp;</Text>
        <Space h="md" />

        <TextInput
          mt="sm"
          required
          name="name"
          onBlur={form.handleBlur}
          onChange={form.handleChange}
          defaultValue={form.values.name}
          label={t("contractorListingText4")}
          error={form.touched.name && form.errors.name ? t("contractorListingText5") : ""}
        />

        <Group>
          <TextInput
            w="40%"
            mt="sm"
            required
            name="email"
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            defaultValue={form.values.email}
            label={t("contractorListingText6")}
            error={form.touched.email && form.errors.email ? t("contractorListingText7") : ""}
          />
          <TextInput
            w="40%"
            mt="sm"
            required
            name="phone"
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            defaultValue={form.values.phone}
            label={t("contractorListingText8")}
            error={form.touched.phone && form.errors.phone ? t("contractorListingText9") : ""}
          />
        </Group>
        <TextInput
          mt="sm"
          name="website"
          onBlur={form.handleBlur}
          onChange={form.handleChange}
          defaultValue={form.values.website}
          label={t("contractorListingText10")}
        />
        <Textarea
          mt="sm"
          autosize
          required
          minRows={3}
          name="address"
          onBlur={form.handleBlur}
          onChange={form.handleChange}
          defaultValue={form.values.address}
          label={t("contractorListingText11")}
          error={form.touched.address && form.errors.address ? t("contractorListingText12") : ""}
        />
      </Card>

      <Space h="xl" />
      <Card>
        <Text fw="bold">{t("contractorListingText13")}</Text>
        <Text>{t("contractorListingText14")}</Text>
        <Space h="lg" />

        <Group>
          <Checkbox
            checked={form.values.residential}
            onChange={(event) => form.setFieldValue("residential", event.currentTarget.checked)}
            label={t("contractorListingText15")}
          />
          <Checkbox
            checked={form.values.commercial}
            onChange={(event) => form.setFieldValue("commercial", event.currentTarget.checked)}
            label={t("contractorListingText16")}
          />
          <Checkbox
            checked={form.values.maintenance}
            onChange={(event) => form.setFieldValue("maintenance", event.currentTarget.checked)}
            label={t("contractorListingText17")}
          />
        </Group>
        <Conditional renderIf={form.touched && !form.selectedServiceProvided}>
          <Text mt="lg" c="red">
            {t("contractorListingText18")}
          </Text>
        </Conditional>
      </Card>
      <Space h="xl" />

      <Card>
        <Text fw="bold">{t("contractorListingText19")}</Text>
        <Text>{t("contractorListingText20")}</Text>

        <Group>
          <NumberInput
            w="40%"
            mt="sm"
            required
            leftSection="$"
            name="projectMin"
            onBlur={form.handleBlur}
            value={form.values.projectMin}
            label={t("contractorListingText21")}
            onChange={(value) => form.setFieldValue("projectMin", value)}
            error={form.touched.projectMin && form.errors.projectMin ? t("contractorListingText22") : ""}
          />
          <NumberInput
            required
            name="projectMax"
            onBlur={form.handleBlur}
            value={form.values.projectMax}
            label={t("contractorListingText23")}
            onChange={(value) => form.setFieldValue("projectMax", value)}
            error={form.touched.projectMax && form.errors.projectMax ? t("contractorListingText24") : ""}
            leftSection="$"
            w="40%"
            mt="sm"
          />
        </Group>
      </Card>
      <Space h="xl" />

      <Group justify="center">
        <Button
          disabled={!form.isFormValid}
          onClick={() => {
            saveFirmMetadata.saveFirmMetadata(hmac!, { id: id!, type: 0, ...form.values });
          }}
        >
          {t("contractorListingText25")}
        </Button>
      </Group>

      <Space h="xl" />
    </Container>
  );
};

export default ContractorListing;
