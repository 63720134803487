import { Button, Divider, Space, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import CustomModal from "../../components/CustomModal";
import { Estimate } from "../../typing/gql.schema";
import { formatDate } from "../../utils/utils";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  estimate?: Estimate;
};

const EstimateReadyToBuyDisabledModal: React.FC<Props> = ({ isOpen, onClose, estimate }) => {
  const { t } = useTranslation();
  return (
    <CustomModal size="xl" centered opened={isOpen} onClose={onClose} title={t("estimateText38")}>
      <Divider />
      <Space h={15} />
      <Text data-testid="ready-to-buy-disabled">
        {t("estimateText39", { deliveryDate: formatDate(estimate?.rfq?.deliveryDate) })}
        <a href="mailto:orders@bidscape.com"> orders@bidscape.com </a>
        {t("estimateText40")}
      </Text>
      <Space h={15} />
      <Divider />
      <Space h={15} />
      <Button variant="default" onClick={onClose}>
        {t("estimateText60")}
      </Button>
    </CustomModal>
  );
};

export default EstimateReadyToBuyDisabledModal;
