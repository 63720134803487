import { Button, Card, CardSection, Group, Image, Text } from "@mantine/core";
import { IconPencil } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Conditional from "../../components/Conditional";
import { FadeTransition } from "../../components/Transitions";
import useAuth from "../../hooks/useAuth";
import { EstimateItem } from "../../typing/gql.schema";

type Props = {
  estimateItem: EstimateItem;
};

const CurrentPhoto: React.FC<Props> = ({ estimateItem }) => {
  const auth = useAuth();
  const { t } = useTranslation();

  return (
    <FadeTransition>
      <Styled>
        <Card withBorder shadow="xs" radius="sm">
          <CardSection withBorder inheritPadding py="xs" className="header">
            ({estimateItem?.cartItem?.quantity}) {estimateItem?.cartItem.size?.name} {estimateItem?.cartItem?.plant?.latinName} (
            {estimateItem?.cartItem?.plant?.commonName})
          </CardSection>
          <CardSection withBorder inheritPadding py="xs">
            <Image src={`//img.bidscape.com/yard-product-photos/${estimateItem?.cartItem?.defaultPhoto?.filename}`} />
          </CardSection>
          <CardSection withBorder inheritPadding py="xs">
            <Group justify="center" gap={5}>
              <Text>{t("defaultPhotoText6")} </Text>
              &nbsp;&nbsp;
              <Conditional renderIf={auth.loggedInUser?.isAdmin}>
                <Button
                  component="a"
                  target="_blank"
                  variant="default"
                  rel="noopener noreferrer"
                  href={`//api.bidscape.com/manage-yard-product-photo/${estimateItem?.cartItem?.defaultPhoto?.yardProduct?.id}`}
                  leftSection={<IconPencil />}
                >
                  {t("defaultPhotoText25")}
                </Button>
              </Conditional>
            </Group>
          </CardSection>
        </Card>
      </Styled>
    </FadeTransition>
  );
};

const Styled = styled.div`
  .header {
    color: #38a169;
    background-color: ${({ theme }) => {
      return theme.darkMode ? theme.colors?.dark?.[5] : theme.colors?.green?.[0];
    }};
  }
`;

export default CurrentPhoto;
