import { gql, useLazyQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { trackAction } from "../../services/firebaseAnalytics";
import { Query, QueryPhotoOptionsForQuoteItemArgs, QuoteItem, VariationData } from "../../typing/gql.schema";
import useGenerateForcedVariation from "./useGenerateForcedVariation";

export const photoOptionGQL = gql`
  query photoOptionsForQuoteItem($quoteItemId: ID!) {
    photoOptionsForQuoteItem(quoteItemId: $quoteItemId) {
      __typename
      id
      yardProduct {
        id
        width
        height
        plant {
          id
          latinName
          commonName
        }
        size {
          id
          name
        }
        approvedPhotos {
          id
          filename
        }
      }
    }
  }
`;

const useLoadOptions = (quoteItem?: QuoteItem) => {
  const maxIncrease = 2.0;
  const [loading, setLoading] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const generateForcedVariation = useGenerateForcedVariation();

  const [variations, setVariations] = useState(new Map<string, VariationData>());

  const [query, { data, refetch, error }] = useLazyQuery<{ photoOptionsForQuoteItem: Query["photoOptionsForQuoteItem"] }, QueryPhotoOptionsForQuoteItemArgs>(
    photoOptionGQL,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    }
  );

  const load = async () => {
    setLoading(true);
    setShowOptions(true);
    try {
      const result = await query({ variables: { quoteItemId: quoteItem?.id! } });
      if (!result.data?.photoOptionsForQuoteItem) throw new Error("Error loading options");

      const promises = (result.data?.photoOptionsForQuoteItem || []).map(async (item) => {
        const r = await generateForcedVariation.generate({ quoteId: quoteItem?.quoteId!, quoteItemId: item?.id! });
        if (!r?.data?.buildForcedVariation) return;
        if (!quoteItem?.cartItem?.quantity) return;
        if (!quoteItem?.estimateItem?.unitTotal) return;
        if (quoteItem.estimateItem.unitTotal * maxIncrease > r.data.buildForcedVariation.priceDifference / quoteItem.cartItem.quantity) {
          setVariations(new Map(variations.set(item?.id!, r.data.buildForcedVariation)));
        }
      });

      await Promise.all(promises);
      setLoading(false);
      trackAction("load_options", { item_id: quoteItem?.id! });
    } catch (error: any) {
      setLoading(false);
      Sentry.captureException(error, { extra: { quoteItem, where: "useLoadOptions" } });
    }
  };

  const options = (data?.photoOptionsForQuoteItem || []).reduce((acc, c) => {
    if (c) return [...acc, c];
    return acc;
  }, [] as Array<QuoteItem>);

  const showOptionsLoader = showOptions && loading;
  const showOptionsError = showOptions && error && !loading;
  const showOptionsNoOptions = showOptions && !loading && !error && options?.length === 0;

  return {
    loading,
    data,
    refetch,
    error,
    options,
    load,
    showOptions,
    setShowOptions,
    variations,
    showOptionsLoader,
    showOptionsError,
    showOptionsNoOptions,
  };
};

export default useLoadOptions;
